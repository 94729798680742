import Axios from 'axios'
import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Cars: FunctionComponent = () => {
  return (
    <Table
      apiUrl="/api/v1/cars"
      addUrl="/dashboard/add-car"
      defaultSorting={{ name: 'number', direction: 'asc' }}
      items={[
        { title: 'Фотография', name: 'imagePath', type: 'image' },
        { title: 'Номер', name: 'number', type: 'string' },
        { title: 'Объём кузова', name: 'volume', type: 'string' },
        {
          title: 'Тип',
          name: 'type',
          type: 'enum',
          enum: ['Самосвал', 'Тягач'],
        },
        {
          title: 'Количество осей',
          name: 'axleType',
          type: 'enum',
          enum: ['3 осная', '4 осная', '5 осная'],
        },
      ]}
      actions={[
        { title: 'Изменить', icon: 'far fa-edit', url: '/dashboard/edit-car' },
        {
          title: 'Архивировать',
          icon: 'fas fa-archive',
          url: '/dashboard/archive-car',
          hidden: (_: { archived: boolean }) => _.archived,
        },
        {
          title: 'Вернуть из архива',
          icon: 'fas fa-undo',
          onClick: async (_: { id: string }) => {
            await Axios.put(
              `${process.env.REACT_APP_API_URL}/api/v1/cars/${_.id}/restore`
            )
          },
          hidden: (_: { archived: boolean }) => !_.archived,
        },
      ]}
    />
  )
}
