import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import { toast } from 'react-toastify'

export function axiosAuthInterceptor(
  config: any = undefined,
  instance = Axios
) {
  instance.interceptors.request.use(
    (_) => {
      if (localStorage.getItem('authenticated') === 'true') {
        _.withCredentials = true

        _.headers['Authorization'] = `Bearer ${localStorage.getItem(
          'tokenJwt'
        )}`
      }

      return _
    },
    (_) => _
  )

  instance.interceptors.response.use(
    (_) => _,
    (_) =>
      new Promise((resolve, reject) => {
        if (!_ || !_.response) {
          reject(_)

          toast.error('Не удалось обновить токен аутентификации.')
        }

        if (_.response.status !== 401) {
          reject(_)

          return
        }

        const tokenJwt = localStorage.getItem('tokenJwt')

        if (!tokenJwt) {
          signOut(reject, _)

          return
        }

        const originalRequest = _.config
        const decodedJwt: any = jwt_decode(tokenJwt)

        fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/tokens/${
            decodedJwt.userId
          }/${localStorage.getItem('refreshTokenId')}`,
          {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            redirect: 'follow',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
          .then((_) => _.json())
          .then((_) => {
            if (_.id && _.jwt) {
              localStorage.setItem('tokenJwt', _.jwt)
              localStorage.setItem('authenticated', 'true')
              localStorage.setItem('tokenId', _.id)
              localStorage.setItem('refreshTokenId', _.refreshTokenId)

              resolve(instance(originalRequest))

              return
            }

            signOut(reject, _)
          })
          .catch((_) => {
            signOut(reject, _)
          })
      })
  )

  function signOut(reject: (reason?: any) => void, _: any) {
    toast.error('Пользователь не авторизован.')

    localStorage.removeItem('tokenJwt')
    localStorage.removeItem('tokenId')
    localStorage.removeItem('authenticated')

    reject(_)

    setTimeout(() => {
      document.location.href = '/'
    }, 3000)
  }
}
