import Axios from 'axios'
import React, { useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../../table/table'

export const TariffTrips: FunctionComponent = () => {
  const [displayArchive, setDisplayArchive] = useState(false)

  const tableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
  }>(null)

  return (
    <>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="displayArchive"
          checked={displayArchive}
          onChange={(_) => {
            const newValue = !displayArchive

            setDisplayArchive(newValue)

            tableRef.current?.updateParams(
              'displayArchive',
              newValue ? 'true' : 'false'
            )

            tableRef.current?.refresh()
          }}
        />
        <label className="form-check-label" htmlFor="displayArchive">
          Показывать архивные
        </label>
      </div>
      <Table
        ref={tableRef}
        apiUrl="/api/v1/tariffs-trips"
        addUrl="/dashboard/add-tariff-trip"
        defaultSorting={{ name: 'fromAddressName', direction: 'asc' }}
        items={[
          { title: 'Пункт А', name: 'fromAddressName', type: 'string' },
          { title: 'Пункт Б', name: 'toAddressName', type: 'string' },
          { title: 'Водитель, фамилия', name: 'driverSurname', type: 'string' },
          { title: 'Водитель, имя', name: 'driverName', type: 'string' },
          {
            title: 'Водитель, отчество',
            name: 'driverPatronymic',
            type: 'string',
          },
          { title: 'Тариф', name: 'value', type: 'string' },
        ]}
        actions={[
          {
            title: 'Изменить',
            icon: 'far fa-edit',
            url: '/dashboard/edit-tariff-trip',
          },
          {
            title: 'Архивировать',
            icon: 'fas fa-archive',
            onClick: async (_: { id: string }) => {
              await Axios.delete(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-trips/${_.id}`
              )
            },
            hidden: (_: { archived: boolean }) => _.archived,
          },
          {
            title: 'Вернуть из архива',
            icon: 'fas fa-undo',
            onClick: async (_: { id: string }) => {
              await Axios.put(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-trips/${_.id}/restore`
              )
            },
            hidden: (_: { archived: boolean }) => !_.archived,
          },
          {
            title: 'Удалить',
            icon: 'fas fa-trash-alt',
            onClick: async (_: { id: string }) => {
              await Axios.delete(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-trips/${_.id}/delete`
              )
            },
          }
        ]}
      />
    </>
  )
}
