import Axios from 'axios'
import { stringify } from 'query-string'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import TableData from './tableData'
import { format } from 'date-fns'
import './table.scss'
import { TableImage } from './tableImage'
import NumberFormat from 'react-number-format'
import AsyncSelect from 'react-select/async'

export const Table = forwardRef<
  any,
  {
    apiUrl: string
    addUrl?: string
    deferred?: boolean
    items: {
      title: string
      name: string
      type:
        | 'string'
        | 'multiselect'
        | 'boolean'
        | 'image'
        | 'datetime'
        | 'datetime-timefilter'
        | 'datetime-timezone'
        | 'enum'
        | 'date'
        | 'number'
        | 'number-colored'
      enum?: string[]
      hidden?: boolean
      multiselectUrl?: string
    }[]
    actions?: {
      title: string
      icon: string
      url?: string
      onClick?: (_: any) => void
      hidden?: (_: any) => boolean
    }[]
    tableActions?: {
      title: string
      icon: string
      url?: string
      onClick?: () => void
    }[]
    hideSearch?: boolean
    displayFilters?: boolean
    defaultSorting: { name: string; direction: 'asc' | 'desc' }
    onFiltersUpdate?: (_: { name: string; value: string }[]) => void
    heightSm?: boolean
  }
>(
  (
    {
      apiUrl,
      addUrl,
      deferred,
      items,
      actions,
      tableActions,
      hideSearch,
      displayFilters,
      defaultSorting,
      onFiltersUpdate,
      heightSm,
    },
    ref
  ) => {
    const history = useHistory()

    const search = new URLSearchParams(useLocation().search)

    const searchPage = parseInt(search.get('page') || '1')

    const [query, setQuery] = useState(search.get('query') || null)
    const [searchQuery, setSearchQuery] = useState(search.get('query') || '')
    const [sorting, setSorting] = useState({
      name: defaultSorting.name,
      direction: defaultSorting.direction,
    })
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState(new TableData([], 0, 0))
    const [currentPage, setCurrentPage] = useState(searchPage)
    const [refresh, setRefresh] = useState(false)
    const [params, setParams] = useState<any>({})
    const [allowUpdate, setAllowUpdate] = useState(!deferred)
    const [columnsFilters, setColumnsFilters] = useState<
      { name: string; value: string }[]
    >(
      Array.from(search.entries()).map((_) => {
        return {
          name: _[0],
          value: _[1],
        }
      })
    )

    const upsertColumnFilter = (name: string, value: string) => {
      const index = columnsFilters.findIndex(
        (columnFilter) => columnFilter.name === name
      )

      updateSearch(name, value)

      if (index === -1) {
        columnsFilters.push({
          name: name,
          value: value,
        })

        setColumnsFilters([...columnsFilters])

        if (onFiltersUpdate) {
          onFiltersUpdate([...columnsFilters])
        }

        return
      }

      columnsFilters[index].value = value

      setColumnsFilters([...columnsFilters])

      if (onFiltersUpdate) {
        onFiltersUpdate([...columnsFilters])
      }
    }

    const updateSearch = (name: string, value: string) => {
      if (search.has(name)) {
        search.delete(name)
      }

      if (name !== 'page') {
        search.delete('page')
      }

      search.append(name, value)

      history.push({
        search: search.toString(),
      })
    }

    useImperativeHandle(ref, () => {
      return {
        refresh: () => setRefresh(!refresh),
        updateParams: (key: string, value: string) => {
          params[key] = value

          setParams(params)
        },
        allowUpdate: () => setAllowUpdate(true),
        getQuery: () => query,
        resetFilters: () => {
          window.location.href = window.location.pathname
        },
      }
    })

    useEffect(() => {
      if (!allowUpdate) {
        return
      }

      const updateTableData = async () => {
        const columnsFiltersParams: any = {}

        let counter = 0

        for (const columnFilter of columnsFilters) {
          if (columnFilter.value) {
            columnsFiltersParams[`columnsFilters[${counter}].name`] =
              columnFilter.name

            columnsFiltersParams[`columnsFilters[${counter}].value`] =
              columnFilter.value

            counter++
          }
        }

        const tableData = await Axios.get<TableData>(
          `${process.env.REACT_APP_API_URL}${apiUrl}`,
          {
            params: {
              ...params,
              ...columnsFiltersParams,
              page: currentPage,
              query,
              'sorting.name': sorting.name,
              'sorting.direction': sorting.direction,
            },
          }
        )
        setTableData(tableData.data)

        setIsLoading(false)
      }

      updateTableData()
    }, [
      currentPage,
      query,
      sorting,
      apiUrl,
      refresh,
      params,
      allowUpdate,
      columnsFilters,
    ])

    useEffect(() => {
      if (currentPage !== searchPage) {
        setCurrentPage(searchPage)
      }
    }, [searchPage, currentPage])

    return (
      <>
        <div className="row">
          <div className="col-6">
            {!hideSearch ? (
              <form
                onSubmit={(_) => {
                  _.preventDefault()

                  setQuery(searchQuery)

                  updateSearch('query', searchQuery)
                }}
              >
                <fieldset disabled={isLoading}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Поиск"
                      aria-label="Поиск"
                      minLength={3}
                      value={searchQuery}
                      onChange={(_) => setSearchQuery(_.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                      >
                        <i className="fas fa-search"></i> Найти
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            ) : null}
          </div>
          <div className="col-6 text-right">
            {addUrl ? (
              <Link to={addUrl} className="btn btn-primary mr-1">
                <i className="fas fa-plus"></i>Добавить
              </Link>
            ) : null}
            {tableActions
              ? tableActions.map((_) =>
                  _.url ? (
                    <Link to={_.url} className="btn btn-outline-secondary mr-1">
                      <i className={_.icon}></i>
                      <span className="ml-1">{_.title}</span>
                    </Link>
                  ) : (
                    <button
                      className="btn btn-outline-secondary mr-1"
                      onClick={async () => {
                        setIsLoading(true)

                        await _.onClick!()

                        setRefresh(!refresh)
                        setIsLoading(false)
                      }}
                    >
                      <i className={_.icon}></i>
                      <span className="ml-1">{_.title}</span>
                    </button>
                  )
                )
              : null}
          </div>
        </div>
        <div className="row pt-2">
          <div className="col">
            <span>Всего: {tableData.count}</span>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col">
            <div className="table-responsive">
              <div
                className="table-fixed"
                style={{
                  maxHeight: heightSm
                    ? window.innerHeight - 100
                    : window.innerHeight - 200,
                }}
              >
                {!isLoading && tableData.pages > 1 ? (
                  <nav aria-label="Навигация">
                    <ul className="pagination">
                      {currentPage !== 1 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(1)

                              updateSearch('page', '1')
                            }}
                          >
                            <span aria-hidden="true">Начало</span>
                          </button>
                        </li>
                      ) : null}

                      {currentPage > 1 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage - 1)

                              updateSearch('page', (currentPage - 1).toString())
                            }}
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>
                      ) : null}

                      <li className="page-item active">
                        <span className="page-link">{currentPage}</span>
                      </li>

                      {tableData.pages - 2 >= currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 1}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 1 === currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 1}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 1 > currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 2)

                              updateSearch('page', (currentPage + 2).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 2}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 2 > currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      ) : null}
                      {currentPage < tableData.pages - 2 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(tableData.pages)

                              updateSearch('page', tableData.pages.toString())
                            }}
                          >
                            <span aria-hidden="true">Конец</span>
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </nav>
                ) : null}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {items
                        .filter((_) => !_.hidden)
                        .map((_) => (
                          <th key={_.name}>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={(event) => {
                                event.preventDefault()

                                let search: any = {}

                                if (searchQuery) {
                                  search['query'] = searchQuery
                                }

                                search['sorting.name'] = _.name

                                if (sorting.name !== _.name) {
                                  search['sorting.direction'] = 'asc'
                                } else {
                                  if (sorting.direction === 'asc') {
                                    search['sorting.direction'] = 'desc'
                                  } else {
                                    search['sorting.direction'] = 'asc'
                                  }
                                }

                                setSorting({
                                  name: search['sorting.name'],
                                  direction: search['sorting.direction'],
                                })

                                history.location.search = stringify(search)

                                history.push(history.location)
                              }}
                            >
                              {_.title}
                              {sorting.name === _.name ? (
                                sorting.direction === 'asc' ? (
                                  <i className="fas fa-sort-amount-down-alt"></i>
                                ) : (
                                  <i className="fas fa-sort-amount-down"></i>
                                )
                              ) : null}
                            </button>
                          </th>
                        ))}
                      {actions ? (
                        <th className="text-center">
                          <span
                            className="btn cursor-default"
                            style={{ width: actions.length * 48 + 24 + 'px' }}
                          >
                            Действия
                          </span>
                        </th>
                      ) : null}
                    </tr>
                    {displayFilters ? (
                      <tr>
                        {items
                          .filter((_) => !_.hidden)
                          .map((_) => (
                            <th key={`${_.name}-filter`}>
                              {_.type === 'date' ? (
                                <>
                                  <div className="input-group">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      max={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'From',
                                          value.target.value
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'From', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      min={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'To',
                                          value.target.value + ' 23:59:59.999'
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'To', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ) : null}
                              {_.type === 'datetime' ? (
                                <>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      max={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'From',
                                          value.target.value
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'From', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      min={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'To',
                                          value.target.value + ' 23:59:59.999'
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'To', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ) : null}
                              {_.type === 'datetime-timefilter' ? (
                                <>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      value={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      max={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      onChange={(value) => {
                                        const currentValue = columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value

                                        if (!currentValue) {
                                          const date = value.target.value.split(
                                            'T'
                                          )[0]

                                          upsertColumnFilter(
                                            _.name + 'From',
                                            `${date}T00:00`
                                          )

                                          upsertColumnFilter(
                                            _.name + 'To',
                                            `${date}T23:59`
                                          )
                                        } else {
                                          upsertColumnFilter(
                                            _.name + 'From',
                                            value.target.value
                                          )
                                        }
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'From', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      value={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name + 'To'
                                        )?.value
                                      }
                                      min={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'To',
                                          value.target.value
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'To', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ) : null}
                              {_.type === 'datetime-timezone' ? (
                                <>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      max={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'From',
                                          value.target.value
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'From', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <div className="input-group date-min-width">
                                    <input
                                      type="date"
                                      className="form-control"
                                      value={
                                        columnsFilters
                                          .find(
                                            (columnFilter) =>
                                              columnFilter.name ===
                                              _.name + 'To'
                                          )
                                          ?.value.split(' ')[0]
                                      }
                                      min={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'To',
                                          value.target.value + ' 23:59:59.999'
                                        )
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() => {
                                        upsertColumnFilter(_.name + 'To', '')
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ) : null}
                              {_.type === 'boolean' ? (
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    onChange={(value) => {
                                      if (value.target.value === '-') {
                                        upsertColumnFilter(_.name, '')
                                        return
                                      }

                                      upsertColumnFilter(
                                        _.name,
                                        value.target.value === 'Да'
                                          ? 'true'
                                          : 'false'
                                      )
                                    }}
                                  >
                                    <option selected={true}>-</option>
                                    <option
                                      selected={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name
                                        )?.value === 'true'
                                      }
                                    >
                                      Да
                                    </option>
                                    <option
                                      selected={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name
                                        )?.value === 'false'
                                      }
                                    >
                                      Нет
                                    </option>
                                  </select>
                                </div>
                              ) : null}
                              {_.type === 'number' ||
                              _.type === 'number-colored' ? (
                                <div className="form-group">
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      step={0.01}
                                      className="form-control"
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'From',
                                          value.target.value
                                        )
                                      }}
                                      defaultValue={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name ===
                                            _.name + 'From'
                                        )?.value
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      step={0.01}
                                      className="form-control"
                                      onChange={(value) => {
                                        upsertColumnFilter(
                                          _.name + 'To',
                                          value.target.value
                                        )
                                      }}
                                      defaultValue={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name + 'To'
                                        )?.value
                                      }
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {_.type === 'string' ? (
                                <div className="form-group">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(value) => {
                                        if (
                                          value.target.value.length !== 0 &&
                                          value.target.value.length < 3
                                        ) {
                                          return
                                        }

                                        upsertColumnFilter(
                                          _.name,
                                          value.target.value
                                        )
                                      }}
                                      defaultValue={
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name
                                        )?.value
                                      }
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {_.type === 'multiselect' && _.multiselectUrl ? (
                                <div className="form-group">
                                  <AsyncSelect
                                    key={`${_.multiselectUrl}_asyncselect`}
                                    isMulti
                                    cacheOptions
                                    defaultOptions
                                    placeholder="Выбрать"
                                    noOptionsMessage={() => 'Не найдено'}
                                    loadingMessage={() => 'Загрузка'}
                                    loadOptions={(inputValue, callback) => {
                                      const load = async (
                                        inputValue: string,
                                        callback: any
                                      ) => {
                                        const items = await Axios.get<{
                                          items: { id: string; value: string }[]
                                        }>(
                                          `${process.env.REACT_APP_API_URL}/api/v1/${_.multiselectUrl}?query=${inputValue}&page=1`
                                        )

                                        callback(
                                          items.data.items.map((_) => {
                                            return {
                                              value: _.value,
                                              label: _.value,
                                            }
                                          })
                                        )
                                      }

                                      load(inputValue, callback)
                                    }}
                                    onChange={(value: any) => {
                                      upsertColumnFilter(
                                        _.name,
                                        value
                                          ? value
                                              .map((_: any) => _.label)
                                              .join('|@|')
                                          : ''
                                      )
                                    }}
                                    styles={{
                                      menu: ({ width, ...css }) => ({ ...css }),
                                    }}
                                  />
                                </div>
                              ) : null}
                              {_.type === 'enum' ? (
                                <select
                                  className="form-control"
                                  onChange={(value) => {
                                    if (value.target.value === '-') {
                                      upsertColumnFilter(_.name, '')
                                      return
                                    }

                                    upsertColumnFilter(
                                      _.name,
                                      _.enum
                                        ?.findIndex(
                                          (enumValue) =>
                                            enumValue === value.target.value
                                        )
                                        .toString() ?? ''
                                    )
                                  }}
                                >
                                  <option>-</option>
                                  {_.enum?.map((__, index) => (
                                    <option
                                      selected={
                                        index.toString() ===
                                        columnsFilters.find(
                                          (columnFilter) =>
                                            columnFilter.name === _.name
                                        )?.value
                                      }
                                    >
                                      {__}
                                    </option>
                                  ))}
                                  )
                                </select>
                              ) : null}
                            </th>
                          ))}
                        <th></th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={items.length + 1}>Загрузка...</td>
                      </tr>
                    ) : (
                      tableData.items.map(
                        (tableDataItem, tableDataItemIndex) => (
                          <tr key={tableDataItemIndex}>
                            {items.map((_, index) =>
                              !_.hidden ? (
                                <td key={index}>
                                  {_.type === 'string' ||
                                  _.type === 'multiselect'
                                    ? tableDataItem[_.name]
                                      ? tableDataItem[_.name]
                                      : '-'
                                    : null}
                                  {_.type === 'image' ? (
                                    tableDataItem[_.name] ? (
                                      <TableImage src={tableDataItem[_.name]} />
                                    ) : (
                                      '-'
                                    )
                                  ) : null}
                                  {_.type === 'number' ? (
                                    tableDataItem[_.name] ? (
                                      <NumberFormat
                                        value={tableDataItem[_.name]}
                                        displayType={'text'}
                                        thousandSeparator=" "
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                      />
                                    ) : (
                                      '-'
                                    )
                                  ) : null}
                                  {_.type === 'number-colored' ? (
                                    tableDataItem[_.name] ? (
                                      tableDataItem[_.name] < 0 ? (
                                        <span className="text-danger">
                                          <NumberFormat
                                            value={tableDataItem[_.name]}
                                            displayType={'text'}
                                            thousandSeparator=" "
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                          />
                                        </span>
                                      ) : (
                                        <span className="text-success">
                                          <NumberFormat
                                            value={tableDataItem[_.name]}
                                            displayType={'text'}
                                            thousandSeparator=" "
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                          />
                                        </span>
                                      )
                                    ) : (
                                      '-'
                                    )
                                  ) : null}
                                  {_.type === 'boolean'
                                    ? tableDataItem[_.name]
                                      ? 'Да'
                                      : 'Нет'
                                    : null}
                                  {_.type === 'datetime' ||
                                  _.type === 'datetime-timefilter'
                                    ? !tableDataItem[_.name] ||
                                      tableDataItem[_.name] ===
                                        '0001-01-01T00:00:00'
                                      ? '-'
                                      : format(
                                          new Date(
                                            new Date(
                                              tableDataItem[_.name]
                                            ).getTime() +
                                              new Date().getTimezoneOffset() *
                                                60000
                                          ),
                                          'dd.MM.yyyy HH:mm'
                                        )
                                    : null}
                                  {_.type === 'datetime-timezone'
                                    ? !tableDataItem[_.name] ||
                                      tableDataItem[_.name] ===
                                        '0001-01-01T00:00:00'
                                      ? '-'
                                      : format(
                                          new Date(
                                            new Date(
                                              tableDataItem[_.name]
                                            ).getTime() -
                                              new Date().getTimezoneOffset() *
                                                60000
                                          ),
                                          'dd.MM.yyyy HH:mm'
                                        )
                                    : null}
                                  {_.type === 'date'
                                    ? !tableDataItem[_.name] ||
                                      tableDataItem[_.name] ===
                                        '0001-01-01T00:00:00'
                                      ? '-'
                                      : format(
                                          new Date(
                                            new Date(
                                              tableDataItem[_.name]
                                            ).getTime() +
                                              new Date().getTimezoneOffset() *
                                                60000
                                          ),
                                          'dd.MM.yyyy'
                                        )
                                    : null}
                                  {_.type === 'enum' && _.enum
                                    ? _.enum[parseInt(tableDataItem[_.name])] ??
                                      '-'
                                    : null}
                                </td>
                              ) : null
                            )}
                            {actions ? (
                              <td className="text-center">
                                {actions.map((action, index) =>
                                  action.hidden === undefined ||
                                  !action.hidden(tableDataItem) ? (
                                    action.url ? (
                                      <>
                                        <Link
                                          className="btn btn-link"
                                          to={`${action.url}/${tableDataItem.id}`}
                                          key={index}
                                        >
                                          <i
                                            className={action.icon}
                                            title={action.title}
                                          ></i>
                                        </Link>
                                      </>
                                    ) : action.onClick ? (
                                      <>
                                        <button
                                          className="btn btn-link"
                                          onClick={async () => {
                                            setIsLoading(true)

                                            await action.onClick!(tableDataItem)

                                            setRefresh(!refresh)
                                            setIsLoading(false)
                                          }}
                                        >
                                          <i
                                            className={action.icon}
                                            title={action.title}
                                          ></i>{' '}
                                        </button>
                                      </>
                                    ) : null
                                  ) : null
                                )}
                              </td>
                            ) : null}
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
                {!isLoading && tableData.pages > 1 ? (
                  <nav aria-label="Навигация">
                    <ul className="pagination">
                      {currentPage !== 1 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(1)

                              updateSearch('page', '1')
                            }}
                          >
                            <span aria-hidden="true">Начало</span>
                          </button>
                        </li>
                      ) : null}

                      {currentPage > 1 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage - 1)

                              updateSearch('page', (currentPage - 1).toString())
                            }}
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>
                      ) : null}

                      <li className="page-item active">
                        <span className="page-link">{currentPage}</span>
                      </li>

                      {tableData.pages - 2 >= currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 1}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 1 === currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 1}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 1 > currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 2)

                              updateSearch('page', (currentPage + 2).toString())
                            }}
                          >
                            <span aria-hidden="true">{currentPage + 2}</span>
                          </button>
                        </li>
                      ) : null}

                      {tableData.pages - 2 > currentPage ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(currentPage + 1)

                              updateSearch('page', (currentPage + 1).toString())
                            }}
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      ) : null}
                      {currentPage < tableData.pages - 2 ? (
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            onClick={() => {
                              setCurrentPage(tableData.pages)

                              updateSearch('page', tableData.pages.toString())
                            }}
                          >
                            <span aria-hidden="true">Конец</span>
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </nav>
                ) : null}
                <div
                  className="row"
                  hidden={!isLoading && tableData.items.length !== 0}
                >
                  <div className="col">Нет записей.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)
