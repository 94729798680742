import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Bar, Pie } from 'react-chartjs-2'
import { getMonth } from '../../infrastructure/document/getMonth'

import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import './dashboardHome.scss'

export const DashboardHome = () => {
  Chart.defaults.set('plugins.datalabels', {
    color: '#FFFFFF',
  })

  const now = new Date()
  const today = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${now
    .getDate()
    .toString()
    .padStart(2, '0')} 23:59:59.999`

  const [from, setFrom] = useState(`${now.getFullYear()}-01-01`)
  const [fromMin, setFromMin] = useState(from)

  const [to, setTo] = useState(today)
  const [toMax, setToMax] = useState(to)

  const [revenues, setRevenues] = useState<{ month: number; value: number }[]>()
  const [totalRevenue, setTotalRevenue] = useState<number>()

  const [orders, setOrders] = useState<{ month: number; value: number }[]>()
  const [totalOrders, setTotalOrders] = useState<number>()

  const [volumes, setVolumes] = useState<
    {
      name: string
      from: number
      to: number
      diff: number
      fromType: number
      toType: number
    }[]
  >()

  const [topContractors, setTopContractors] = useState<
    { name: string; value: number }[]
  >()

  const [topDriversType, setTopDriversType] = useState('revenue')
  const [topDrivers, setTopDrivers] = useState<
    { name: string; value: number }[]
  >()

  const [tripsTypes, setTripsTypes] = useState<
    { tripType: number; value: number }[]
  >()

  const getRandomColor = (index: number) => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }

    return color
  }

  const [volumeType, setVolumeType] = useState('cubic-meters')
  const [volumesStatistics, setVolumesStatistics] = useState<
    { name: string; value: number }[]
  >()

  const [commonStatistics, setCommonStatistics] = useState<{
    marked: number
    idleHours: number
  }>()

  useEffect(() => {
    const fromMin = new Date(new Date(to).getTime() - 365 * 24 * 60 * 60 * 1000)

    setFromMin(
      `${fromMin.getFullYear()}-${(fromMin.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${(fromMin.getDate() + 1)
        .toString()
        .padStart(2, '0')}`
    )
  }, [fromMin, to])

  useEffect(() => {
    const toMax = new Date(new Date(from).getTime() + 365 * 24 * 60 * 60 * 1000)

    setToMax(
      `${toMax.getFullYear()}-${(toMax.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${(toMax.getDate() + 1).toString().padStart(2, '0')}`
    )
  }, [toMax, from])

  useEffect(() => {
    const loadRevenues = async () => {
      const revenues = await Axios.get<{ month: number; value: number }[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/revenues?from=${from}&to=${to}`
      )

      setRevenues(revenues.data)
    }

    loadRevenues()
  }, [from, to])

  useEffect(() => {
    const loadToalRevenue = async () => {
      const totalRevenue = await Axios.get<number>(
        `${process.env.REACT_APP_API_URL}/api/v1/revenues/total?from=${from}&to=${to}`
      )

      setTotalRevenue(totalRevenue.data)
    }

    loadToalRevenue()
  }, [from, to])

  useEffect(() => {
    const loadOrders = async () => {
      const orders = await Axios.get<{ month: number; value: number }[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/orders?from=${from}&to=${to}`
      )

      setOrders(orders.data)
    }

    loadOrders()
  }, [from, to])

  useEffect(() => {
    const loadToalOrders = async () => {
      const totalOrders = await Axios.get<number>(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/total?from=${from}&to=${to}`
      )

      setTotalOrders(totalOrders.data)
    }

    loadToalOrders()
  }, [from, to])

  useEffect(() => {
    const loadVolumes = async () => {
      const volumes = await Axios.get<
        {
          name: string
          from: number
          to: number
          diff: number
          fromType: number
          toType: number
        }[]
      >(`${process.env.REACT_APP_API_URL}/api/v1/volumes?from=${from}&to=${to}`)

      setVolumes(volumes.data)
    }

    loadVolumes()
  }, [from, to])

  useEffect(() => {
    const loadTopContractors = async () => {
      const topContractors = await Axios.get<
        {
          name: string
          value: number
        }[]
      >(
        `${process.env.REACT_APP_API_URL}/api/v1/top-contractors/quantity?from=${from}&to=${to}`
      )

      setTopContractors(topContractors.data)
    }

    loadTopContractors()
  }, [from, to])

  useEffect(() => {
    const loadTopDrivers = async () => {
      const topDrivers = await Axios.get<
        {
          name: string
          value: number
        }[]
      >(
        `${process.env.REACT_APP_API_URL}/api/v1/top-drivers/${topDriversType}?from=${from}&to=${to}`
      )

      setTopDrivers(topDrivers.data)
    }

    loadTopDrivers()
  }, [topDriversType, from, to])

  useEffect(() => {
    const loadTripTypes = async () => {
      const tripTypes = await Axios.get<
        {
          tripType: number
          value: number
        }[]
      >(
        `${process.env.REACT_APP_API_URL}/api/v1/trips-types?from=${from}&to=${to}`
      )

      setTripsTypes(tripTypes.data)
    }

    loadTripTypes()
  }, [from, to])

  useEffect(() => {
    const loadVolumesStatistics = async () => {
      const volumesStatistics = await Axios.get<
        {
          name: string
          value: number
        }[]
      >(
        `${process.env.REACT_APP_API_URL}/api/v1/volumes-statistics/${volumeType}?from=${from}&to=${to}`
      )

      setVolumesStatistics(volumesStatistics.data)
    }

    loadVolumesStatistics()
  }, [from, to, volumeType])

  useEffect(() => {
    const loadCommonStatistics = async () => {
      const commonStatistics = await Axios.get<{
        marked: number
        idleHours: number
      }>(
        `${process.env.REACT_APP_API_URL}/api/v1/common-statistics?from=${from}&to=${to}`
      )

      setCommonStatistics(commonStatistics.data)
    }

    loadCommonStatistics()
  }, [from, to])

  return (
    <>
      <div className="background-dashboard">
        <div className="row">
          <div className="col">
            <div className="form-row">
              <div className="input-group col-lg-3">
                <input
                  className="form-control"
                  required
                  type="date"
                  min={fromMin}
                  max={to}
                  value={from.toString()}
                  onChange={(_) => setFrom(_.target.value)}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    setFrom('')
                  }}
                >
                  X
                </button>
              </div>

              <div className="input-group col-lg-3">
                <input
                  className="form-control"
                  required
                  type="date"
                  value={to.split(' ')[0]}
                  min={from}
                  max={toMax}
                  onChange={(_) => setTo(_.target.value + ' 23:59:59.999')}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    setTo('')
                  }}
                >
                  X
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    Выручка{' '}
                    <h3>
                      <NumberFormat
                        value={totalRevenue}
                        displayType={'text'}
                        thousandSeparator=" "
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </h3>
                    <Bar
                      data={{
                        labels: revenues?.map((_) => getMonth(_.month)),
                        datasets: [
                          {
                            label: 'Выручка',
                            backgroundColor: revenues?.map((_, index) =>
                              getRandomColor(index)
                            ),
                            data: revenues?.map((_) => _.value),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    Заказы{' '}
                    <h3>
                      <NumberFormat
                        value={totalOrders}
                        displayType={'text'}
                        thousandSeparator=" "
                        decimalSeparator=","
                        decimalScale={0}
                        fixedDecimalScale={true}
                      />
                    </h3>
                    <Bar
                      data={{
                        labels: orders?.map((_) => getMonth(_.month)),
                        datasets: [
                          {
                            label: 'Заказы',
                            backgroundColor: orders?.map((_, index) =>
                              getRandomColor(index)
                            ),
                            data: orders?.map((_) => _.value),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <Bar
                  data={{
                    labels: topContractors?.map((_) => _.name),
                    datasets: [
                      {
                        label: 'Топ карьеров по кол-ву заказов',
                        backgroundColor: topContractors?.map((_, index) =>
                          getRandomColor(index)
                        ),
                        data: topContractors?.map((_) => _.value),
                      },
                    ],
                  }}
                  options={{ indexAxis: 'y' }}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <select onChange={(_) => setTopDriversType(_.target.value)}>
                  <option value="revenue">Топ водителей (по выручке)</option>
                  <option value="quantity">
                    Топ водителей (по кол-ву заказов)
                  </option>
                </select>
                <Bar
                  data={{
                    labels: topDrivers?.map((_) => _.name),
                    datasets: [
                      {
                        label: 'Топ водителей',
                        backgroundColor: topDrivers?.map((_, index) =>
                          getRandomColor(index)
                        ),
                        data: topDrivers?.map((_) => _.value),
                      },
                    ],
                  }}
                  options={{ indexAxis: 'y' }}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <select onChange={(_) => setVolumeType(_.target.value)}>
                  <option value="cubic-meters">Объём поставок (М3)</option>
                  <option value="tons">Объём поставок (Тонны)</option>
                </select>
                <Pie
                  data={{
                    labels: volumesStatistics?.map((_) => _.name) ?? [],
                    datasets: [
                      {
                        label: 'Объём поставок',
                        backgroundColor: volumesStatistics?.map((_, index) =>
                          getRandomColor(index)
                        ),
                        data: volumesStatistics?.map((_) => _.value),
                      },
                    ],
                  }}
                  plugins={[ChartDataLabels]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    Типы заказов
                    <Pie
                      data={{
                        labels:
                          tripsTypes?.map((_) =>
                            _.tripType === 0
                              ? 'Поставка'
                              : _.tripType === 1
                              ? 'Перевозка'
                              : 'Аренда'
                          ) ?? [],
                        datasets: [
                          {
                            label: 'Тип заказов',
                            backgroundColor: tripsTypes?.map((_, index) =>
                              getRandomColor(index)
                            ),
                            data: tripsTypes?.map((_) => _.value),
                          },
                        ],
                      }}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    Прочее <br />
                    <strong>Отметок:</strong> {commonStatistics?.marked}. <br />
                    <strong>Часов простоя:</strong>{' '}
                    {commonStatistics?.idleHours}.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Материал</th>
                      <th>Загрузка</th>
                      <th>Отгрузка</th>
                      <th>Разница</th>
                    </tr>
                  </thead>
                  <tbody>
                    {volumes?.map((_) => (
                      <tr>
                        <td>{_.name}</td>
                        <td>
                          <NumberFormat
                            value={_.from}
                            displayType={'text'}
                            thousandSeparator=" "
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />{' '}
                          {_.fromType === 0 ? 'Тонны' : 'М³'}
                        </td>
                        <td>
                          <NumberFormat
                            value={_.to}
                            displayType={'text'}
                            thousandSeparator=" "
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />{' '}
                          {_.toType === 0 ? 'Тонны' : 'М³'}
                        </td>
                        <td>
                          <span
                            className={
                              _.diff > 0 ? 'text-success' : 'text-danger'
                            }
                          >
                            <NumberFormat
                              value={_.diff}
                              displayType={'text'}
                              thousandSeparator=" "
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
