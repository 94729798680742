import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'

export const AddAddress = () => {
  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  const history = useHistory()

  const addAddress = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const address = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/addresses`,
        {
          name,
          value,
        }
      )

      toast.success('Адрес добавлен.')

      history.push(`/dashboard/edit-address/${address.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={(_) => addAddress(_)}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Адрес</label>
              <input
                type="string"
                className="form-control"
                id="name"
                value={name}
                onChange={(_) => setName(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Подробный адрес</label>
              <input
                type="text"
                className="form-control"
                id="value"
                value={value}
                onChange={(_) => setValue(_.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
