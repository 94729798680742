import Axios from 'axios'
import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Materials: FunctionComponent = () => {
  return (
    <Table
      addUrl="/dashboard/add-material"
      apiUrl="/api/v1/materials"
      defaultSorting={{ name: 'name', direction: 'asc' }}
      items={[{ title: 'Название', name: 'name', type: 'string' }]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-material',
        },
        {
          title: 'Архивировать',
          icon: 'fas fa-archive',
          url: '/dashboard/archive-material',
          hidden: (_: { archived: boolean }) => _.archived,
        },
        {
          title: 'Вернуть из архива',
          icon: 'fas fa-undo',
          onClick: async (_: { id: string }) => {
            await Axios.put(
              `${process.env.REACT_APP_API_URL}/api/v1/materials/${_.id}/restore`
            )
          },
          hidden: (_: { archived: boolean }) => !_.archived,
        },
      ]}
    />
  )
}
