import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

const AnonymousRoute = ({ component, authenticated, title, ...rest }: any) => {
  useEffect(() => {
    document.title = `${title} - ${process.env.REACT_APP_NAME}`
  })
  const routeComponent = (props: any) =>
    authenticated ? (
      <Redirect to={{ pathname: '/' }} />
    ) : (
      React.createElement(component, props)
    )
  return <Route {...rest} render={routeComponent} />
}

export default AnonymousRoute
