import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const GibddFines: FunctionComponent = () => {
  return (
    <Table
      apiUrl="/api/v1/gibdd-fines"
      addUrl="/dashboard/add-gibdd-fine"
      defaultSorting={{ name: 'date', direction: 'desc' }}
      items={[
        { title: 'Дата', name: 'date', type: 'date' },
        { title: 'Сумма', name: 'amount', type: 'string' },
        {
          title: 'Номер транспортного средства',
          name: 'number',
          type: 'string',
        },
        { title: 'Фамилия', name: 'driverSurname', type: 'string' },
        { title: 'Имя', name: 'driverName', type: 'string' },
        { title: 'Отчество', name: 'driverPatronymic', type: 'string' },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-gibdd-fine',
        },
        {
          title: 'Удалить',
          icon: 'fas fa-trash-alt',
          url: '/dashboard/delete-gibdd-fine',
        },
      ]}
    />
  )
}
