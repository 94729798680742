import './axiosProgressBarInterceptor.scss'

import NProgress from 'nprogress'
import Axios from 'axios'

const calculatePercentage = (loaded: number, total: number) =>
  Math.floor(loaded * 1.0) / total

export function axiosProgressBarInterceptor(
  config: any = undefined,
  instance = Axios
) {
  let requestsCounter = 0

  const setupStartProgress = () => {
    instance.interceptors.request.use((config) => {
      requestsCounter++
      NProgress.start()
      return config
    })
  }

  const setupUpdateProgress = () => {
    const update = (e: any) =>
      NProgress.inc(calculatePercentage(e.loaded, e.total))

    instance.defaults.onDownloadProgress = update
    instance.defaults.onUploadProgress = update
  }

  const setupStopProgress = () => {
    const responseFunc = (response: any) => {
      if (--requestsCounter === 0) {
        NProgress.done()
      }
      return response
    }

    const errorFunc = (error: any) => {
      if (--requestsCounter === 0) {
        NProgress.done()
      }
      return Promise.reject(error)
    }

    instance.interceptors.response.use(responseFunc, errorFunc)
  }

  NProgress.configure(config)
  setupStartProgress()
  setupUpdateProgress()
  setupStopProgress()
}
