import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Payers: FunctionComponent = () => {
  return (
    <Table
      addUrl="/dashboard/add-payer"
      apiUrl="/api/v1/payers"
      defaultSorting={{ name: 'name', direction: 'asc' }}
      items={[
        {
          name: 'name',
          title: 'Название',
          type: 'string',
        },
        {
          name: 'inn',
          title: 'ИНН',
          type: 'string',
        },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-payer',
        },
      ]}
    />
  )
}
