import Axios from 'axios'
import React from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const AddRepair: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [carId, setCarId] = useState<string>()
  const [trailerId, setTrailerId] = useState<string>()
  const [description, setDescription] = useState('')
  const [beginDateTime, setBeginDateTime] = useState('')
  const [planningEndDateTime, setPlanningEndDateTime] = useState('')
  const [factEndDateTime, setFactEndDateTime] = useState('')
  const [delayComment, setDelayComment] = useState('')

  const history = useHistory()

  const addRepair = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if ((carId && trailerId) || (!carId && !trailerId)) {
        alert('Выберите транспортное средство или прицеп.')

        return
      }

      const repair = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/repairs`,
        {
          carId,
          trailerId,
          description,
          beginDateTime,
          planningEndDateTime,
          factEndDateTime: !factEndDateTime ? null : factEndDateTime,
          delayComment,
        }
      )

      toast.success('Ремонт добавлен.')

      history.push(`/dashboard/edit-repair/${repair.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addRepair}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Транспортное средство</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string; driverId: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/cars?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.number,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) =>
                  _ ? setCarId(_.value) : setCarId(undefined)
                }
              />
            </div>
            <div className="form-group">
              <label>Прицеп</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadTrailers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const trarilers = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/trailers?query=${inputValue}&page=1`
                    )

                    callback(
                      trarilers.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.number,
                        }
                      })
                    )
                  }

                  loadTrailers(inputValue, callback)
                }}
                onChange={(_: any) =>
                  _ ? setTrailerId(_.value) : setTrailerId(undefined)
                }
              />
            </div>
            <div className="form-group">
              <label>Описание</label>
              <textarea
                className="form-control"
                required
                rows={5}
                value={description}
                onChange={(_) => setDescription(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Начало ремонта</label>
              <input
                className="form-control"
                required
                type="datetime-local"
                value={beginDateTime.toString()}
                onChange={(_) => {
                  if (
                    new Date(_.target.value) > new Date(planningEndDateTime)
                  ) {
                    alert(
                      'Начало ремонта не может быть позже планируемого завершения.'
                    )
                    _.preventDefault()
                    return
                  }

                  setBeginDateTime(_.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Планируемое завершение</label>
              <input
                className="form-control"
                required
                type="datetime-local"
                value={planningEndDateTime.toString()}
                onChange={(_) => {
                  if (new Date(_.target.value) < new Date(beginDateTime)) {
                    alert(
                      'Планируемое завершение не может быть раньше начала ремонта.'
                    )
                    _.preventDefault()
                    return
                  }

                  setPlanningEndDateTime(_.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Фактическое завершение</label>
              <input
                className="form-control"
                type="datetime-local"
                value={factEndDateTime.toString()}
                onChange={(_) => setFactEndDateTime(_.target.value)}
              />
            </div>
            {factEndDateTime > planningEndDateTime ? (
              <div className="form-group">
                <label>Комментарий по задержке</label>
                <textarea
                  className="form-control"
                  rows={5}
                  value={delayComment}
                  onChange={(_) => setDelayComment(_.target.value)}
                  required
                />
              </div>
            ) : null}
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
