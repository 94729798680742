import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useParams } from 'react-router-dom'
import { getMonth } from '../../../infrastructure/document/getMonth'
import { Table } from '../table/table'

export const ViewPayroll = () => {
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')

  const [total, setTotal] = useState(0)
  const [onCard, setOnCard] = useState(0)
  const [nonCard, setNonCard] = useState(0)

  const [salary, setSalary] = useState(0)
  const [income, setIncome] = useState(0)
  const [tripsCount, setTripsCount] = useState(0)
  const [advances, setAdvances] = useState(0)
  const [gibddFines, setGibddFines] = useState(0)
  const [fines, setFines] = useState(0)
  const [bonuses, setBonuses] = useState(0)
  const [idles, setIdles] = useState(0)

  const { year, month, driverId } = useParams<{
    year: string
    month: string
    driverId: string
  }>()

  const tripsTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    tripsTableRef.current?.updateParams('year', year)
    tripsTableRef.current?.updateParams('month', month)
    tripsTableRef.current?.updateParams('driverId', driverId)
    tripsTableRef.current?.allowUpdate()
    tripsTableRef.current?.refresh()
  }, [year, month, driverId])

  const gibddFinesTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    gibddFinesTableRef.current?.updateParams('year', year)
    gibddFinesTableRef.current?.updateParams('month', month)
    gibddFinesTableRef.current?.updateParams('driverId', driverId)
    gibddFinesTableRef.current?.allowUpdate()
    gibddFinesTableRef.current?.refresh()
  }, [year, month, driverId])

  const finesTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    finesTableRef.current?.updateParams('year', year)
    finesTableRef.current?.updateParams('month', month)
    finesTableRef.current?.updateParams('driverId', driverId)
    finesTableRef.current?.allowUpdate()
    finesTableRef.current?.refresh()
  }, [year, month, driverId])

  const advancesTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    advancesTableRef.current?.updateParams('year', year)
    advancesTableRef.current?.updateParams('month', month)
    advancesTableRef.current?.updateParams('driverId', driverId)
    advancesTableRef.current?.allowUpdate()
    advancesTableRef.current?.refresh()
  }, [year, month, driverId])

  const bonusesTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    bonusesTableRef.current?.updateParams('year', year)
    bonusesTableRef.current?.updateParams('month', month)
    bonusesTableRef.current?.updateParams('driverId', driverId)
    bonusesTableRef.current?.allowUpdate()
    bonusesTableRef.current?.refresh()
  }, [year, month, driverId])

  const idlesTableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
  }>(null)

  useEffect(() => {
    idlesTableRef.current?.updateParams('year', year)
    idlesTableRef.current?.updateParams('month', month)
    idlesTableRef.current?.updateParams('driverId', driverId)
    idlesTableRef.current?.allowUpdate()
    idlesTableRef.current?.refresh()
  }, [year, month, driverId])

  useEffect(() => {
    const loadPayroll = async () => {
      const payroll = await Axios.get<{
        surname: string
        name: string
        patronymic: string
        total: number
        income: number
        onCard: number
        nonCard: number
        salary: number
        advances: number
        gibddFines: number
        fines: number
        bonuses: number
        idles: number
        tripsCount: number
      }>(
        `${process.env.REACT_APP_API_URL}/api/v1/payrolls/${year}/${month}/${driverId}`
      )

      setSurname(payroll.data.surname)
      setName(payroll.data.name)
      setPatronymic(payroll.data.patronymic)
      setTotal(payroll.data.total)
      setIncome(payroll.data.income)
      setOnCard(payroll.data.onCard)
      setNonCard(payroll.data.nonCard)
      setSalary(payroll.data.salary)
      setAdvances(payroll.data.advances)
      setGibddFines(payroll.data.gibddFines)
      setFines(payroll.data.fines)
      setBonuses(payroll.data.bonuses)
      setIdles(payroll.data.idles)
      setTripsCount(payroll.data.tripsCount)
    }

    loadPayroll()
  }, [year, month, driverId])

  return (
    <>
      <div className="row">
        <div className="col-lg">
          Водитель:{' '}
          <strong>
            {surname} {name} {patronymic}
          </strong>
          . <br />
          <strong>
            Период: {getMonth(parseInt(month))} {year}.
          </strong>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <strong>
            Рейсы, премии, простои{' '}
            <span className="text-success">
              <NumberFormat
                value={income}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="₽"
              />
            </span>
          </strong>
          <br />
          <strong>
            Штрафы, авансы{' '}
            <span className="text-danger">
              <NumberFormat
                value={advances + fines + gibddFines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="₽"
              />
            </span>
          </strong>
        </div>

        <div className="col">
          <strong>
            К выплате{' '}
            <NumberFormat
              value={total}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              suffix="₽"
            />
          </strong>
          <br />
          На карту{' '}
          <NumberFormat
            value={onCard}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix="₽"
          />
          <br />
          На руки{' '}
          <NumberFormat
            value={nonCard}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix="₽"
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <strong>Заработная плата</strong>{' '}
          <NumberFormat
            value={salary}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <strong>Поездок</strong>{' '}
          <NumberFormat
            value={tripsCount}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={0}
            fixedDecimalScale={true}
          />
        </div>
      </div>
      <Table
        apiUrl="/api/v1/trips"
        ref={tripsTableRef}
        deferred={true}
        defaultSorting={{ name: 'dateTimeTo', direction: 'desc' }}
        items={[
          {
            name: 'dateTimeTo',
            title: 'Дата',
            type: 'datetime',
          },
          {
            name: 'number',
            title: 'Документ',
            type: 'string',
          },
          {
            name: 'type',
            title: 'Тип заказа',
            type: 'enum',
            enum: ['Поставка', 'Перевозка', 'Аренда'],
          },
          {
            name: 'contractorObjectNameFrom',
            title: 'Погрузка, наименование адреса',
            type: 'string',
          },
          {
            name: 'contractorObjectNameTo',
            title: 'Разгрузка, наименование адреса',
            type: 'string',
          },
          {
            name: 'payment',
            title: 'Оплата поездки',
            type: 'number',
          },
        ]}
      />

      <div className="row mt-3">
        <div className="col">
          <strong>Премии</strong>{' '}
          <span className="text-success">
            <NumberFormat
              value={bonuses}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <Table
        apiUrl={`/api/v1/bonuses/${driverId}`}
        ref={bonusesTableRef}
        deferred={true}
        defaultSorting={{ name: 'date', direction: 'desc' }}
        items={[
          { title: 'Дата', name: 'date', type: 'date' },
          { title: 'Сумма', name: 'amount', type: 'string' },
          { title: 'Комментарий', name: 'comment', type: 'string' },
        ]}
      />

      <div className="row mt-3">
        <div className="col">
          <strong>Простои</strong>{' '}
          <span className="text-success">
            <NumberFormat
              value={idles}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <Table
        apiUrl={`/api/v1/idles/${driverId}`}
        ref={idlesTableRef}
        deferred={true}
        defaultSorting={{ name: 'date', direction: 'desc' }}
        items={[
          { title: 'Дата', name: 'date', type: 'date' },
          { title: 'Сумма', name: 'amount', type: 'string' },
          { title: 'Комментарий', name: 'comment', type: 'string' },
        ]}
      />

      <div className="row mt-3">
        <div className="col">
          <strong>Штрафы ГИБДД</strong>{' '}
          <span className="text-danger">
            <NumberFormat
              value={gibddFines}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <Table
        apiUrl="/api/v1/gibdd-fines"
        ref={gibddFinesTableRef}
        deferred={true}
        defaultSorting={{ name: 'number', direction: 'asc' }}
        items={[
          {
            title: 'Номер транспортного средства',
            name: 'number',
            type: 'string',
          },
          { title: 'Дата', name: 'date', type: 'date' },
          { title: 'Сумма', name: 'amount', type: 'number' },
        ]}
      />

      <div className="row mt-3">
        <div className="col">
          <strong>Иные штрафы</strong>{' '}
          <span className="text-danger">
            {' '}
            <NumberFormat
              value={fines}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <Table
        apiUrl={`/api/v1/fines/${driverId}`}
        ref={finesTableRef}
        deferred={true}
        defaultSorting={{ name: 'date', direction: 'desc' }}
        items={[
          {
            title: 'Комментарий',
            name: 'comment',
            type: 'string',
          },
          { title: 'Дата', name: 'date', type: 'date' },
          { title: 'Сумма', name: 'amount', type: 'number' },
        ]}
      />

      <div className="row mt-3">
        <div className="col">
          <strong>Авансы</strong>{' '}
          <span className="text-danger">
            {' '}
            <NumberFormat
              value={advances}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
      </div>
      <Table
        apiUrl={`/api/v1/advances/${driverId}`}
        ref={advancesTableRef}
        deferred={true}
        defaultSorting={{ name: 'date', direction: 'desc' }}
        items={[
          {
            title: 'Комментарий',
            name: 'comment',
            type: 'string',
          },
          { title: 'Дата', name: 'date', type: 'date' },
          { title: 'Сумма', name: 'amount', type: 'number' },
        ]}
      />
    </>
  )
}
