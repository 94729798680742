import { FunctionComponent } from 'react'

export default class DashboardRoute {
  constructor(
    public item: {
      component: FunctionComponent
      title?: string
      routePath: string
      breadcrumbUrl: string
      children: DashboardRoute[]
    }
  ) {}
}
