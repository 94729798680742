import Axios from 'axios'
import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditUserPassword: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>()

  const [disabled, setDisabled] = useState(false)
  const [password, setPassword] = useState('')

  const changePassword = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}/password`,
        {
          password,
        }
      )

      toast.success('Пароль изменён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={changePassword}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Пароль</label>
              <input
                type="text"
                className="form-control"
                minLength={6}
                value={password}
                onChange={(_) => setPassword(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
