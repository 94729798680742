import Axios from 'axios'
import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Trailers: FunctionComponent = () => {
  return (
    <Table
      apiUrl="/api/v1/trailers"
      addUrl="/dashboard/add-trailer"
      defaultSorting={{ name: 'number', direction: 'asc' }}
      items={[
        { title: 'Номер', name: 'number', type: 'string' },
        { title: 'Марка', name: 'brand', type: 'string' },
        { title: 'Объём кузова', name: 'volume', type: 'string' },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-trailer',
        },
        {
          title: 'Архивировать',
          icon: 'fas fa-archive',
          url: '/dashboard/archive-trailer',
          hidden: (_: { archived: boolean }) => _.archived,
        },
        {
          title: 'Вернуть из архива',
          icon: 'fas fa-undo',
          onClick: async (_: { id: string }) => {
            await Axios.put(
              `${process.env.REACT_APP_API_URL}/api/v1/trailers/${_.id}/restore`
            )
          },
          hidden: (_: { archived: boolean }) => !_.archived,
        },
      ]}
    />
  )
}
