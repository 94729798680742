import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const EditCar: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [number, setNumber] = useState('')
  const [type, setType] = useState(0)
  const [axleType, setAxleType] = useState(0)
  const [brand, setBrand] = useState('')
  const [year, setYear] = useState(0)
  const [color, setColor] = useState('')
  const [volume, setVolume] = useState(0)
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [image, setImage] = useState<Blob>()
  const [imagePath, setImagePath] = useState('')
  const [trailerNumber, setTrailerNumber] = useState('')
  const [trailerId, setTrailerId] = useState('')

  const { carId } = useParams<{ carId: string }>()

  useEffect(() => {
    const loadCar = async () => {
      setDisabled(true)

      try {
        const car = await Axios.get<{
          number: string
          type: number
          axleType: number
          brand: string
          year: number
          color: string
          volume: number
          companyId: string
          companyName: string
          imagePath: string
          trailerId: string
          trailerNumber: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/cars/${carId}`)

        setNumber(car.data.number)
        setType(car.data.type)
        setAxleType(car.data.axleType)
        setBrand(car.data.brand)
        setYear(car.data.year)
        setColor(car.data.color)
        setVolume(car.data.volume)
        setCompanyId(car.data.companyId)
        setCompanyName(car.data.companyName)
        setImagePath(car.data.imagePath)
        setTrailerId(car.data.trailerId)
        setTrailerNumber(car.data.trailerNumber)
      } finally {
        setDisabled(false)
      }
    }

    loadCar()
  }, [carId])

  const editCar = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    const formData = new FormData()

    formData.append('number', number)
    formData.append('type', type.toString())
    formData.append('axleType', axleType !== null ? axleType.toString() : '0')
    formData.append('volume', volume ? volume.toString() : '')
    formData.append('brand', brand)
    formData.append('year', year.toString())
    formData.append('color', color)
    formData.append('companyId', companyId)
    formData.append('image', image || '')
    formData.append('trailerId', trailerId ?? '')

    try {
      const result = await Axios.put<{ id: string; imagePath: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/cars/${carId}`,
        formData
      )

      setImagePath(result.data.imagePath)

      toast.success('Транспортное средство обновлено.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editCar}>
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col-lg">
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={number}
                    onChange={(_) => setNumber(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Вид ТС</label>
                  <select
                    className="form-control"
                    value={type}
                    onChange={(_) => setType(parseInt(_.target.value))}
                  >
                    <option value={0}>Самосвал</option>
                    <option value={1}>Тягач</option>
                  </select>
                </div>
                {type === 0 ? (
                  <div className="form-group">
                    <label>Количество осей</label>
                    <select
                      className="form-control"
                      value={axleType}
                      onChange={(_) => setAxleType(parseInt(_.target.value))}
                    >
                      <option value={0}>3 осная</option>
                      <option value={1}>4 осная</option>
                      <option value={2}>5 осная</option>
                    </select>
                  </div>
                ) : null}
                <div className="form-group">
                  <label>Марка</label>
                  <input
                    className="form-control"
                    required
                    type="string"
                    value={brand}
                    onChange={(_) => setBrand(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Год выпуска</label>
                  <input
                    className="form-control"
                    required
                    type="number"
                    value={year}
                    min={1}
                    onChange={(_) => setYear(parseInt(_.target.value))}
                  />
                </div>
                <div className="form-group">
                  <label>Цвет</label>
                  <input
                    className="form-control"
                    required
                    type="string"
                    value={color}
                    onChange={(_) => setColor(_.target.value)}
                  />
                </div>
                {type === 0 ? (
                  <div className="form-group">
                    <label>Объём кузова</label>
                    <input
                      className="form-control"
                      required
                      type="number"
                      value={volume}
                      min={0.01}
                      step={0.01}
                      onChange={(_) => setVolume(parseFloat(_.target.value))}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label>Организация собственник</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={{
                      value: companyId,
                      label: companyName,
                    }}
                    loadOptions={(inputValue, callback) => {
                      const loadCompanies = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const companies = await Axios.get<{
                          items: { id: string; name: string }[]
                        }>(
                          `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1`
                        )

                        callback(
                          companies.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.name,
                            }
                          })
                        )
                      }

                      loadCompanies(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setCompanyId(_.value)
                    }}
                  />
                  <input
                    type="text"
                    value={companyId ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>
                {type === 1 ? (
                  <div className="form-group">
                    <label>Прицеп</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={{
                        value: trailerId,
                        label: trailerNumber,
                      }}
                      loadOptions={(inputValue, callback) => {
                        const loadTrailers = async (
                          inputValue: string,
                          callback: any
                        ) => {
                          let url = `${process.env.REACT_APP_API_URL}/api/v1/trailers?query=${inputValue}&page=1`

                          const trailers = await Axios.get<{
                            items: {
                              id: string
                              number: string
                            }[]
                          }>(url)

                          callback(
                            trailers.data.items.map((_) => {
                              return {
                                value: _.id,
                                label: _.number,
                              }
                            })
                          )
                        }

                        loadTrailers(inputValue, callback)
                      }}
                      onChange={(_: any) => {
                        setTrailerId(_.value)
                        setTrailerNumber(_.label)
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="col-lg">
                {imagePath ? (
                  <div className="form-group">
                    <img
                      src={imagePath}
                      alt="Фотография транспортного средства"
                      className="form-image"
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label>Фотография</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setImage(_.target.files[0])
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <button type="submit" className="btn btn-success">
                  <i className="fas fa-save"></i> Сохранить
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
