import Axios from 'axios'
import React, { useEffect } from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const AddGibddFine: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState(0)
  const [carId, setCarId] = useState<string>()
  const [trailerId, setTrailerId] = useState<string>()

  const [driverId, setDriverId] = useState<string>()
  const [driverSurname, setDriverSurname] = useState('')
  const [driverName, setDriverName] = useState('')
  const [driverPatronymic, setDriverPatronymic] = useState('')
  const [driverDisabled, setDriverDisabled] = useState(true)

  const history = useHistory()

  useEffect(() => {
    const loadDriver = async () => {
      if (carId && date && !trailerId) {
        try {
          const driver = await Axios.get<{
            id: string
            surname: string
            name: string
            patronymic?: string
          }>(
            `${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines/cars/${carId}/${date}`
          )

          setDriverId(driver.data.id)
          setDriverSurname(driver.data.surname)
          setDriverName(driver.data.name)
          setDriverPatronymic(driver.data.patronymic ?? '')
        } finally {
          setDriverDisabled(false)
        }

        return
      }

      if (trailerId && date && !carId) {
        try {
          const driver = await Axios.get<{
            id: string
            surname: string
            name: string
            patronymic?: string
          }>(
            `${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines/trailers/${trailerId}/${date}`
          )

          setDriverId(driver.data.id)
          setDriverSurname(driver.data.surname)
          setDriverName(driver.data.name)
          setDriverPatronymic(driver.data.patronymic ?? '')
        } finally {
          setDriverDisabled(false)
        }

        return
      }

      setDriverDisabled(true)
      setDriverId(undefined)
      setDriverSurname('')
      setDriverName('')
      setDriverPatronymic('')
    }

    loadDriver()
  }, [date, carId, trailerId])

  const addGibddFine = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if ((!trailerId && !carId) || (trailerId && carId)) {
        alert('Выберите прицеп или транспортное средство.')
        return
      }

      const gibddFine = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines`,
        {
          date,
          amount,
          carId,
          trailerId,
          driverId,
        }
      )

      toast.success('Штраф ГИБДД добавлен.')

      history.push(`/dashboard/gibdd-fines/${gibddFine.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addGibddFine}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Дата</label>
              <input
                className="form-control"
                required
                type="date"
                value={date.toString()}
                onChange={(_) => setDate(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Сумма</label>
              <input
                className="form-control"
                required
                type="number"
                value={amount}
                min={0.01}
                step={0.01}
                onChange={(_) => setAmount(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Транспортное средство</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/cars?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return { value: _.id, label: _.number }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) =>
                  _ ? setCarId(_.value) : setCarId(undefined)
                }
              />
            </div>
            <div className="form-group">
              <label>Прицеп</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadTrailers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/trailers?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return { value: _.id, label: _.number }
                      })
                    )
                  }

                  loadTrailers(inputValue, callback)
                }}
                onChange={(_: any) =>
                  _ ? setTrailerId(_.value) : setTrailerId(undefined)
                }
              />
            </div>
            <div className="form-group">
              <label>Водитель</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isDisabled={driverDisabled}
                value={{
                  value: driverId,
                  label: `${driverSurname} ${driverName} ${driverPatronymic}`,
                }}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: {
                        id: string
                        surname: string
                        name: string
                        patronymic: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/drivers?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.surname} ${_.name} ${_.patronymic ?? ''}`,
                          surname: _.surname,
                          name: _.name,
                          patronymic: _.patronymic,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setDriverId(_.value)
                  setDriverSurname(_.surname)
                  setDriverName(_.name)
                  setDriverPatronymic(_.patronymic)
                }}
              />
              <input
                type="text"
                value={driverId ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
