import Axios from 'axios'
import React, { Component, FormEvent } from 'react'
import { Redirect } from 'react-router-dom'
import Footer from '../footer/footer'
import InitializeState from './initializeState'

export default class Initialize extends Component<any, InitializeState> {
  constructor(props: any) {
    super(props)

    this.state = new InitializeState()

    this.initialize = this.initialize.bind(this)
  }
  async componentDidMount() {
    try {
      await Axios.get(`${process.env.REACT_APP_API_URL}/api/v1/initialize`)

      this.setState({ initialized: true })
    } catch (error) {
      this.setState({ initialized: false })
    }
  }
  render() {
    if (this.state.initialized) {
      return <Redirect to="/" />
    }

    return (
      <>
        <div className="container-fluid app">
          <div className="row pt-3 text-center">
            <div className="col-lg-4 offset-lg-4">
              <img
                src="/logo192.png"
                alt={process.env.REACT_APP_NAME}
                className="signin-logo"
              />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-4 offset-lg-4">
              <div className="alert alert-warning" role="alert">
                Приложение не было инциировано. Введите ниже email и пароль
                администратора по умолчанию. После этого вы сможете войти в
                приложение.
              </div>
              <form onSubmit={this.initialize}>
                <fieldset disabled={this.state.disabled}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      required
                      className="form-control"
                      id="email"
                      value={this.state.email}
                      onChange={(_) => this.setState({ email: _.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Пароль</label>
                    <input
                      type="password"
                      required
                      minLength={6}
                      className="form-control"
                      id="password"
                      value={this.state.password}
                      onChange={(_) =>
                        this.setState({ password: _.target.value })
                      }
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Инициализировать
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
  async initialize(event: FormEvent) {
    event.preventDefault()

    this.setState({ disabled: true })

    try {
      await Axios.post(`${process.env.REACT_APP_API_URL}/api/v1/initialize`, {
        email: this.state.email,
        password: this.state.password,
      })

      localStorage.setItem('initialized', 'true')
      window.location.href = '/'
    } catch {
      this.setState({ disabled: false })
    }
  }
}
