import Axios from 'axios'
import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Companies: FunctionComponent = () => {
  return (
    <Table
      addUrl="/dashboard/add-company"
      apiUrl="/api/v1/companies"
      defaultSorting={{ name: 'name', direction: 'asc' }}
      items={[
        {
          name: 'name',
          title: 'Название',
          type: 'string',
        },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-company',
        },
        {
          title: 'Архивировать',
          icon: 'fas fa-archive',
          onClick: async (_: { id: string }) => {
            await Axios.delete(
              `${process.env.REACT_APP_API_URL}/api/v1/companies/${_.id}`
            )
          },
          hidden: (_: { archived: boolean }) => _.archived,
        },
        {
          title: 'Вернуть из архива',
          icon: 'fas fa-undo',
          onClick: async (_: { id: string }) => {
            await Axios.put(
              `${process.env.REACT_APP_API_URL}/api/v1/companies/${_.id}/restore`
            )
          },
          hidden: (_: { archived: boolean }) => !_.archived,
        },
      ]}
    />
  )
}
