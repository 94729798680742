import React, { useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Drivers: FunctionComponent = () => {
  const [showLocked, setShowLocked] = useState(false)

  const tableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
  }>(null)

  return (
    <>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="showLocked"
          checked={showLocked}
          onChange={(_) => {
            const newValue = !showLocked

            setShowLocked(newValue)

            tableRef.current?.updateParams(
              'showLocked',
              newValue ? 'true' : 'false'
            )

            tableRef.current?.refresh()
          }}
        />
        <label className="form-check-label" htmlFor="showLocked">
          Показывать заблокированных
        </label>
      </div>
      <Table
        apiUrl="/api/v1/drivers"
        addUrl="/dashboard/add-driver"
        ref={tableRef}
        defaultSorting={{ name: 'surname', direction: 'asc' }}
        items={[
          { title: 'Фотография', name: 'imagePath', type: 'image' },
          { title: 'Фамилия', name: 'surname', type: 'string' },
          { title: 'Имя', name: 'name', type: 'string' },
          { title: 'Отчество', name: 'patronymic', type: 'string' },
          { title: 'День рождения', name: 'birthday', type: 'date' },
          {
            title: 'Дата принятия на работу',
            name: 'jobStartDate',
            type: 'date',
          },
          {
            title: 'Номер транспортного средства',
            name: 'carNumber',
            type: 'string',
          },
          { title: 'Телефон', name: 'phone', type: 'string' },
          { title: 'Заблокирован', name: 'locked', type: 'boolean' },
        ]}
        actions={[
          {
            title: 'Изменить',
            icon: 'far fa-edit',
            url: '/dashboard/edit-driver',
          },
          {
            title: 'Изменить пароль',
            icon: 'fas fa-key',
            url: '/dashboard/edit-driver-password',
          },
        ]}
      />
    </>
  )
}
