import Axios from 'axios'
import React, { useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const DeleteGibddFine: FunctionComponent = () => {
  const { gibddFineId } = useParams<{ gibddFineId: string }>()
  const history = useHistory()

  useEffect(() => {
    const deleteGibddFine = async () => {
      await Axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines/${gibddFineId}`
      )

      toast.success('Штраф ГИБДД удалён.')

      history.push('/dashboard/gibdd-fines')
    }

    deleteGibddFine()
  }, [gibddFineId, history])

  return (
    <div className="row">
      <div className="col">Удалить штраф ГИБДД.</div>
    </div>
  )
}
