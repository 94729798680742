import React, { FormEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'

export const EditAddress = () => {
  const { addressId } = useParams<{ addressId: string }>()
  const [disabled, setDisabled] = useState(false)

  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {
    const loadAddress = async () => {
      setDisabled(true)

      try {
        const address = await Axios.get<{
          name: string
          value: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/addresses/${addressId}`)

        setName(address.data.name)
        setValue(address.data.value)
      } finally {
        setDisabled(false)
      }
    }

    loadAddress()
  }, [addressId])

  const editAddress = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/addresses/${addressId}`,
        {
          name,
          value,
        }
      )

      setDisabled(false)

      toast.success('Адрес изменён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={(_) => editAddress(_)}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Адрес</label>
              <input
                type="string"
                className="form-control"
                id="name"
                value={name}
                onChange={(_) => setName(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Подробный адрес</label>
              <input
                type="text"
                className="form-control"
                id="value"
                value={value}
                onChange={(_) => setValue(_.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
