import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Contractors: FunctionComponent = () => {
  return (
    <Table
      apiUrl="/api/v1/contractors"
      addUrl="/dashboard/add-contractor"
      defaultSorting={{ name: 'name', direction: 'asc' }}
      tableActions={[
        {
          title: 'Пересчёты цен',
          icon: 'fas fa-ruble-sign',
          url: '/dashboard/contractors-recalculations',
        },
      ]}
      items={[
        { title: 'Название', name: 'name', type: 'string' },
        { title: 'Плательщик, название', name: 'payerName', type: 'string' },
        { title: 'Плательщик, ИНН', name: 'payerInn', type: 'string' },
        {
          title: 'Тип',
          name: 'type',
          type: 'enum',
          enum: ['Объект', 'Карьер', 'Полигон', 'База'],
        },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-contractor',
        },
        {
          title: 'Удалить',
          icon: 'fas fa-trash-alt',
          url: '/dashboard/delete-contractor',
        },
      ]}
    />
  )
}
