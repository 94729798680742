export const getMonth = (month: number) => {
  let monthString = ''

  switch (month) {
    case 1: {
      monthString = 'январь'
      break
    }
    case 2: {
      monthString = 'февраль'
      break
    }
    case 3: {
      monthString = 'март'
      break
    }
    case 4: {
      monthString = 'апрель'
      break
    }
    case 5: {
      monthString = 'май'
      break
    }
    case 6: {
      monthString = 'июнь'
      break
    }
    case 7: {
      monthString = 'июль'
      break
    }
    case 8: {
      monthString = 'август'
      break
    }
    case 9: {
      monthString = 'сентябрь'
      break
    }
    case 10: {
      monthString = 'октябрь'
      break
    }
    case 11: {
      monthString = 'ноябрь'
      break
    }
    case 12: {
      monthString = 'декабрь'
      break
    }
    default: {
      throw Error('Неизвестный месяц.')
    }
  }

  return monthString
}
