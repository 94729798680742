import React, { FunctionComponent, useState } from 'react'
import Sidenav from './sidenav/sidenav'
import './dashboard.scss'
import ProtectedRoute from '../../infrastructure/react-router-dom/protectedRoute'
import { Users } from './users/users'
import { Breadcrumbs } from 'react-breadcrumbs'
import { Signout } from './signout/signout'
import { AddUser } from './users/addUser'
import { EditUser } from './users/editUser'
import DashboardRoute from './dashboardRoute'
import { EditUserPassword } from './users/editUserPassword'
import { Drivers } from './drivers/drivers'
import { AddDriver } from './drivers/addDriver'
import { EditDriverPassword } from './drivers/editDriverPassword'
import { Cars } from './cars/cars'
import { AddCar } from './cars/addCar'
import { EditCar } from './cars/editCar'
import { ArchiveCar } from './cars/archiveCar'
import { Contractors } from './contractors/contractors'
import { AddContractor } from './contractors/addContractor'
import { EditContractor } from './contractors/editContractor'
import { DeleteContractor } from './contractors/deleteContractor'
import { GibddFines } from './gibddFines/gibddFines'
import { AddGibddFine } from './gibddFines/addGibddFine'
import { EditGibddFine } from './gibddFines/editGibddFine'
import { DeleteGibddFine } from './gibddFines/deleteGibddFines'
import { EditFine } from './fines/editFine'
import { AddFine } from './fines/addFine'
import { AddAdvance } from './advances/addAdvance'
import { EditAdvance } from './advances/editAdvance'
import { Materials } from './materials/materials'
import { AddMaterial } from './materials/addMaterial'
import { ArchiveMaterial } from './materials/archiveMaterial'
import { EditMaterial } from './materials/editMaterial'
import { Trailers } from './trailers/trailers'
import { AddTrailer } from './trailers/addTrailer'
import { EditTrailer } from './trailers/editTrailer'
import { ArchiveTrailer } from './trailers/archiveTrailer'
import { EditDriverTabs } from './drivers/editDriverTabs'
import { EditBonus } from './bonuses/editBonus'
import { AddBonus } from './bonuses/addBonus'
import { Repairs } from './repairs/repairs'
import { AddRepair } from './repairs/addRepair'
import { EditRepair } from './repairs/editRepair'
import { Tariffs } from './tariffs/tariffs'
import { AddTariffTrip } from './tariffs/tariffTrips/addTariffTrip'
import { EditTariffTrip } from './tariffs/tariffTrips/editTariffTrip'
import { AddTariffRent } from './tariffs/tariffRents/addTariffRent'
import { EditTariffRent } from './tariffs/tariffRents/editTariffRent'
import { Trips } from './trips/trips'
import { AddTrip } from './trips/addTrip'
import { Companies } from './companies/companies'
import { AddCompany } from './companies/addCompany'
import { EditCompany } from './companies/editCompany'
import { EditTrip } from './trips/editTrip'
import { TripsDocuments } from './trips/tripsDocuments'
import { Payrolls } from './payrolls/payrolls'
import { PayrollsDocuments } from './payrolls/payrollsDocuments'
import { PayrollsDocumentsSignature } from './payrolls/payrollsDocumentsSignature'
import { ViewPayroll } from './payrolls/viewPayroll'
import { CompaniesDrivers } from './companiesDrivers/companiesDrivers'
import { Payers } from './payers/payers'
import { AddPayer } from './payers/addPayer'
import { EditPayer } from './payers/editPayer'
import { AddIdle } from './idles/addIdle'
import { EditIdle } from './idles/editIdle'
import jwt_decode from 'jwt-decode'
import { NavLink } from 'react-router-dom'
import { Addresses } from './addresses/addresses'
import { AddAddress } from './addresses/addAddress'
import { EditAddress } from './addresses/editAddress'
import { DashboardHome } from './dashboardHome'
import { Recalculations } from './contractors/recalculations/recalculations'
import { TripsImports } from './trips/tripsImports'

export const Dashboard: FunctionComponent = () => {
  const [sidenavOpen, setSidenavOpen] = useState(window.innerWidth > 1224)

  const routes: DashboardRoute[] = [
    new DashboardRoute({
      component: DashboardHome,
      routePath: '/dashboard/home',
      breadcrumbUrl: '/dashboard/home',
      children: [],
    }),
    new DashboardRoute({
      component: Users,
      title: 'Пользователи',
      routePath: '/dashboard/users',
      breadcrumbUrl: '/dashboard/users',
      children: [
        new DashboardRoute({
          component: AddUser,
          title: 'Новый пользователь',
          routePath: '/dashboard/add-user',
          breadcrumbUrl: '/dashboard/add-user',
          children: [],
        }),
        new DashboardRoute({
          component: EditUser,
          title: 'Редактировать пользователя',
          routePath: '/dashboard/edit-user/:userId',
          breadcrumbUrl: '/dashboard/edit-user',
          children: [],
        }),
        new DashboardRoute({
          component: EditUserPassword,
          title: 'Редактировать пароль',
          routePath: '/dashboard/edit-user-password/:userId',
          breadcrumbUrl: '/dashboard/edit-user-password',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Drivers,
      title: 'Водители',
      routePath: '/dashboard/drivers',
      breadcrumbUrl: '/dashboard/drivers',
      children: [
        new DashboardRoute({
          component: AddDriver,
          title: 'Добавить водителя',
          routePath: '/dashboard/add-driver',
          breadcrumbUrl: '/dashboard/add-driver',
          children: [],
        }),
        new DashboardRoute({
          component: EditDriverTabs,
          title: 'Редактировать водителя',
          routePath: '/dashboard/edit-driver/:driverId',
          breadcrumbUrl: '/dashboard/edit-driver',
          children: [],
        }),
        new DashboardRoute({
          component: EditDriverPassword,
          title: 'Редактировать пароль водителя',
          routePath: '/dashboard/edit-driver-password/:driverId',
          breadcrumbUrl: '/dashboard/edit-driver-password',
          children: [],
        }),
        new DashboardRoute({
          component: AddFine,
          title: 'Добавить личный штраф',
          routePath: '/dashboard/add-fine/:driverId',
          breadcrumbUrl: '/dashboard/add-fine',
          children: [],
        }),
        new DashboardRoute({
          component: EditFine,
          title: 'Редактировать личный штраф',
          routePath: '/dashboard/edit-fine/:driverId/:fineId',
          breadcrumbUrl: '/dashboard/edit-fine',
          children: [],
        }),
        new DashboardRoute({
          component: AddAdvance,
          title: 'Добавить аванс',
          routePath: '/dashboard/add-advance/:driverId',
          breadcrumbUrl: '/dashboard/add-advance',
          children: [],
        }),
        new DashboardRoute({
          component: EditAdvance,
          title: 'Редактировать аванс',
          routePath: '/dashboard/edit-advance/:driverId/:advanceId',
          breadcrumbUrl: '/dashboard/edit-advance',
          children: [],
        }),
        new DashboardRoute({
          component: AddBonus,
          title: 'Добавить премию',
          routePath: '/dashboard/add-bonus/:driverId',
          breadcrumbUrl: '/dashboard/add-bonus',
          children: [],
        }),
        new DashboardRoute({
          component: EditBonus,
          title: 'Редактировать премию',
          routePath: '/dashboard/edit-bonus/:driverId/:bonusId',
          breadcrumbUrl: '/dashboard/edit-bonus',
          children: [],
        }),
        new DashboardRoute({
          component: AddIdle,
          title: 'Добавить простой',
          routePath: '/dashboard/add-idle/:driverId',
          breadcrumbUrl: '/dashboard/add-idle',
          children: [],
        }),
        new DashboardRoute({
          component: EditIdle,
          title: 'Редактировать простой',
          routePath: '/dashboard/edit-idle/:driverId/:idleId',
          breadcrumbUrl: '/dashboard/edit-idle',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Cars,
      title: 'Транспортные средства',
      routePath: '/dashboard/cars',
      breadcrumbUrl: '/dashboard/cars',
      children: [
        new DashboardRoute({
          component: AddCar,
          title: 'Добавить транспортное средство',
          routePath: '/dashboard/add-car',
          breadcrumbUrl: '/dashboard/add-car',
          children: [],
        }),
        new DashboardRoute({
          component: EditCar,
          title: 'Редактировать транспортное средство',
          routePath: '/dashboard/edit-car/:carId',
          breadcrumbUrl: '/dashboard/edit-car',
          children: [],
        }),
        new DashboardRoute({
          component: ArchiveCar,
          title: 'Архивировать транспортное средство',
          routePath: '/dashboard/archive-car/:carId',
          breadcrumbUrl: '/dashboard/archive-car',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Trailers,
      title: 'Прицепы',
      routePath: '/dashboard/trailers',
      breadcrumbUrl: '/dashboard/trailers',
      children: [
        new DashboardRoute({
          component: AddTrailer,
          title: 'Добавить прицеп',
          routePath: '/dashboard/add-trailer',
          breadcrumbUrl: '/dashboard/add-trailer',
          children: [],
        }),
        new DashboardRoute({
          component: EditTrailer,
          title: 'Редактировать прицеп',
          routePath: '/dashboard/edit-trailer/:trailerId',
          breadcrumbUrl: '/dashboard/edit-trailer',
          children: [],
        }),
        new DashboardRoute({
          component: ArchiveTrailer,
          title: 'Архивировать прицеп',
          routePath: '/dashboard/archive-trailer/:trailerId',
          breadcrumbUrl: '/dashboard/archive-trailer',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Materials,
      title: 'Материалы',
      routePath: '/dashboard/materials',
      breadcrumbUrl: '/dashboard/materials',
      children: [
        new DashboardRoute({
          component: AddMaterial,
          title: 'Добавить материал',
          routePath: '/dashboard/add-material',
          breadcrumbUrl: '/dashboard/add-material',
          children: [],
        }),
        new DashboardRoute({
          component: EditMaterial,
          title: 'Редактировать материал',
          routePath: '/dashboard/edit-material/:materialId',
          breadcrumbUrl: '/dashboard/edit-material',
          children: [],
        }),
        new DashboardRoute({
          component: ArchiveMaterial,
          title: 'Архивировать материал',
          routePath: '/dashboard/archive-material/:materialId',
          breadcrumbUrl: '/dashboard/archive-material',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Contractors,
      title: 'Контрагенты',
      routePath: '/dashboard/contractors',
      breadcrumbUrl: '/dashboard/contractors',
      children: [
        new DashboardRoute({
          component: Recalculations,
          title: 'Пересчёты цен',
          routePath: '/dashboard/contractors-recalculations',
          breadcrumbUrl: '/dashboard/contractors-recalculations',
          children: [],
        }),
        new DashboardRoute({
          component: AddContractor,
          title: 'Добавить контрагента',
          routePath: '/dashboard/add-contractor',
          breadcrumbUrl: '/dashboard/add-contractor',
          children: [],
        }),
        new DashboardRoute({
          component: EditContractor,
          title: 'Редактировать контрагента',
          routePath: '/dashboard/edit-contractor/:contractorId',
          breadcrumbUrl: '/dashboard/edit-contractor',
          children: [],
        }),
        new DashboardRoute({
          component: DeleteContractor,
          title: 'Удалить контрагента',
          routePath: '/dashboard/delete-contractor/:contractorId',
          breadcrumbUrl: '/dashboard/delete-contractor',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Payers,
      title: 'Плательщики',
      routePath: '/dashboard/payers',
      breadcrumbUrl: '/dashboard/payers',
      children: [
        new DashboardRoute({
          component: AddPayer,
          title: 'Добавить плательщика',
          routePath: '/dashboard/add-payer',
          breadcrumbUrl: '/dashboard/add-payer',
          children: [],
        }),
        new DashboardRoute({
          component: EditPayer,
          title: 'Редактировать плательщика',
          routePath: '/dashboard/edit-payer/:payerId',
          breadcrumbUrl: '/dashboard/edit-payer',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: GibddFines,
      title: 'Штрафы ГИБДД',
      routePath: '/dashboard/gibdd-fines',
      breadcrumbUrl: '/dashboard/gibdd-fines',
      children: [
        new DashboardRoute({
          component: AddGibddFine,
          title: 'Добавить штраф ГИБДД',
          routePath: '/dashboard/add-gibdd-fine',
          breadcrumbUrl: '/dashboard/add-gibdd-fine',
          children: [],
        }),
        new DashboardRoute({
          component: EditGibddFine,
          title: 'Редактировать штраф ГИБДД',
          routePath: '/dashboard/edit-gibdd-fine/:gibddFineId',
          breadcrumbUrl: '/dashboard/edit-gibdd-fine',
          children: [],
        }),
        new DashboardRoute({
          component: DeleteGibddFine,
          title: 'Удалить штраф ГИБДД',
          routePath: '/dashboard/delete-gibdd-fine/:gibddFineId',
          breadcrumbUrl: '/dashboard/delete-gibdd-fine',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Repairs,
      title: 'Ремонты',
      routePath: '/dashboard/repairs',
      breadcrumbUrl: '/dashboard/repairs',
      children: [
        new DashboardRoute({
          component: AddRepair,
          title: 'Добавить ремонт',
          routePath: '/dashboard/add-repair',
          breadcrumbUrl: '/dashboard/add-repair',
          children: [],
        }),
        new DashboardRoute({
          component: EditRepair,
          title: 'Редактировать ремонт',
          routePath: '/dashboard/edit-repair/:repairId',
          breadcrumbUrl: '/dashboard/edit-repair',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Tariffs,
      title: 'Тарифы',
      routePath: '/dashboard/tariffs',
      breadcrumbUrl: '/dashboard/tariffs',
      children: [
        new DashboardRoute({
          component: AddTariffTrip,
          title: 'Добавить тариф поездки',
          routePath: '/dashboard/add-tariff-trip',
          breadcrumbUrl: '/dashboard/add-tariff-trip',
          children: [],
        }),
        new DashboardRoute({
          component: EditTariffTrip,
          title: 'Редактировать тариф поездки',
          routePath: '/dashboard/edit-tariff-trip/:tariffTripId',
          breadcrumbUrl: '/dashboard/edit-tariff-trip',
          children: [],
        }),
        new DashboardRoute({
          component: AddTariffRent,
          title: 'Добавить тариф аренды',
          routePath: '/dashboard/add-tariff-rent',
          breadcrumbUrl: '/dashboard/add-tariff-rent',
          children: [],
        }),
        new DashboardRoute({
          component: EditTariffRent,
          title: 'Редактировать тариф аренды',
          routePath: '/dashboard/edit-tariff-rent/:tariffRentId',
          breadcrumbUrl: '/dashboard/edit-tariff-rent',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Trips,
      title: 'Реестр поездок',
      routePath: '/dashboard/trips',
      breadcrumbUrl: '/dashboard/trips',
      children: [
        new DashboardRoute({
          component: AddTrip,
          title: 'Добавить поездку',
          routePath: '/dashboard/add-trip',
          breadcrumbUrl: '/dashboard/add-trip',
          children: [],
        }),
        new DashboardRoute({
          component: EditTrip,
          title: 'Редактировать поездку',
          routePath: '/dashboard/edit-trip/:tripId',
          breadcrumbUrl: '/dashboard/edit-trip',
          children: [],
        }),
        new DashboardRoute({
          component: TripsDocuments,
          title: 'Экспорты',
          routePath: '/dashboard/trips-documents',
          breadcrumbUrl: '/dashboard/trips-documents',
          children: [],
        }),
        new DashboardRoute({
          component: TripsImports,
          title: 'Импорты',
          routePath: '/dashboard/trips-imports',
          breadcrumbUrl: '/dashboard/trips-imports',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Payrolls,
      title: 'Расчетные листы',
      routePath: '/dashboard/payrolls',
      breadcrumbUrl: '/dashboard/payrolls',
      children: [
        new DashboardRoute({
          component: PayrollsDocuments,
          title: 'Экспорты',
          routePath: '/dashboard/payrolls-documents',
          breadcrumbUrl: '/dashboard/payrolls-documents',
          children: [],
        }),
        new DashboardRoute({
          component: PayrollsDocumentsSignature,
          title: 'Ведомости',
          routePath: '/dashboard/payrolls-documents-signature',
          breadcrumbUrl: '/dashboard/payrolls-documents-signature',
          children: [],
        }),
        new DashboardRoute({
          component: ViewPayroll,
          title: 'Расчетный лист',
          routePath: '/dashboard/view-payroll/:year/:month/:driverId',
          breadcrumbUrl: '/dashboard/view-payroll',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: CompaniesDrivers,
      title: 'Организация-водитель',
      routePath: '/dashboard/companies-drivers',
      breadcrumbUrl: '/dashboard/companies-drivers',
      children: [],
    }),
    new DashboardRoute({
      component: Companies,
      title: 'Организации',
      routePath: '/dashboard/companies',
      breadcrumbUrl: '/dashboard/companies',
      children: [
        new DashboardRoute({
          component: AddCompany,
          title: 'Добавить компанию',
          routePath: '/dashboard/add-company',
          breadcrumbUrl: '/dashboard/add-company',
          children: [],
        }),
        new DashboardRoute({
          component: EditCompany,
          title: 'Редактировать компанию',
          routePath: '/dashboard/edit-company/:companyId',
          breadcrumbUrl: '/dashboard/edit-company',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Addresses,
      title: 'Адреса',
      routePath: '/dashboard/addresses',
      breadcrumbUrl: '/dashboard/addresses',
      children: [
        new DashboardRoute({
          component: AddAddress,
          title: 'Добавить адрес',
          routePath: '/dashboard/add-address',
          breadcrumbUrl: '/dashboard/add-address',
          children: [],
        }),
        new DashboardRoute({
          component: EditAddress,
          title: 'Редактировать адрес',
          routePath: '/dashboard/edit-address/:addressId',
          breadcrumbUrl: '/dashboard/edit-address',
          children: [],
        }),
      ],
    }),
    new DashboardRoute({
      component: Signout,
      title: 'Выход',
      routePath: '/dashboard/signout',
      breadcrumbUrl: '/dashboard/signout',
      children: [],
    }),
  ]

  const generateProtectedRoute = (
    route: DashboardRoute,
    breadcrumbs: { title: string; url: string }[]
  ): any => {
    if (route.item.title) {
      breadcrumbs.push({
        title: route.item.title,
        url: route.item.breadcrumbUrl,
      })
    }

    return (
      <>
        <ProtectedRoute
          key={route.item.title}
          component={route.item.component}
          path={route.item.routePath}
          breadcrumbs={[...breadcrumbs]}
        />
        {route.item.children.length > 0
          ? route.item.children.map((_) =>
              generateProtectedRoute(_, [...breadcrumbs])
            )
          : null}
      </>
    )
  }

  const decodedJwt: any = jwt_decode(localStorage.getItem('tokenJwt') || '')

  const [isDarkTheme, setIsDarkTheme] = useState(
    window.localStorage.getItem('theme') === 'dark'
  )

  return (
    <div className="wrapper">
      {sidenavOpen ? <Sidenav /> : null}
      <div className="main">
        <nav className="navbar navbar-expand navbar-light navbar-bg">
          <button
            className="sidebar-toggle btn btn-link"
            onClick={() => setSidenavOpen(!sidenavOpen)}
          >
            <i className="hamburger align-self-center"></i>
          </button>
          <div className="col text-right">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="showLocked"
                checked={isDarkTheme}
                onChange={(_) => {
                  const newValue = !isDarkTheme

                  setIsDarkTheme(newValue)

                  if (newValue) {
                    window.localStorage.setItem('theme', 'dark')
                  } else {
                    window.localStorage.setItem('theme', 'light')
                  }

                  window.location.reload()
                }}
              />
              <label className="form-check-label" htmlFor="showLocked">
                Тёмная тема
              </label>
            </div>
            <span>{decodedJwt.userEmail}</span>

            <NavLink to="/dashboard/signout" className="ml-2" title="Выйти">
              <i className="fas fa-sign-out-alt"></i>
            </NavLink>
          </div>
        </nav>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col">
              <Breadcrumbs className="breadcrumb" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {routes.map((_) => generateProtectedRoute(_, []))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
