import React from 'react'
import { Table } from '../table/table'
import Axios from 'axios'

export const Addresses = () => {
  return (
    <Table
      apiUrl="/api/v1/addresses"
      addUrl="/dashboard/add-address"
      defaultSorting={{ name: 'name', direction: 'asc' }}
      items={[
        { title: 'Адрес', name: 'name', type: 'string' },
        { title: 'Подробный адрес', name: 'value', type: 'string' },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-address',
        },
        {
          title: 'Удалить',
          icon: 'fas fa-trash-alt',
          onClick: async (_: { id: string }) => {
            await Axios.delete(
              `${process.env.REACT_APP_API_URL}/api/v1/addresses/${_.id}`
            )
          },
        },
      ]}
    />
  )
}
