import Axios from 'axios'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import NumberFormat from 'react-number-format'

export const TripsStatistics = forwardRef((any, ref) => {
  const [quantity, setQuantity] = useState(0)
  const [markedQuantity, setMarkedQuantity] = useState(0)
  const [profit, setProfit] = useState(0)

  const [volumeFromTons, setVolumeFromTons] = useState(0)
  const [volumeFromCubicMeters, setVolumeFromCubicMeters] = useState(0)

  const [volumeToTons, setVolumeToTons] = useState(0)
  const [volumeToCubicMeters, setVolumeToCubicMeters] = useState(0)

  const [refresh, setRefresh] = useState(false)

  const [columnsFilters, setColumnsFilters] = useState<
    { name: string; value: string }[]
  >([])

  useEffect(() => {
    const loadStatistics = async () => {
      const columnsFiltersParams: any = {}

      let counter = 0

      for (const columnFilter of columnsFilters) {
        if (columnFilter.value) {
          columnsFiltersParams[`columnsFilters[${counter}].name`] =
            columnFilter.name

          columnsFiltersParams[`columnsFilters[${counter}].value`] =
            columnFilter.value

          counter++
        }
      }

      const tripsStatistics = await Axios.get<{
        quantity: number
        markedQuantity: number
        profit: number

        volumeFromTons: number
        volumeFromCubicMeters: number

        volumeToTons: number
        volumeToCubicMeters: number
      }>(`${process.env.REACT_APP_API_URL}/api/v1/trips-statistics`, {
        params: { ...columnsFiltersParams },
      })

      setQuantity(tripsStatistics.data.quantity)
      setMarkedQuantity(tripsStatistics.data.markedQuantity)
      setProfit(tripsStatistics.data.profit)

      setVolumeFromTons(tripsStatistics.data.volumeFromTons)
      setVolumeFromCubicMeters(tripsStatistics.data.volumeFromCubicMeters)

      setVolumeToTons(tripsStatistics.data.volumeToTons)
      setVolumeToCubicMeters(tripsStatistics.data.volumeToCubicMeters)
    }

    loadStatistics()
  }, [refresh, columnsFilters])

  useImperativeHandle(ref, () => {
    return {
      refresh: () => setRefresh(!refresh),
      updateFilters: (_: { name: string; value: string }[]) =>
        setColumnsFilters([..._]),
    }
  })

  return (
    <div className="row mb-1">
      <div className="col-lg">
        Выручка: <br />
        <strong>
          <NumberFormat
            value={profit}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </strong>
      </div>
      <div className="col-lg">
        Поездки: <br />
        <strong>{quantity}</strong>
      </div>
      <div className="col-lg">
        Объём загрузки: <br />
        <strong>
          <NumberFormat
            value={volumeFromCubicMeters}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix=" М³"
          />
        </strong>{' '}
        <br />
        <strong>
          <NumberFormat
            value={volumeFromTons}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix=" Тонны"
          />
        </strong>
      </div>
      <div className="col-lg">
        Объём разгрузки: <br />
        <strong>
          <NumberFormat
            value={volumeToCubicMeters}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix=" М³"
          />
        </strong>{' '}
        <br />
        <strong>
          <NumberFormat
            value={volumeToTons}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            suffix=" Тонны"
          />
        </strong>
      </div>
      <div className="col-lg">
        Отметки: <br />
        <strong>{markedQuantity}</strong>
      </div>
    </div>
  )
})
