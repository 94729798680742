import Axios from 'axios'
import React, { useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const ArchiveTrailer: FunctionComponent = () => {
  const { trailerId } = useParams<{ trailerId: string }>()
  const history = useHistory()

  useEffect(() => {
    const archiveCar = async () => {
      await Axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/trailers/${trailerId}`
      )

      toast.success('Прицеп архивирован.')

      history.push('/dashboard/trailers')
    }

    archiveCar()
  }, [trailerId, history])

  return (
    <div className="row">
      <div className="col">Архивировать прицеп.</div>
    </div>
  )
}
