import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DriverFio } from '../drivers/driverFio'

export const EditFine: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState(0)
  const [comment, setComment] = useState('')

  const { driverId } = useParams<{ driverId: string }>()
  const { fineId } = useParams<{ fineId: string }>()

  useEffect(() => {
    const loadFine = async () => {
      setDisabled(true)

      try {
        const gibddFine = await Axios.get<{
          date: string
          amount: number
          deduction: number
          carNumber: string
          carId: string
          comment: string
        }>(
          `${process.env.REACT_APP_API_URL}/api/v1/fines/${driverId}/${fineId}`
        )
        setDate(gibddFine.data.date.replace('T00:00:00', ''))
        setAmount(gibddFine.data.amount)
        setComment(gibddFine.data.comment)
      } finally {
        setDisabled(false)
      }
    }

    loadFine()
  }, [driverId, fineId])

  const editFine = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/fines/${fineId}`,
        {
          date,
          amount,
          comment,
        }
      )

      toast.success('Личный штраф обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editFine}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>
                <DriverFio driverId={driverId} />
              </label>
            </div>
            <div className="form-group">
              <label>Дата</label>
              <input
                className="form-control"
                required
                type="date"
                value={date.toString().replace('+00:00', '')}
                onChange={(_) => setDate(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Сумма</label>
              <input
                className="form-control"
                required
                type="number"
                min={0.01}
                value={amount}
                step={0.01}
                onChange={(_) => setAmount(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Комментарий</label>
              <textarea
                className="form-control"
                rows={5}
                value={comment}
                onChange={(_) => setComment(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
