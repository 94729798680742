import Axios from 'axios'
import React, { useEffect } from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditMaterial: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')

  const { materialId } = useParams<{ materialId: string }>()

  useEffect(() => {
    const loadMaterial = async () => {
      setDisabled(true)

      try {
        const material = await Axios.get<{
          name: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/materials/${materialId}`)

        setName(material.data.name)
      } finally {
        setDisabled(false)
      }
    }

    loadMaterial()
  }, [materialId])

  const editMaterial = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/materials/${materialId}`,
        {
          name,
        }
      )

      toast.success('Материал обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editMaterial}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Название</label>
              <input
                className="form-control"
                required
                type="text"
                value={name}
                onChange={(_) => setName(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
