import Axios from 'axios'
import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditDriverPassword: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [password, setPassword] = useState('')

  const { driverId } = useParams<{ driverId: string }>()

  const updateDriverPassword = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/drivers/${driverId}/password`,
        {
          password,
        }
      )

      toast.success('Пароль водителя обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={updateDriverPassword}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Пароль</label>
              <input
                className="form-control"
                required
                type="text"
                value={password}
                onChange={(_) => setPassword(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
