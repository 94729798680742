import React from 'react'
import { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './sidenav.scss'

export default class Sidenav extends Component {
  render() {
    return (
      <nav id="sidebar" className="sidebar">
        <div className="sidebar-content js-simplebar">
          <Link className="sidebar-brand" to="/">
            <img
              src="/logo192.png"
              alt={process.env.REACT_APP_NAME}
              className="sidenav-header-logo"
            />
            <span className="align-middle ml-1">
              {process.env.REACT_APP_NAME}
            </span>
          </Link>
          <ul className="sidebar-nav">
            <li className="sidebar-header">Администрирование</li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/drivers"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas fa-user-astronaut"></i> Водители
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/repairs"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas fa-wrench"></i> Ремонты
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/gibdd-fines"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas fa-ruble-sign"></i> Штрафы ГИБДД
              </NavLink>
            </li>

            <li className="sidebar-header">Отчёты</li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/companies-drivers"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Организация-водитель
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/payrolls"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Расчетные листы
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/trips"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Реестр поездок
              </NavLink>
            </li>

            <li className="sidebar-header">Справочники</li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/companies"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Организации
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/contractors"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Контрагенты
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/materials"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Материалы
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/payers"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Плательщики
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/users"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Пользователи
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/trailers"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Прицепы
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/tariffs"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Тарифы
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/cars"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Транспортные средства
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                to="/dashboard/addresses"
                className="sidebar-link"
                activeClassName="active"
              >
                <i className="fas"></i> Адреса
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
