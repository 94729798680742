import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditUser: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>()

  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('')
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')
  const [locked, setLocked] = useState(false)

  useEffect(() => {
    const loadUser = async () => {
      setDisabled(true)

      try {
        const user = await Axios.get<{
          email: string
          surname: string
          name: string
          patronymic: string
          locked: boolean
        }>(`${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`)

        setEmail(user.data.email)
        setSurname(user.data.surname)
        setName(user.data.name)
        setPatronymic(user.data.patronymic)
        setLocked(user.data.locked)
      } finally {
        setDisabled(false)
      }
    }

    loadUser()
  }, [userId])

  const editUser = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
        {
          email,
          surname,
          name,
          patronymic,
          locked,
        }
      )

      setDisabled(false)

      toast.success('Пользователь изменён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={(_) => editUser(_)}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(_) => setEmail(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Фамилия</label>
              <input
                type="text"
                className="form-control"
                id="surname"
                value={surname}
                onChange={(_) => setSurname(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Имя</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(_) => setName(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Отчество</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={patronymic}
                onChange={(_) => setPatronymic(_.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={locked}
                  onChange={(_) => setLocked(!locked)}
                  id="locked"
                />
                <label className="form-check-label" htmlFor="locked">
                  Заблокирован
                </label>
              </div>
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
