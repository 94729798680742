import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { threeMonthsAgo } from '../threeMonthsAgo'

export const AddTariffTrip: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)

  const [fromAddressAnyContractor, setFromAddressAnyContractor] = useState(true)
  const [fromContractorsIds, setFromContractorsIds] = useState(
    new Array<string>()
  )
  const [fromAddressId, setFromAddressId] = useState<string>()
  const [fromAddressName, setFromAddressName] = useState<string>()

  const [toAddressAnyContractor, setToAddressAnyContractor] = useState(true)
  const [toContractorsIds, setToContractorsIds] = useState(new Array<string>())
  const [toAddressId, setToAddressId] = useState<string>()
  const [toAddressName, setToAddressName] = useState<string>()

  const [driverId, setDriverId] = useState<string>()
  const [driverName, setDriverName] = useState<string>()

  const [value, setValue] = useState(0)

  const [updateFrom, setUpdateFrom] = useState<string>()

  const [tariffsTripsFrom, setTariffsTripsFrom] = useState<
    { id: string; name: string }[]
  >()

  const [tariffsTripsTo, setTariffsTripsTo] = useState<
    { id: string; name: string }[]
  >()

  const [tripsUpdateWarning, setTripsUpdateWarning] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const loadTariffsTripsFrom = async () => {
      setDisabled(true)

      try {
        if (fromAddressId) {
          const tariffsTripsFrom = await Axios.get<{
            items: {
              id: string
              name: string
            }[]
          }>(
            `${process.env.REACT_APP_API_URL}/api/v1/contractors/addresses/${fromAddressId}`
          )

          setTariffsTripsFrom(tariffsTripsFrom.data.items)
        }
      } finally {
        setDisabled(false)
      }
    }

    loadTariffsTripsFrom()
  }, [fromAddressId])

  useEffect(() => {
    const loadTariffsTripsTo = async () => {
      setDisabled(true)

      try {
        if (toAddressId) {
          const tariffsTripsTo = await Axios.get<{
            items: {
              id: string
              name: string
            }[]
          }>(
            `${process.env.REACT_APP_API_URL}/api/v1/contractors/addresses/${toAddressId}`
          )

          setTariffsTripsTo(tariffsTripsTo.data.items)
        }
      } finally {
        setDisabled(false)
      }
    }

    loadTariffsTripsTo()
  }, [toAddressId])

  const addTariffTrip = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if (!fromAddressId && !toAddressId && !driverId) {
        alert('Выберите Пункт А и/или Пункт Б и/или Водителя.')
        return
      }

      const tariffTrip = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/tariffs-trips`,
        {
          fromAddressId,
          fromAddressAnyContractor,
          fromContractorsIds: fromAddressAnyContractor
            ? []
            : fromContractorsIds,
          toAddressId,
          toAddressAnyContractor,
          toContractorsIds: toAddressAnyContractor ? [] : toContractorsIds,
          driverId,
          value,
          updateFrom,
        }
      )

      toast.success('Тариф поездки добавлен.')

      history.push(`/dashboard/edit-tariff-trip/${tariffTrip.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addTariffTrip}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Пункт А</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                value={{
                  value: fromAddressId,
                  label: fromAddressName,
                }}
                loadOptions={(inputValue: string, callback: any) => {
                  const loadAddresses = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const addresses = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        value: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/addresses?query=${inputValue}&page=1`
                    )

                    callback(
                      addresses.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.name,
                        }
                      })
                    )
                  }

                  loadAddresses(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setFromAddressName(_.label)
                    setFromAddressId(_.value)
                    return
                  }

                  setFromAddressName(undefined)
                  setFromAddressId(undefined)
                }}
              />
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="fromAddressAnyContractor"
                checked={fromAddressAnyContractor}
                onChange={(_) => {
                  const newValue = !fromAddressAnyContractor

                  setFromAddressAnyContractor(newValue)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="fromAddressAnyContractor"
              >
                Все контрагенты по этому адресу
              </label>
            </div>

            <div className="form-group" hidden={fromAddressAnyContractor}>
              {tariffsTripsFrom?.map((_, index) => (
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`tariffsTripsFrom_${index}`}
                    checked={fromContractorsIds?.indexOf(_.id) !== -1}
                    onChange={(__) => {
                      const index = fromContractorsIds?.indexOf(_.id) ?? -1

                      if (index !== -1) {
                        fromContractorsIds?.splice(index, 1)
                      } else {
                        fromContractorsIds?.push(_.id)
                      }

                      setFromContractorsIds([...fromContractorsIds!])

                      if (!fromContractorsIds?.length) {
                        setFromAddressAnyContractor(true)
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`tariffsTripsFrom_${index}`}
                  >
                    {_.name}
                  </label>
                </div>
              ))}
            </div>

            <div className="form-group">
              <label>Пункт Б</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                value={{
                  value: toAddressId,
                  label: toAddressName,
                }}
                loadOptions={(inputValue: string, callback: any) => {
                  const loadAddresses = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const addresses = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        value: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/addresses?query=${inputValue}&page=1`
                    )

                    callback(
                      addresses.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.name,
                        }
                      })
                    )
                  }

                  loadAddresses(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setToAddressName(_.label)
                    setToAddressId(_.value)
                    return
                  }

                  setToAddressName(undefined)
                  setToAddressId(undefined)
                }}
              />
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="toAddressAnyContractor"
                checked={toAddressAnyContractor}
                onChange={(_) => {
                  const newValue = !toAddressAnyContractor

                  setToAddressAnyContractor(newValue)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="toAddressAnyContractor"
              >
                Все контрагенты по этому адресу
              </label>
            </div>

            <div className="form-group" hidden={toAddressAnyContractor}>
              {tariffsTripsTo?.map((_, index) => (
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`tariffsTripsTo_${index}`}
                    checked={toContractorsIds?.indexOf(_.id) !== -1}
                    onChange={(__) => {
                      const index = toContractorsIds?.indexOf(_.id) ?? -1

                      if (index !== -1) {
                        toContractorsIds?.splice(index, 1)
                      } else {
                        toContractorsIds?.push(_.id)
                      }

                      setToContractorsIds([...toContractorsIds!])

                      if (!toContractorsIds?.length) {
                        setToAddressAnyContractor(true)
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`tariffsTripsTo_${index}`}
                  >
                    {_.name}
                  </label>
                </div>
              ))}
            </div>

            <div className="form-group">
              <label>Водитель</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                value={{
                  value: driverId,
                  label: driverName,
                }}
                loadOptions={(inputValue: string, callback: any) => {
                  const loadDrivers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const drivers = await Axios.get<{
                      items: {
                        id: string
                        surname: string
                        name: string
                        patronymic: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/drivers?query=${inputValue}&page=1`
                    )

                    callback(
                      drivers.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.surname} ${_.name[0]}.${
                            _.patronymic ? ' ' + _.patronymic[0] + '.' : ''
                          }`,
                        }
                      })
                    )
                  }

                  loadDrivers(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setDriverId(_ ? _.value : undefined)
                  setDriverName(_ ? _.label : undefined)
                }}
              />
            </div>

            <div className="form-group">
              <label>Тариф</label>
              <input
                className="form-control"
                type="number"
                step={0.01}
                min={0.01}
                value={value}
                required
                onChange={(_) => {
                  _ ? setValue(parseFloat(_.target.value)) : setValue(0)

                  if (updateFrom) {
                    setTripsUpdateWarning(true)
                  }
                }}
              />
            </div>

            <div className="form-group">
              <label>Тариф актуален с (необязательно)</label>
              <input
                className="form-control"
                type="date"
                value={updateFrom}
                min={threeMonthsAgo()}
                onChange={(_) => {
                  setUpdateFrom(_.target.value)
                  setTripsUpdateWarning(true)
                }}
              />
            </div>

            {tripsUpdateWarning ? (
              <span className="text-danger">
                Внимание! Тариф будет пересчитан во всех уже совершенных
                заказах.
              </span>
            ) : null}

            <div className="form-group">
              <button type="submit" className="btn btn-success">
                Сохранить
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
