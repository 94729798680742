import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { Table } from '../table/table'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { downloadFile } from '../../../infrastructure/document/downloadFile'
import { Modal } from 'react-bootstrap'

export const PayrollsDocuments = () => {
  const [disabled, setDisabled] = useState(false)

  const [year, setYear] = useState<number>()
  const [years, setYears] = useState<number[]>()

  const [month, setMonth] = useState<number>()
  const [months, setMonths] = useState<number[]>()

  useEffect(() => {
    const loadYears = async () => {
      const years = await Axios.get<number[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/payrolls/years`
      )

      setYears(years.data)
      setYear(years.data[years.data.length - 1])
    }

    loadYears()
  }, [])

  useEffect(() => {
    const loadMonths = async () => {
      if (!year) {
        return
      }

      const months = await Axios.get<number[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/payrolls/months/${year}`
      )

      setMonths(months.data)
      setMonth(months.data[months.data.length - 1])
    }

    loadMonths()
  }, [year])

  const tableRef = useRef<{ refresh(): void }>(null)

  const [displayExportWindow, setDisplayExportWindow] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      tableRef.current?.refresh()
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  const exportPayrolls = async (_: FormEvent) => {
    _.preventDefault()

    try {
      setDisabled(true)

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/exports-documents/payrolls`,
        { month, year }
      )

      tableRef.current?.refresh()

      toast.success('Экспорт добавлен.')
    } finally {
      setDisabled(false)
      setDisplayExportWindow(false)
    }
  }

  return (
    <>
      <Table
        ref={tableRef}
        apiUrl="/api/v1/exports-documents/payrolls"
        defaultSorting={{ name: 'creationDateTime', direction: 'desc' }}
        items={[
          {
            name: 'creationDateTime',
            title: 'Дата создания',
            type: 'datetime-timezone',
          },
          {
            name: 'name',
            title: 'Название',
            type: 'string',
          },
          {
            name: 'exportStatus',
            title: 'Статус',
            type: 'enum',
            enum: ['Создано', 'Выполняется', 'Завершено', 'Не удалось'],
          },
        ]}
        tableActions={[
          {
            title: 'Экспортировать',
            icon: 'far fa-file-excel',
            onClick: async () => {
              setDisplayExportWindow(true)
            },
          },
        ]}
        actions={[
          {
            title: 'Скачать',
            icon: 'fas fa-download',
            hidden: (_) => _.exportStatus !== 2,
            onClick: async (_: { id: string; name: string }) => {
              const response = await Axios.get(
                process.env.REACT_APP_API_URL +
                  '/api/v1/exports-documents/files/' +
                  _.id,
                {
                  responseType: 'blob',
                }
              )

              downloadFile(response)
            },
          },
        ]}
      />
      <Modal
        show={displayExportWindow}
        onHide={() => setDisplayExportWindow(!displayExportWindow)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Настройки экспорта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={exportPayrolls}>
            <fieldset disabled={disabled}>
              <div className="form-group">
                <label>Год</label>
                <select
                  className="form-control"
                  onChange={(_) => setYear(parseInt(_.target.value))}
                >
                  {years?.map((_) => (
                    <option selected={_ === year}>{_}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Месяц</label>
                <select
                  className="form-control"
                  onChange={(_) => setMonth(parseInt(_.target.value))}
                >
                  {months?.map((_) => (
                    <option selected={_ === month}>{_}</option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn btn-success">
                Экспортировать
              </button>
            </fieldset>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
