import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../footer/footer'

export default class NotFound extends Component {
  render() {
    return (
      <>
        <div className="container-fluid app">
          <div className="row">
            <div className="col-lg-12">
              <h1>404</h1>
              <h2>Страница не найдена</h2>
              <p>
                <Link to="/">На главную</Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
