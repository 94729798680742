import React, { FunctionComponent } from 'react'
import { Table } from '../../table/table'
import { useParams } from 'react-router-dom'

export const TariffRentsHistory: FunctionComponent = () => {
  const { tariffRentId } = useParams<{ tariffRentId: string }>()

  return (
    <Table
      apiUrl={`/api/v1/tariffs-rents-history/${tariffRentId}`}
      defaultSorting={{ name: 'updateFrom', direction: 'desc' }}
      items={[
        { title: 'Актуален с', name: 'updateFrom', type: 'datetime' },
        { title: 'Дата создания', name: 'creationDateTime', type: 'datetime' },
        {
          title: 'Минимальное количество часов',
          name: 'minHours',
          type: 'string',
        },
        {
          title: 'Максимальное количество часов',
          name: 'maxHours',
          type: 'string',
        },
        { title: 'Контрагент', name: 'contractorName', type: 'string' },
        { title: 'Тариф', name: 'value', type: 'string' },
      ]}
    />
  )
}
