import Axios from 'axios'
import React, { useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { Table } from '../table/table'
import { TripsStatistics } from './tripsStatistics'

export const Trips: FunctionComponent = () => {
  const [displayColumnsSetup, setDisplayColumnsSetup] = useState(false)

  const [columns, setColumns] = useState(
    [
      {
        name: 'dateTimeTo',
        title: 'Дата',
        type: 'datetime-timefilter',
      },
      {
        name: 'carNumber',
        title: 'Автомобиль, номер',
        type: 'multiselect',
        multiselectUrl: 'cars/autocomplete',
      },
      {
        name: 'driverShortName',
        title: 'Водитель, ФИО',
        type: 'multiselect',
        multiselectUrl: 'drivers/autocomplete',
      },
      {
        name: 'type',
        title: 'Тип поездки',
        type: 'enum',
        enum: ['Поставка', 'Перевозка', 'Аренда'],
      },
      {
        name: 'marked',
        title: 'Отм.',
        type: 'boolean',
      },
      {
        name: 'invoiceNumber',
        title: 'Документ',
        type: 'multiselect',
        multiselectUrl: 'trips/documents/invoices/autocomplete',
      },
      {
        name: 'waybillNumber',
        title: 'Номер путевого листа',
        type: 'multiselect',
        multiselectUrl: 'trips/documents/waybills/autocomplete',
      },
      {
        name: 'contractorObjectNameFrom',
        title: 'Пункт погрузки',
        type: 'multiselect',
        multiselectUrl: 'contractors-objects/autocomplete',
      },
      {
        name: 'volumeFrom',
        title: 'Приняли',
        type: 'number',
      },
      {
        name: 'volumeTypeFrom',
        title: 'Ед. изм.',
        type: 'enum',
        enum: ['Тонны', 'М³'],
      },
      {
        name: 'materialName',
        title: 'Материал',
        type: 'multiselect',
        multiselectUrl: 'materials/autocomplete',
      },
      {
        name: 'volumeTo',
        title: 'Сдали',
        type: 'number',
      },
      {
        name: 'volumeTypeTo',
        title: 'Ед. изм.',
        type: 'enum',
        enum: ['Тонны', 'М³'],
      },
      {
        name: 'price',
        title: 'Цена',
        type: 'number',
      },
      {
        name: 'profit',
        title: 'Сумма выручки',
        type: 'number',
      },
      {
        name: 'contractorObjectNameTo',
        title: 'Пункт разгрузки',
        type: 'multiselect',
        multiselectUrl: 'contractors-objects/autocomplete',
      },
      {
        name: 'contractorName',
        title: 'Контрагент',
        type: 'multiselect',
        multiselectUrl: 'contractors/autocomplete',
      },
      {
        name: 'payerName',
        title: 'Плательщик, название',
        type: 'multiselect',
        multiselectUrl: 'payers/autocomplete',
      },
      {
        name: 'payerInn',
        title: 'Плательщик, ИНН',
        type: 'multiselect',
        multiselectUrl: 'payers/inn/autocomplete',
      },
      {
        name: 'idleHours',
        title: 'Простой',
        type: 'number',
      },
      {
        name: 'workHours',
        title: 'Часы работы',
        type: 'number',
      },
      {
        name: 'payment',
        title: 'Оплата поездки',
        type: 'multiselect',
        multiselectUrl: 'trips/payments/autocomplete',
      },
      {
        name: 'companyName',
        title: 'Организация',
        type: 'multiselect',
        multiselectUrl: 'companies/autocomplete',
      },
      {
        name: 'ticketNumber',
        title: 'Номер талона',
        type: 'string',
      },
      {
        name: 'comment',
        title: 'Комментарий',
        type: 'string',
      },
    ].map((_: any) => {
      _.hidden =
        localStorage.getItem(`trips_column_${_.name}_hidden`) === 'true'

      return _
    }) as {
      title: string
      name: string
      type: 'string' | 'boolean' | 'datetime' | 'enum' | 'date'
      enum?: string[]
      hidden?: boolean
    }[]
  )

  const statisticsRef = useRef<{
    refresh(): void
    updateFilters(_: { name: string; value: string }[]): void
  }>(null)

  const tableRef = useRef<{
    resetFilters(): void
  }>(null)

  return (
    <>
      <TripsStatistics ref={statisticsRef} />
      <Table
        ref={tableRef}
        heightSm={true}
        apiUrl="/api/v1/trips"
        addUrl="/dashboard/add-trip"
        defaultSorting={{ name: 'dateTimeTo', direction: 'desc' }}
        items={columns}
        hideSearch={true}
        displayFilters={true}
        onFiltersUpdate={(_) => {
          statisticsRef.current?.updateFilters(_)
        }}
        actions={[
          {
            title: 'Изменить',
            icon: 'far fa-edit',
            url: '/dashboard/edit-trip',
          },
          {
            title: 'Удалить',
            icon: 'fas fa-trash-alt',
            onClick: async (_: { id: string }) => {
              await Axios.delete(
                `${process.env.REACT_APP_API_URL}/api/v1/trips/${_.id}`
              )

              statisticsRef.current?.refresh()
            },
          },
        ]}
        tableActions={[
          {
            title: 'Настроить столбцы',
            icon: 'fas fa-columns',
            onClick: () => {
              setDisplayColumnsSetup(true)
            },
          },
          {
            title: 'Импорты',
            icon: 'far fa-file-excel',
            url: '/dashboard/trips-imports',
          },
          {
            title: 'Экспорты',
            icon: 'far fa-file-excel',
            url: '/dashboard/trips-documents',
          },
          {
            title: 'Сбросить фильтры',
            icon: 'fas fa-times',
            onClick: () => {
              tableRef.current?.resetFilters()
            },
          },
        ]}
      />
      <Modal
        show={displayColumnsSetup}
        onHide={() => setDisplayColumnsSetup(!displayColumnsSetup)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Отображаемые столбцы</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {columns.map((_) => (
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={_.name}
                    defaultChecked={!_.hidden}
                    onChange={() => {
                      if (
                        localStorage.getItem(
                          `trips_column_${_.name}_hidden`
                        ) === 'true'
                      ) {
                        localStorage.removeItem(`trips_column_${_.name}_hidden`)
                        _.hidden = false

                        setColumns(columns)

                        return
                      }

                      localStorage.setItem(
                        `trips_column_${_.name}_hidden`,
                        'true'
                      )

                      _.hidden = true

                      setColumns(columns)
                    }}
                  />
                  <label className="form-check-label" htmlFor={_.name}>
                    {_.title}
                  </label>
                </div>
              </div>
            ))}
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
