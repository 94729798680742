import Axios from 'axios'
import React from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const AddCar: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [number, setNumber] = useState('')
  const [type, setType] = useState(0)
  const [axleType, setAxleType] = useState(0)
  const [brand, setBrand] = useState('')
  const [year, setYear] = useState(0)
  const [color, setColor] = useState('')
  const [volume, setVolume] = useState(0)
  const [companyId, setCompanyId] = useState('')
  const [image, setImage] = useState<Blob>()
  const [trailerId, setTrailerId] = useState<string>()

  const history = useHistory()

  const addCar = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      formData.append('number', number)
      formData.append('type', type.toString())
      formData.append('axleType', axleType !== null ? axleType.toString() : '0')
      formData.append('volume', volume ? volume.toString() : '')
      formData.append('brand', brand)
      formData.append('year', year.toString())
      formData.append('color', color)
      formData.append('companyId', companyId)
      formData.append('image', image || '')
      formData.append('trailerId', trailerId || '')

      const car = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/cars`,
        formData
      )

      toast.success('Транспортное средство добавлено.')

      history.push(`/dashboard/edit-car/${car.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addCar}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Номер</label>
              <input
                className="form-control"
                required
                type="text"
                value={number}
                onChange={(_) => setNumber(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Вид ТС</label>
              <select
                className="form-control"
                value={type}
                onChange={(_) => setType(parseInt(_.target.value))}
              >
                <option value={0}>Самосвал</option>
                <option value={1}>Тягач</option>
              </select>
            </div>
            {type === 0 ? (
              <div className="form-group">
                <label>Количество осей</label>
                <select
                  className="form-control"
                  value={axleType}
                  onChange={(_) => setAxleType(parseInt(_.target.value))}
                >
                  <option value={0}>3 осная</option>
                  <option value={1}>4 осная</option>
                  <option value={2}>5 осная</option>
                </select>
              </div>
            ) : null}
            <div className="form-group">
              <label>Марка</label>
              <input
                className="form-control"
                required
                type="string"
                value={brand}
                onChange={(_) => setBrand(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Год выпуска</label>
              <input
                className="form-control"
                required
                type="number"
                value={year}
                min={1}
                onChange={(_) => setYear(parseInt(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Цвет</label>
              <input
                className="form-control"
                required
                type="string"
                value={color}
                onChange={(_) => setColor(_.target.value)}
              />
            </div>
            {type === 0 ? (
              <div className="form-group">
                <label>Объём кузова</label>
                <input
                  className="form-control"
                  required
                  type="number"
                  value={volume}
                  min={0.01}
                  step={0.01}
                  onChange={(_) => setVolume(parseFloat(_.target.value))}
                />
              </div>
            ) : null}
            <div className="form-group">
              <label>Организация собственник</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={(inputValue, callback) => {
                  const loadCompanies = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const companies = await Axios.get<{
                      items: { id: string; name: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1`
                    )

                    callback(
                      companies.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.name,
                        }
                      })
                    )
                  }

                  loadCompanies(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setCompanyId(_.value)
                }}
              />
              <input
                type="text"
                value={companyId ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            {type === 1 ? (
              <div className="form-group">
                <label>Прицеп</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue, callback) => {
                    const loadContractors = async (
                      inputValue: string,
                      callback: any
                    ) => {
                      const cars = await Axios.get<{
                        items: { id: string; number: string }[]
                      }>(
                        `${process.env.REACT_APP_API_URL}/api/v1/trailers?query=${inputValue}&page=1`
                      )

                      callback(
                        cars.data.items.map((_) => {
                          return {
                            value: _.id,
                            label: _.number,
                          }
                        })
                      )
                    }

                    loadContractors(inputValue, callback)
                  }}
                  onChange={(_: any) =>
                    _ ? setTrailerId(_.value) : setTrailerId(undefined)
                  }
                />
              </div>
            ) : null}
            <div className="form-group">
              <label>Фотография</label>
              <input
                className="form-control"
                type="file"
                onChange={(_) => {
                  if (!_.target.files) {
                    return
                  }

                  if (
                    _.target.files[0].size >
                    process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                  ) {
                    alert(
                      `Выберите файл размером меньше ${
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000 / 1000
                      }МБ.`
                    )

                    return
                  }

                  setImage(_.target.files[0])
                }}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
