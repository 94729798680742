import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../../table/table'
import { useParams } from 'react-router-dom'

export const TariffTripsHistory: FunctionComponent = () => {
  const { tariffTripId } = useParams<{ tariffTripId: string }>()

  return (
    <Table
      apiUrl={`/api/v1/tariff-trips-history/${tariffTripId}`}
      hideSearch={true}
      defaultSorting={{ name: 'updateFrom', direction: 'desc' }}
      items={[
        { title: 'Актуален с', name: 'updateFrom', type: 'datetime' },
        { title: 'Дата создания', name: 'creationDateTime', type: 'datetime' },
        { title: 'Пункт А', name: 'fromAddressName', type: 'string' },
        { title: 'Пункт Б', name: 'toAddressName', type: 'string' },
        { title: 'Водитель, фамилия', name: 'driverSurname', type: 'string' },
        { title: 'Водитель, имя', name: 'driverName', type: 'string' },
        {
          title: 'Водитель, отчество',
          name: 'driverPatronymic',
          type: 'string',
        },
        { title: 'Тариф', name: 'value', type: 'string' },
      ]}
    />
  )
}
