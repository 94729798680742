export const threeMonthsAgo = () => {
  const date = new Date(new Date().setMonth(new Date().getMonth() - 3))

  return (
    date.getFullYear().toString() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate()
  )
}
