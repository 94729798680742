import Axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { Table } from '../table/table'
import { toast } from 'react-toastify'

export const TripsImports = () => {
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const tableRef = useRef<{ refresh(): void }>(null)

  const uploadFile = () => {
    fileUploadRef.current?.click()
  }

  const onFileChanged = async () => {
    if (
      fileUploadRef === null ||
      fileUploadRef.current === null ||
      fileUploadRef.current.files === null ||
      fileUploadRef.current.files[0] === null
    ) {
      return
    }

    try {
      const formData = new FormData()

      formData.append('file', fileUploadRef.current.files[0] as Blob)

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/trips-imports`,
        formData
      )

      tableRef.current?.refresh()

      toast.success('Импорт добавлен в очередь.')
    } finally {
      fileUploadRef.current.value = ''
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      tableRef.current?.refresh()
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <input
        id="myInput"
        type="file"
        ref={fileUploadRef}
        style={{ display: 'none' }}
        onChange={onFileChanged.bind(this)}
      />

      <Table
        apiUrl="/api/v1/trips-imports"
        defaultSorting={{ name: 'creationDateTime', direction: 'desc' }}
        hideSearch={true}
        ref={tableRef}
        tableActions={[
          {
            title: 'Импортировать',
            icon: 'far fa-file-excel',
            onClick: async () => {
              uploadFile()
            },
          },
        ]}
        items={[
          {
            title: 'Дата создания',
            name: 'creationDateTime',
            type: 'datetime',
          },
          {
            title: 'Статус',
            name: 'status',
            type: 'enum',
            enum: ['Создано', 'Выполняется', 'Завершено', 'Не удалось'],
          },
          {
            title: 'Создано',
            name: 'created',
            type: 'number',
          },
          {
            title: 'Обновлено',
            name: 'updated',
            type: 'number',
          },
        ]}
      />
    </>
  )
}
