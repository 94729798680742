import Axios from 'axios'
import React, { FormEvent, useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { toast } from 'react-toastify'
import { downloadFile } from '../../../infrastructure/document/downloadFile'
import { Table } from '../table/table'

export const TripDocuments: FunctionComponent<{ tripId: string }> = ({
  tripId,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [type, setType] = useState('')
  const [document, setDocument] = useState<Blob>()

  const tableRef = useRef<{ refresh(): void }>(null)

  const addDocument = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      formData.append('type', type)
      formData.append('document', document || '')

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/trips-documents/${tripId}`,
        formData
      )

      tableRef.current?.refresh()

      setType('')
      setDocument(undefined)

      toast.success('Документ добавлен.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <form onSubmit={addDocument}>
            <fieldset disabled={disabled}>
              <div className="form-group">
                <label>Тип документа</label>
                <input
                  className="form-control"
                  required
                  type="text"
                  value={type}
                  onChange={(_) => setType(_.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Документ</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={(_) => {
                    if (!_.target.files) {
                      return
                    }

                    if (
                      _.target.files[0].size >
                      process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                    ) {
                      alert(
                        `Выберите файл размером меньше ${
                          process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000 / 1000
                        }МБ.`
                      )

                      return
                    }

                    setDocument(_.target.files[0])
                  }}
                />
                <input
                  type="text"
                  value={document && document.size > 0 ? '-' : ''}
                  style={{ height: 0, opacity: 0 }}
                  required
                />
              </div>
              <button type="submit" className="btn btn-success">
                <i className="fas fa-save"></i> Добавить
              </button>
            </fieldset>
          </form>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <Table
            ref={tableRef}
            apiUrl={`/api/v1/trips-documents/${tripId}`}
            defaultSorting={{ name: 'name', direction: 'asc' }}
            items={[
              { title: 'Тип документа', name: 'type', type: 'string' },
              { title: 'Название', name: 'name', type: 'string' },
              { title: 'Превью', name: 'path', type: 'image' },
            ]}
            actions={[
              {
                title: 'Скачать',
                icon: 'fas fa-download',
                onClick: async (_: { id: string; name: string }) => {
                  const response = await Axios.get(
                    process.env.REACT_APP_API_URL +
                      '/api/v1/trips-documents/files/' +
                      _.id,
                    {
                      responseType: 'blob',
                    }
                  )

                  downloadFile(response)
                },
              },
              {
                title: 'Удалить',
                icon: 'fas fa-trash-alt',
                onClick: async (_: { id: string }) => {
                  await Axios.delete(
                    `${process.env.REACT_APP_API_URL}/api/v1/trips-documents/${_.id}`
                  )
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
