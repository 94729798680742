import React from 'react'
import { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div className="text-center">
        <span className="text-muted">
          Версия: {process.env.REACT_APP_VERSION}.
        </span>
      </div>
    )
  }
}
