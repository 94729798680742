import Axios from 'axios'
import React from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

export const AddMaterial: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')

  const history = useHistory()

  const addMaterial = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const material = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/materials`,
        {
          name,
        }
      )

      toast.success('Материал добавлен.')

      history.push(`/dashboard/materials/${material.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addMaterial}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Название</label>
              <input
                className="form-control"
                required
                type="text"
                value={name}
                onChange={(_) => setName(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
