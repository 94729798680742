import Axios from 'axios'
import React, { useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const DeleteContractor: FunctionComponent = () => {
  const { contractorId } = useParams<{ contractorId: string }>()
  const history = useHistory()

  useEffect(() => {
    const deleteCar = async () => {
      await Axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/contractors/${contractorId}`
      )

      toast.success('Контрагент удалён.')

      history.push('/dashboard/contractors')
    }

    deleteCar()
  }, [contractorId, history])

  return (
    <div className="row">
      <div className="col">Удалить контрагента.</div>
    </div>
  )
}
