import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditRepair: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [carNumber, setCarNumber] = useState<string>()
  const [trailerNumber, setTrailerNumber] = useState<string>()
  const [description, setDescription] = useState('')
  const [beginDateTime, setBeginDateTime] = useState('')
  const [planningEndDateTime, setPlanningEndDateTime] = useState('')
  const [factEndDateTime, setFactEndDateTime] = useState('')
  const [delayComment, setDelayComment] = useState('')

  const { repairId } = useParams<{ repairId: string }>()

  useEffect(() => {
    const loadRepair = async () => {
      setDisabled(true)

      try {
        const repair = await Axios.get<{
          carNumber: string
          trailerNumber: string
          description: string
          beginDateTime: string
          planningEndDateTime: string
          factEndDateTime: string
          delayComment: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/repairs/${repairId}`)

        if (repair.data.carNumber) {
          setCarNumber(repair.data.carNumber)
        }

        if (repair.data.trailerNumber) {
          setTrailerNumber(repair.data.trailerNumber)
        }

        setDescription(repair.data.description)
        setDelayComment(repair.data.delayComment)
        setBeginDateTime(repair.data.beginDateTime.replace('T00:00:00', ''))
        setPlanningEndDateTime(
          repair.data.planningEndDateTime.replace('T00:00:00', '')
        )

        if (repair.data.factEndDateTime) {
          setFactEndDateTime(
            repair.data.factEndDateTime.replace('T00:00:00', '')
          )
        }
      } finally {
        setDisabled(false)
      }
    }

    loadRepair()
  }, [repairId])

  const editRepair = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/repairs/${repairId}`,
        {
          description,
          factEndDateTime: !factEndDateTime ? null : factEndDateTime,
          delayComment,
        }
      )

      toast.success('Ремонт обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editRepair}>
          <fieldset disabled={disabled}>
            {carNumber ? (
              <div className="form-group">
                <label>Транспортное средство</label>
                <input
                  type="text"
                  className="form-control"
                  value={carNumber}
                  disabled
                />
              </div>
            ) : null}
            {trailerNumber ? (
              <div className="form-group">
                <label>Прицеп</label>
                <input
                  type="text"
                  className="form-control"
                  value={trailerNumber}
                  disabled
                />
              </div>
            ) : null}
            <div className="form-group">
              <label>Описание</label>
              <textarea
                className="form-control"
                required
                rows={5}
                value={description}
                onChange={(_) => setDescription(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Начало ремонта</label>
              <input
                className="form-control"
                required
                readOnly={true}
                type="datetime-local"
                value={beginDateTime.toString().replace('+00:00', '')}
                onChange={(_) => {
                  if (
                    new Date(_.target.value) > new Date(planningEndDateTime)
                  ) {
                    alert(
                      'Начало ремонта не может быть позже планируемого завершения.'
                    )
                    _.preventDefault()
                    return
                  }

                  setBeginDateTime(_.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Планируемое завершение</label>
              <input
                className="form-control"
                required
                disabled
                type="datetime-local"
                value={planningEndDateTime.toString().replace('+00:00', '')}
                onChange={(_) => {
                  if (new Date(_.target.value) < new Date(beginDateTime)) {
                    alert(
                      'Планируемое завершение не может быть раньше начала ремонта.'
                    )
                    _.preventDefault()
                    return
                  }

                  setPlanningEndDateTime(_.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Фактическое завершение</label>
              <input
                className="form-control"
                type="datetime-local"
                value={factEndDateTime.toString().replace('+00:00', '')}
                onChange={(_) => setFactEndDateTime(_.target.value)}
              />
            </div>
            {factEndDateTime > planningEndDateTime ? (
              <div className="form-group">
                <label>Комментарий по задержке</label>
                <textarea
                  className="form-control"
                  rows={5}
                  value={delayComment}
                  onChange={(_) => setDelayComment(_.target.value)}
                  required
                />
              </div>
            ) : null}
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
