import Axios from 'axios'
import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'
import MaskedInput from 'react-text-mask'

export const AddDriver: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [companyId, setCompanyId] = useState<string>()
  const [salary, setSalary] = useState<number>()
  const [carId, setCarId] = useState('')
  const [image, setImage] = useState<Blob>()
  const [birthday, setBirthday] = useState<string>()
  const [jobStartDate, setJobStartDate] = useState<string>()

  const history = useHistory()

  const addUser = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      formData.append('surname', surname)
      formData.append('name', name)
      formData.append('patronymic', patronymic)
      formData.append('phone', phone)
      formData.append('password', password)
      formData.append('salary', salary ? salary.toString() : '')
      formData.append('companyId', companyId || '')
      formData.append('image', image || '')
      formData.append('carId', carId || '')
      formData.append('birthday', birthday || '')
      formData.append('jobStartDate', jobStartDate || '')

      const result = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/drivers`,
        formData
      )

      toast.success('Водитель добавлен.')

      history.push(`/dashboard/edit-driver/${result.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addUser}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Фамилия</label>
              <input
                className="form-control"
                required
                type="text"
                value={surname}
                onChange={(_) => setSurname(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Имя</label>
              <input
                className="form-control"
                required
                type="text"
                value={name}
                onChange={(_) => setName(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Отчество</label>
              <input
                className="form-control"
                type="text"
                value={patronymic}
                onChange={(_) => setPatronymic(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>День рождения</label>
              <input
                className="form-control"
                type="date"
                value={birthday ? birthday.toString() : undefined}
                onChange={(_) => setBirthday(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Дата принятия на работу</label>
              <input
                className="form-control"
                type="date"
                value={jobStartDate ? jobStartDate?.toString() : undefined}
                onChange={(_) => setJobStartDate(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Телефон (логин для водителя)</label>
              <MaskedInput
                mask={[
                  '+',
                  '7',
                  ' ',
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                className="form-control"
                required
                value={phone}
                placeholder="+7 (999) 999-99-99"
                onChange={(_) => setPhone(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Пароль</label>
              <input
                className="form-control"
                required
                type="text"
                value={password}
                minLength={6}
                onChange={(_) => setPassword(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Организация</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; name: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.name,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setCompanyId(_.value)
                    return
                  }

                  setCompanyId(undefined)
                }}
              />
            </div>
            <div className="form-group">
              <label>Официальная зарплата</label>
              <input
                className="form-control"
                type="number"
                value={salary}
                step={0.01}
                onChange={(_) => setSalary(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Транспортное средство</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/cars?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.number,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setCarId(_.value)
                    return
                  }

                  setCarId('')
                }}
              />
            </div>
            <div className="form-group">
              <label>Фотография</label>
              <input
                className="form-control"
                type="file"
                onChange={(_) => {
                  if (!_.target.files) {
                    return
                  }

                  if (
                    _.target.files[0].size >
                    process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                  ) {
                    alert(
                      `Выберите файл размером меньше ${
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000 / 1000
                      }МБ.`
                    )

                    return
                  }

                  setImage(_.target.files[0])
                }}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
