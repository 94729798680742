import Axios from 'axios'
import React from 'react'
import { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'

export const Material = ({
  material,
  cost,
  volumeType,
  contractorObjectNames,
  setMaterial,
  setCost,
  setVolumeType,
  setContractorObjectNames,
  getContractorObjectNames,
  actualDateFrom,
  setActualDateFrom,
  setRecalculate,
}: {
  material: { id: string; name: string; archived: boolean }
  cost: number
  contractorObjectNames: { label: string; value: string }[]
  setMaterial: (value: { id: string; name: string; archived: boolean }) => void
  setCost: (value: number) => void
  setContractorObjectNames: (
    value: { label: string; value: string; addressId: string }[]
  ) => void
  getContractorObjectNames: (
    value: string
  ) => { label: string; value: string }[]
  volumeType: number
  setVolumeType: (value: number) => void
  actualDateFrom: string
  setActualDateFrom: (value: string) => void
  setRecalculate: (value: boolean) => void
}) => {
  const [currentMaterial, setCurrentMaterial] = useState(material)
  const [currentCost, setCurrentCost] = useState(cost)
  const [
    currentContractorObjectNames,
    setCurrentContractorObjectNames,
  ] = useState(contractorObjectNames)
  const [currrentVolumeType, setCurrentVolumeType] = useState(volumeType)
  const [currentActualDateFrom, setCurrentActualDateFrom] = useState(
    actualDateFrom?.split('T')[0]
  )

  return (
    <div className="form-row">
      <div className="form-group col-lg-4">
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={(inputValue, callback) => {
            const loadMaterials = async (inputValue: string, callback: any) => {
              const materials = await Axios.get<{
                items: {
                  id: string
                  name: string
                  archived: boolean
                }[]
              }>(
                `${process.env.REACT_APP_API_URL}/api/v1/materials?query=${inputValue}&page=1`
              )

              callback(
                materials.data.items.map((_) => {
                  return {
                    value: _.id,
                    label: _.name,
                    archived: _.archived,
                  }
                })
              )
            }

            loadMaterials(inputValue, callback)
          }}
          isOptionDisabled={(option) => {
            if (option.archived) {
              return true
            }

            return false
          }}
          onChange={(_: any) => {
            setCurrentMaterial({
              id: _.value,
              name: _.label,
              archived: _.archived,
            })
            setMaterial({
              id: _.value,
              name: _.label,
              archived: _.archived,
            })
          }}
          value={{
            value: currentMaterial.id,
            label: currentMaterial.name,
            archived: currentMaterial.archived,
          }}
          placeholder="Материал"
          required
        />
        <input
          type="text"
          value={material && material.id ? '-' : ''}
          style={{ height: 0, opacity: 0 }}
          required
        />
      </div>
      <div className="form-group col-lg-1">
        <input
          type="number"
          value={currentCost}
          placeholder="Стоимость"
          className="form-control"
          min={0}
          step={0.01}
          onChange={(_) => {
            setCurrentCost(parseFloat(_.target.value))
          }}
          onBlur={(_) => {
            setCost(currentCost)
            setRecalculate(true)
          }}
          required
        />
      </div>
      <div className="form-group col-lg-1">
        <select
          required
          className="form-control"
          value={currrentVolumeType}
          onChange={(_) => {
            setCurrentVolumeType(parseInt(_.target.value))
            setVolumeType(parseInt(_.target.value))
          }}
        >
          <option value={0}>Тонны</option>
          <option value={1}>М³</option>
        </select>
      </div>
      <div className="form-group col-lg-4">
        <Select
          options={getContractorObjectNames('')}
          onChange={(_: any) => {
            setCurrentContractorObjectNames(_)
            setContractorObjectNames(_)
          }}
          isMulti
          placeholder="Адрес"
          value={currentContractorObjectNames}
          required
        />
        <input
          type="text"
          value={currentContractorObjectNames?.length === 0 ? '' : '-'}
          style={{ height: 0, opacity: 0 }}
          required
        />
      </div>
      <div className="form-group col-lg-2">
        <div className="input-group">
          <input
            className="form-control"
            type="date"
            value={currentActualDateFrom}
            onChange={(_) => {
              setCurrentActualDateFrom(_.target.value)
              setActualDateFrom(_.target.value)
              setRecalculate(true)
            }}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => {
              setCurrentActualDateFrom('')
            }}
          >
            X
          </button>
        </div>
      </div>
    </div>
  )
}
