import Axios from 'axios'
import React from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Material } from './materials/material'
import AsyncSelect from 'react-select/async'

export const AddContractor: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')
  const [inn, setInn] = useState('')
  const [type, setType] = useState(0)
  const [contractorObjects, setContractorObjects] = useState<
    { name: string; address: string; addressId: string; id: string }[]
  >([])
  const [materials, setMaterials] = useState<
    {
      material: {
        id: string
        name: string
        archived: boolean
      }
      cost: number
      volumeType: number
      actualDateFrom: string
      contractorObjectNames: {
        label: string
        value: string
        addressId: string
      }[]
    }[]
  >([])
  const [typeThreeRentPerHour, setTypeThreeRentPerHourt] = useState(0)
  const [typeFourRentPerHour, setTypeFourRentPerHourt] = useState(0)
  const [typeFiveRentPerHour, setTypeFiveRentPerHourt] = useState(0)
  const [payerId, setPayerId] = useState<string>()

  const history = useHistory()

  const addContractor = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const contractor = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/contractors`,
        {
          name,
          inn,
          type,
          objects: contractorObjects,
          materials: materials.map((_) => {
            return {
              materialId: _.material.id,
              cost: _.cost,
              volumeType: _.volumeType,
              contractorObjectsIds: _.contractorObjectNames.map(
                (_) => _.addressId
              ),
              actualDateFrom: _.actualDateFrom === '' ? null : _.actualDateFrom,
            }
          }),
          typeThreeRentPerHour,
          typeFourRentPerHour,
          typeFiveRentPerHour,
          payerId,
        }
      )

      toast.success('Контрагент добавлен.')

      history.push(`/dashboard/edit-contractor/${contractor.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addContractor}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Грузополучатель</label>
              <input
                className="form-control"
                required
                type="text"
                value={name}
                onChange={(_) => setName(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>ИНН</label>
              <input
                className="form-control"
                type="text"
                value={inn}
                onChange={(_) => setInn(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Плательщик</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                  const loadPayers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; name: string; inn: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/payers?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}-${_.inn}`,
                        }
                      })
                    )
                  }

                  loadPayers(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setPayerId(_.value)
                    return
                  }

                  setPayerId(undefined)
                }}
              />
            </div>
            <div className="form-group">
              <label>Тип</label>
              <select
                className="form-control"
                value={type}
                onChange={(_) => setType(parseInt(_.target.value))}
              >
                <option value={0}>Объект</option>
                <option value={1}>Карьер</option>
                <option value={2}>Полигон</option>
                <option value={3}>База</option>
              </select>
            </div>

            <div className="form-group">
              <label>Адреса</label>
            </div>
            {contractorObjects.map((_, index) => (
              <div className="form-row">
                <div className="form-group col-lg-11">
                  <AsyncSelect
                    key={`contractor_object_${new Date().toString()}`}
                    cacheOptions
                    defaultOptions
                    value={{
                      value: _.id,
                      label: _.name ? `${_.name} - ${_.address}` : '',
                    }}
                    loadOptions={(inputValue, callback) => {
                      const loadAddresses = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const addresses = await Axios.get<{
                          items: { id: string; name: string; value: string }[]
                        }>(
                          `${process.env.REACT_APP_API_URL}/api/v1/addresses?query=${inputValue}&page=1`
                        )

                        callback(
                          addresses.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name} - ${_.value}`,
                              name: _.name,
                              address: _.value,
                            }
                          })
                        )
                      }

                      loadAddresses(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      for (let i = 0; i < materials.length; i++) {
                        for (
                          let j = 0;
                          j < materials[i].contractorObjectNames.length;
                          j++
                        ) {
                          if (
                            materials[i].contractorObjectNames[j].addressId ===
                            contractorObjects[index].addressId
                          ) {
                            materials[i].contractorObjectNames[j].addressId =
                              _.value

                            materials[i].contractorObjectNames[j].label =
                              _.label
                          }
                        }
                      }

                      contractorObjects[index].id = _.value
                      contractorObjects[index].name = _.name
                      contractorObjects[index].address = _.address
                      contractorObjects[index].addressId = _.value

                      setContractorObjects([...contractorObjects])
                      setMaterials([...materials])
                    }}
                  />
                </div>
                <div className="form-group col-lg-1">
                  <button
                    key={`contractor_object_delete_button_${new Date().toString()}`}
                    type="button"
                    onClick={() => {
                      setContractorObjects(
                        contractorObjects.filter((_, i) => i !== index)
                      )
                    }}
                    className="btn btn-danger align-bottom"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button
                type="button"
                onClick={() =>
                  setContractorObjects([
                    ...contractorObjects,
                    { name: '', address: '', id: '', addressId: '' },
                  ])
                }
                className="btn btn-link"
              >
                Добавить адрес
              </button>
            </div>

            <div className="form-group">
              <label>Цены на материалы</label>
            </div>
            {materials.map((_, index) => (
              <div className="form-row">
                <div className="form-group col-lg-11">
                  <Material
                    key={`material_${new Date().toString()}`}
                    material={_.material}
                    cost={_.cost}
                    volumeType={_.volumeType}
                    contractorObjectNames={_.contractorObjectNames}
                    setMaterial={(_) => {
                      materials[index].material = _
                      setMaterials([...materials])
                    }}
                    setCost={(_) => {
                      materials[index].cost = _
                      setMaterials([...materials])
                    }}
                    setContractorObjectNames={(_) => {
                      materials[index].contractorObjectNames = _
                      setMaterials([...materials])
                    }}
                    setVolumeType={(_) => {
                      materials[index].volumeType = _
                      setMaterials([...materials])
                    }}
                    getContractorObjectNames={(_) => {
                      if (!_) {
                        return contractorObjects.map((contractorObject) => {
                          return {
                            label: `${contractorObject.name} - ${contractorObject.address}`,
                            value: `${contractorObject.name}${contractorObject.address}`,
                            addressId: contractorObject.addressId,
                          }
                        })
                      }

                      return contractorObjects
                        .filter(
                          (contractorObject) =>
                            contractorObject.name
                              .toLowerCase()
                              .indexOf(_.toLowerCase()) !== -1
                        )
                        .map((contractorObject) => {
                          return {
                            label: contractorObject.name,
                            value: contractorObject.name,
                          }
                        })
                    }}
                    actualDateFrom={_.actualDateFrom}
                    setActualDateFrom={(_) => {
                      materials[index].actualDateFrom = _
                      setMaterials([...materials])
                    }}
                    setRecalculate={(_) => {}}
                  />
                </div>
                <div className="form-group col-lg-1">
                  <button
                    key={`material_delete_button_${new Date().toString()}`}
                    type="button"
                    onClick={() => {
                      setMaterials(materials.filter((_, i) => i !== index))
                    }}
                    className="btn btn-danger align-bottom"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button
                type="button"
                onClick={() =>
                  setMaterials([
                    ...materials,
                    {
                      material: { id: '', name: '', archived: false },
                      cost: 0,
                      contractorObjectNames: [],
                      volumeType: 0,
                      actualDateFrom: '',
                    },
                  ])
                }
                className="btn btn-link"
              >
                Добавить материал
              </button>
            </div>

            <div className="form-group">
              <label>Цены за аренду за час</label>
            </div>
            <div className="form-group">
              <label>3 осная</label>
              <input
                type="number"
                min={0}
                step={0.01}
                required
                className="form-control"
                value={typeThreeRentPerHour}
                onChange={(_) =>
                  setTypeThreeRentPerHourt(parseFloat(_.target.value))
                }
              />
            </div>
            <div className="form-group">
              <label>4 осная</label>
              <input
                type="number"
                min={0}
                step={0.01}
                required
                className="form-control"
                value={typeFourRentPerHour}
                onChange={(_) =>
                  setTypeFourRentPerHourt(parseFloat(_.target.value))
                }
              />
            </div>
            <div className="form-group">
              <label>5 осная</label>
              <input
                type="number"
                min={0}
                step={0.01}
                required
                className="form-control"
                value={typeFiveRentPerHour}
                onChange={(_) =>
                  setTypeFiveRentPerHourt(parseFloat(_.target.value))
                }
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
