import Axios from 'axios'
import React from 'react'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DriverFio } from '../drivers/driverFio'

export const AddBonus: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState(0)
  const [comment, setComment] = useState('')

  const { driverId } = useParams<{ driverId: string }>()

  const history = useHistory()

  const addBonus = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const bonus = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/bonuses/${driverId}`,
        {
          date,
          amount,
          comment,
        }
      )

      toast.success('Надбавка добавлена.')

      history.push(`/dashboard/edit-bonus/${driverId}/${bonus.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addBonus}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>
                <DriverFio driverId={driverId} />
              </label>
            </div>
            <div className="form-group">
              <label>Дата</label>
              <input
                className="form-control"
                required
                type="date"
                value={date.toString()}
                onChange={(_) => setDate(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Сумма</label>
              <input
                className="form-control"
                required
                type="number"
                value={amount}
                min={0.01}
                step={0.01}
                onChange={(_) => setAmount(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Комментарий</label>
              <textarea
                className="form-control"
                rows={5}
                value={comment}
                onChange={(_) => setComment(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
