import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'
import MaskedInput from 'react-text-mask'
import { DefaultGuid } from '../../types/defaultGuid'

export const EditDriver: FunctionComponent<{ driverId: string }> = ({
  driverId,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')
  const [phone, setPhone] = useState('')
  const [locked, setLocked] = useState(false)
  const [carNumber, setCarNumber] = useState('')
  const [companyName, setCompanyName] = useState<string>()
  const [companyId, setCompanyId] = useState<string>()
  const [salary, setSalary] = useState<number>()
  const [carId, setCarId] = useState('')
  const [image, setImage] = useState<Blob>()
  const [imagePath, setImagePath] = useState('')
  const [birthday, setBirthday] = useState<string>()
  const [jobStartDate, setJobStartDate] = useState<string>()

  useEffect(() => {
    const loadDriver = async () => {
      setDisabled(true)

      try {
        const driver = await Axios.get<{
          phone: string
          surname: string
          name: string
          patronymic: string
          locked: boolean
          salary: number
          companyId: string
          companyName: string
          carNumber: string
          imagePath: string
          carId: string
          birthday: string
          jobStartDate: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/drivers/${driverId}`)

        setSurname(driver.data.surname)
        setName(driver.data.name)
        setPatronymic(driver.data.patronymic)
        setLocked(driver.data.locked)
        setPhone(driver.data.phone)
        setSalary(driver.data.salary)

        if (driver.data.companyId && driver.data.companyId !== DefaultGuid) {
          setCompanyId(driver.data.companyId)
          setCompanyName(driver.data.companyName)
        }

        setCarNumber(driver.data.carNumber)
        setCarId(driver.data.carId)
        setImagePath(driver.data.imagePath)

        if (driver.data.birthday) {
          setBirthday(driver.data.birthday.replace('T00:00:00+00:00', ''))
        }

        if (driver.data.jobStartDate) {
          setJobStartDate(
            driver.data.jobStartDate.replace('T00:00:00+00:00', '')
          )
        }
      } finally {
        setDisabled(false)
      }
    }

    loadDriver()
  }, [driverId])

  const editDriver = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      formData.append('surname', surname)
      formData.append('name', name)
      formData.append('patronymic', patronymic)
      formData.append('phone', phone)
      formData.append('locked', locked.toString())
      formData.append('salary', salary ? salary.toString() : '')
      formData.append('companyId', companyId || '')
      formData.append('image', image || '')
      formData.append('carId', carId || '')
      formData.append('birthday', birthday || '')
      formData.append('jobStartDate', jobStartDate || '')

      const result = await Axios.put<{ id: string; imagePath: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/drivers/${driverId}`,
        formData
      )

      setImagePath(result.data.imagePath)

      toast.success('Водитель обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editDriver}>
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col-lg">
                <div className="form-group">
                  <label>Фамилия</label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={surname}
                    onChange={(_) => setSurname(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Имя</label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={name}
                    onChange={(_) => setName(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Отчество</label>
                  <input
                    className="form-control"
                    type="text"
                    value={patronymic}
                    onChange={(_) => setPatronymic(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>День рождения</label>
                  <input
                    className="form-control"
                    type="date"
                    value={birthday ? birthday.toString() : undefined}
                    onChange={(_) => setBirthday(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Дата принятия на работу</label>
                  <input
                    className="form-control"
                    type="date"
                    value={jobStartDate ? jobStartDate.toString() : undefined}
                    onChange={(_) => setJobStartDate(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Телефон (логин для водителя)</label>
                  <MaskedInput
                    mask={[
                      '+',
                      '7',
                      ' ',
                      '(',
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                    className="form-control"
                    required
                    value={phone}
                    placeholder="+7 (999) 999-99-99"
                    onChange={(_) => setPhone(_.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Организация</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable={true}
                    value={{
                      value: companyId,
                      label: companyName,
                    }}
                    loadOptions={(inputValue, callback) => {
                      const loadCompanies = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        let url = `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1`

                        const companies = await Axios.get<{
                          items: {
                            id: string
                            name: string
                          }[]
                        }>(url)

                        callback(
                          companies.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.name,
                            }
                          })
                        )
                      }

                      loadCompanies(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setCompanyId(_.value)
                        setCompanyName(_.label)
                        return
                      }

                      setCompanyId('')
                      setCompanyName('')
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Официальная зарплата</label>
                  <input
                    className="form-control"
                    type="number"
                    value={salary}
                    step={0.01}
                    onChange={(_) => setSalary(parseFloat(_.target.value))}
                  />
                </div>
                <div className="form-group">
                  <label>Транспортное средство</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable={true}
                    value={{
                      value: carId,
                      label: carNumber,
                    }}
                    loadOptions={(inputValue, callback) => {
                      const loadCars = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        let url = `${process.env.REACT_APP_API_URL}/api/v1/cars?query=${inputValue}&page=1`

                        const cars = await Axios.get<{
                          items: {
                            id: string
                            number: string
                          }[]
                        }>(url)

                        callback(
                          cars.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.number,
                            }
                          })
                        )
                      }

                      loadCars(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setCarId(_.value)
                        setCarNumber(_.label)
                        return
                      }

                      setCarId('')
                      setCarNumber('')
                    }}
                  />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={locked}
                      onChange={(_) => setLocked(!locked)}
                      id="locked"
                    />
                    <label className="form-check-label" htmlFor="locked">
                      Заблокирован
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg">
                {imagePath ? (
                  <div className="form-group mt-3">
                    <img
                      src={imagePath}
                      alt="Фотография водителя"
                      className="form-image"
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label>Фотография</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setImage(_.target.files[0])
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <button type="submit" className="btn btn-success">
                  <i className="fas fa-save"></i> Сохранить
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
