import Axios from 'axios'
import React, { Component, FormEvent } from 'react'
import SigninState from './signinState'
import Token from './token'
import './signin.scss'
import Footer from '../footer/footer'

export default class Signin extends Component<any, SigninState> {
  constructor(props: any) {
    super(props)

    this.state = new SigninState()

    this.signin = this.signin.bind(this)
  }
  render() {
    return (
      <>
        <div className="container-fluid app signin">
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="col text-center">
                    <h1>Личный кабинет оператора</h1>
                  </div>

                  <form onSubmit={this.signin}>
                    <fieldset disabled={this.state.disabled}>
                      <div className="form-group">
                        <label className="text-white">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={this.state.email}
                          onChange={(_) =>
                            this.setState({ email: _.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-white">Пароль</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={this.state.password}
                          minLength={6}
                          onChange={(_) =>
                            this.setState({ password: _.target.value })
                          }
                          required
                        />
                      </div>
                      <input
                        type="submit"
                        className="btn btn-primary btn-block"
                        value="Войти"
                      />
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
  async signin(event: FormEvent) {
    event.preventDefault()

    try {
      this.setState({ disabled: true })

      const token = await Axios.post<Token>(
        `${process.env.REACT_APP_API_URL}/api/v1/tokens`,
        {
          email: this.state.email,
          password: this.state.password,
        }
      )

      localStorage.setItem('authenticated', 'true')
      localStorage.setItem('tokenJwt', token.data.jwt)
      localStorage.setItem('refreshTokenId', token.data.refreshTokenId)

      this.setState({ disabled: false })

      document.location.href = '/'
    } catch {
      this.setState({ disabled: false })
    }
  }
}
