import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Repairs: FunctionComponent = () => {
  return (
    <Table
      apiUrl="/api/v1/repairs"
      addUrl="/dashboard/add-repair"
      defaultSorting={{ name: 'number', direction: 'asc' }}
      items={[
        {
          title: 'Номер',
          name: 'number',
          type: 'string',
        },
        {
          title: 'Тип',
          name: 'carType',
          type: 'enum',
          enum: ['Самосвал', 'Тягач'],
        },
        { title: 'Описание', name: 'description', type: 'string' },
        { title: 'Начало ремонта', name: 'beginDateTime', type: 'datetime' },
        {
          title: 'Планируемое завершение',
          name: 'planningEndDateTime',
          type: 'datetime',
        },
        {
          title: 'Фактическое завершение',
          name: 'factEndDateTime',
          type: 'datetime',
        },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-repair',
        },
      ]}
    />
  )
}
