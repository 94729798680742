import React, { useEffect, useRef, useState } from 'react'
import { Table } from '../table/table'
import Axios from 'axios'
import NumberFormat from 'react-number-format'

export const Payrolls = () => {
  const [year, setYear] = useState<number>()
  const [years, setYears] = useState<number[]>()

  const [month, setMonth] = useState<number>()
  const [months, setMonths] = useState<number[]>()

  const [salary, setSalary] = useState(0)
  const [advances, setAdvances] = useState(0)
  const [gibddFines, setGibddFines] = useState(0)
  const [fines, setFines] = useState(0)
  const [total, setTotal] = useState(0)
  const [onCard, setOnCard] = useState(0)
  const [nonCard, setNonCard] = useState(0)

  const tableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
    allowUpdate(): void
    getQuery(): string
  }>(null)

  useEffect(() => {
    const loadYears = async () => {
      const years = await Axios.get<number[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/payrolls/years`
      )

      setYears(years.data)
      setYear(years.data[years.data.length - 1])
    }

    loadYears()
  }, [])

  useEffect(() => {
    const loadMonths = async () => {
      if (!year) {
        return
      }

      const months = await Axios.get<number[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/payrolls/months/${year}`
      )

      setMonths(months.data)
      setMonth(months.data[months.data.length - 1])
    }

    loadMonths()
  }, [year])

  useEffect(() => {
    if (year) {
      tableRef.current?.updateParams('year', year.toString())
    }

    if (month) {
      tableRef.current?.updateParams('month', month.toString())
    }

    if (year && month) {
      tableRef.current?.allowUpdate()
      tableRef.current?.refresh()

      const loadStatistics = async () => {
        const statistics = await Axios.get<{
          salary: number
          advances: number
          gibddFines: number
          fines: number
          total: number
          onCard: number
          nonCard: number
        }>(`${process.env.REACT_APP_API_URL}/api/v1/payrolls/statistics`, {
          params: {
            year,
            month,
            query: tableRef.current?.getQuery(),
          },
        })

        setSalary(statistics.data.salary)
        setAdvances(statistics.data.advances)
        setGibddFines(statistics.data.gibddFines)
        setFines(statistics.data.fines)
        setTotal(statistics.data.total)
        setOnCard(statistics.data.onCard)
        setNonCard(statistics.data.nonCard)
      }

      loadStatistics()
    }
  }, [year, month])

  return (
    <>
      <div className="row">
        <div className="col">
          Зарплаты:{' '}
          {salary ? (
            <span className="text-success">
              <NumberFormat
                value={salary}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          ) : (
            '-'
          )}
          .<br />
          Авансы:{' '}
          {advances ? (
            <span className="text-danger">
              <NumberFormat
                value={advances}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          ) : (
            '-'
          )}
          .
          <br />
          Штрафы ГИБДД:{' '}
          {gibddFines ? (
            <span className="text-danger">
              <NumberFormat
                value={gibddFines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          ) : (
            '-'
          )}
          .<br />
          Иные штрафы:{' '}
          {fines ? (
            <span className="text-danger">
              <NumberFormat
                value={fines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          ) : (
            '-'
          )}
          .
        </div>
        <div className="col">
          Итого к выплате:{' '}
          <NumberFormat
            value={total}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
          />
          . <br />
          На карту:{' '}
          <NumberFormat
            value={onCard}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
          />{' '}
          <br />
          На руки:{' '}
          <NumberFormat
            value={nonCard}
            displayType={'text'}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
          />
          .
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <form>
            <div className="row">
              <div className="form-group col-lg-2">
                <select
                  className="form-control"
                  placeholder="Год"
                  onChange={(_) => setYear(parseInt(_.target.value))}
                >
                  {years?.map((_) => (
                    <option selected={_ === year}>{_}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-lg-2">
                <select
                  className="form-control"
                  placeholder="Месяц"
                  onChange={(_) => setMonth(parseInt(_.target.value))}
                >
                  {months?.map((_) => (
                    <option selected={_ === month}>{_}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Table
        ref={tableRef}
        heightSm={true}
        apiUrl="/api/v1/payrolls"
        deferred={true}
        tableActions={[
          {
            title: 'Ведомости',
            icon: 'far fa-file-excel',
            url: '/dashboard/payrolls-documents-signature',
          },
          {
            title: 'Экспорты',
            icon: 'far fa-file-excel',
            url: '/dashboard/payrolls-documents',
          },
        ]}
        defaultSorting={{ name: 'surname', direction: 'asc' }}
        items={[
          {
            title: 'Водитель, фамилия',
            name: 'surname',
            type: 'string',
          },
          {
            title: 'Водитель, имя',
            name: 'name',
            type: 'string',
          },
          {
            title: 'Водитель, отчество',
            name: 'patronymic',
            type: 'string',
          },
          {
            title: 'Оплата за поездки',
            name: 'salary',
            type: 'number',
          },
          {
            title: 'Премии',
            name: 'bonuses',
            type: 'number',
          },
          {
            title: 'Простои',
            name: 'idles',
            type: 'number',
          },
          {
            title: 'Авансы',
            name: 'advances',
            type: 'number',
          },
          {
            title: 'Штрафы ГИБДД',
            name: 'gibddFines',
            type: 'number',
          },
          {
            title: 'Иные штрафы',
            name: 'fines',
            type: 'number',
          },
          {
            title: 'Итого к выплате',
            name: 'total',
            type: 'number',
          },
          {
            title: 'На карту',
            name: 'onCard',
            type: 'number',
          },
          {
            title: 'На руки',
            name: 'nonCard',
            type: 'number',
          },
        ]}
        actions={[
          {
            icon: 'fas fa-info-circle',
            title: 'Информация',
            url: `/dashboard/view-payroll/${year}/${month}`,
          },
        ]}
      />
    </>
  )
}
