import React from 'react'
import { Table } from '../../table/table'

export const Recalculations = () => {
  return (
    <Table
      apiUrl="/api/v1/recalculations"
      items={[
        {
          title: 'Контрагент, название',
          type: 'string',
          name: 'contractorName',
        },
        {
          title: 'Контрагент, ИНН',
          type: 'string',
          name: 'contractorInn',
        },
        {
          title: 'Дата начала',
          type: 'datetime',
          name: 'creationDateTime',
        },
        {
          title: 'Дата окончания',
          type: 'datetime',
          name: 'endDateTime',
        },
      ]}
      defaultSorting={{
        name: 'creationDateTime',
        direction: 'desc',
      }}
    />
  )
}
