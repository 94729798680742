import React, { Component } from 'react'

import './app.scss'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Initialize from './initialize/initialize'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'
import Signin from './signin/signin'
import AppState from './appState'
import NotFound from './notFound/notFound'
import ProtectedRoute from '../infrastructure/react-router-dom/protectedRoute'
import AnonymousRoute from '../infrastructure/react-router-dom/anonymousRoute'
import { Dashboard } from './dashboard/dashboard'

const LightTheme = React.lazy(
  () => import('./_styles/appstack/dist/css/lightTheme')
)
const DarkTheme = React.lazy(
  () => import('./_styles/appstack/dist/css/darkTheme')
)

export default class App extends Component<any, AppState> {
  constructor(props: any) {
    super(props)

    this.state = new AppState(
      localStorage.getItem('authenticated') === 'true',
      localStorage.getItem('initialized') === 'true'
    )
  }
  async componentDidMount() {
    if (this.state.initialized) {
      return
    }

    try {
      await Axios.get(`${process.env.REACT_APP_API_URL}/api/v1/initialize`)

      localStorage.setItem('initialized', 'true')
      this.setState({ initialized: true })

      return
    } catch (error: any) {
      if (error.response === undefined) {
        toast.error('Сервер недоступен.')

        return
      }

      if (document.location.pathname !== '/initialize') {
        document.location.href = '/initialize'
      }
    }
  }
  render() {
    return (
      <React.Suspense fallback={() => null}>
        {window.localStorage.getItem('theme') !== 'dark' && <LightTheme />}
        {window.localStorage.getItem('theme') === 'dark' && <DarkTheme />}
        <BrowserRouter>
          <ToastContainer position="top-center" />
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (this.state.authenticated) {
                  return <Redirect to="/dashboard/home" />
                }

                return <Redirect to="/signin" />
              }}
            />
            <AnonymousRoute
              path="/signin"
              component={Signin}
              setAuthenticated={this.setAuthenticated}
              title="Вход"
            />
            <AnonymousRoute
              path="/initialize"
              component={Initialize}
              title="Инициализация"
            />
            <AnonymousRoute
              path="/404"
              component={NotFound}
              title="Страница не найдена"
            />
            <ProtectedRoute
              key="Панель управления"
              path="/dashboard"
              component={Dashboard}
              breadcrumbs={[
                { title: 'Панель управления', url: '/dashboard/home' },
              ]}
            />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    )
  }
  setAuthenticated() {
    this.setState({ authenticated: true })
  }
}
