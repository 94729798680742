import Axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export function axiosErrorInterceptor(
  config: any = undefined,
  instance = Axios
) {
  function displayErrorToast(error: any) {
    const axiosError = error as AxiosError

    if (axiosError) {
      if (axiosError.response) {
        if (axiosError.response.data.id && axiosError.response.data.message) {
          toast.error(axiosError.response.data.message)
          return
        }

        if (axiosError.response.status !== 401) {
          toast.error('Не удалось выполнить запрос.')
        }
      }
    }
  }

  instance.interceptors.request.use(
    function (config) {
      return config
    },
    function (error) {
      displayErrorToast(error)

      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      displayErrorToast(error)

      return Promise.reject(error)
    }
  )
}
