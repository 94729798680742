import React from 'react'
import { FunctionComponent } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { TariffRents } from './tariffRents/tariffsRents'
import { TariffTrips } from './tariffTrips/tariffTrips'

export const Tariffs: FunctionComponent = () => {
  const activeTab = useLocation().hash.replace('#', '') || 'trips'

  return (
    <Tabs defaultActiveKey={activeTab}>
      <Tab eventKey="trips" title="Поездки">
        <TariffTrips />
      </Tab>
      <Tab eventKey="rents" title="Аренда">
        <TariffRents />
      </Tab>
    </Tabs>
  )
}
