import React from 'react'
import { FunctionComponent } from 'react'

export const Signout: FunctionComponent = () => {
  localStorage.removeItem('authenticated')
  localStorage.removeItem('tokenJwt')

  document.location.href = '/'

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">Выход из системы...</div>
        </div>
      </div>
    </div>
  )
}
