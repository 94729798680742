import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { forwardRef } from 'react'

export const DriverFio = forwardRef<
  any,
  {
    driverId: string
  }
>(({ driverId }, ref) => {
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')

  useEffect(() => {
    const loadDriver = async () => {
      const driver = await Axios.get<{
        surname: string
        name: string
        patronymic: string
      }>(`${process.env.REACT_APP_API_URL}/api/v1/drivers/${driverId}`)

      setSurname(driver.data.surname)
      setName(driver.data.name)
      setPatronymic(driver.data.patronymic)
    }

    loadDriver()
  }, [driverId])

  return (
    <>
      {surname} {name} {patronymic}
    </>
  )
})
