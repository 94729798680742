import { AxiosResponse } from 'axios'

export const downloadFile = (response: AxiosResponse) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/octet-stream' })
  )

  let fileName = response.headers['content-disposition']
    .split('filename=')[1]
    .split(';')[0]

  if (response.headers['content-disposition'].indexOf('filename*') !== -1) {
    fileName = decodeURI(
      response.headers['content-disposition']
        .split("filename*=UTF-8''")[1]
        .split(';')[0]
    )
  }

  const link = window.document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.target = '_blank'
  link.click()
  link.remove()

  window.URL.revokeObjectURL(url)
}
