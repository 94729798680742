import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import AsyncSelect from 'react-select/async'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

export const AddTrip: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)

  const [type, setType] = useState(0)
  const [marked, setMarked] = useState(false)
  const [driverId, setDriverId] = useState<string>()
  const [companyId, setCompanyId] = useState('')

  const [dateTimeFrom, setDateTimeFrom] = useState('')
  const [contractorIdFrom, setContractorIdFrom] = useState('')
  const [contractorObjectIdFrom, setContractorObjectIdFrom] = useState('')
  const [idleFrom, setIdleFrom] = useState(false)
  const [idleStartDateTimeFrom, setIdleStartDateTimeFrom] = useState<string>()
  const [idleEndDateTimeFrom, setIdleEndDateTimeFrom] = useState<string>()
  const [idleCommentFrom, setIdleCommentFrom] = useState<string>()

  const [dateTimeTo, setDateTimeTo] = useState<string>()
  const [contractorIdTo, setContractorIdTo] = useState<string>()
  const [contractorObjectIdTo, setContractorObjectIdTo] = useState<string>()
  const [idleTo, setIdleTo] = useState(false)
  const [idleStartDateTimeTo, setIdleStartDateTimeTo] = useState<string>()
  const [idleEndDateTimeTo, setIdleEndDateTimeTo] = useState<string>()
  const [idleCommentTo, setIdleCommentTo] = useState<string>()

  const [contractorMaterialObjectId, setContractorMaterialObjectId] = useState<
    string
  >()

  const [volumeFrom, setVolumeFrom] = useState(0)
  const [volumeTypeFrom, setVolumeTypeFrom] = useState(0)

  const [volumeTo, setVolumeTo] = useState<number>()
  const [volumeTypeTo, setVolumeTypeTo] = useState(0)
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [waybillNumber, setWaybillNumber] = useState('')

  const [comment, setComment] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')

  const history = useHistory()

  const addTrip = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if (
        type !== 2 &&
        dateTimeTo &&
        new Date(dateTimeFrom) > new Date(dateTimeTo)
      ) {
        alert('Дата разгрузки должна быть больше или равна дате погрузки.')
        return
      }

      const trip = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/trips`,
        {
          type,
          marked,
          driverId,
          dateTimeFrom,
          contractorIdFrom,
          contractorObjectIdFrom,
          idleFrom,
          idleStartDateTimeFrom,
          idleEndDateTimeFrom,
          idleCommentFrom,
          dateTimeTo,
          contractorIdTo,
          contractorObjectIdTo,
          idleTo,
          idleStartDateTimeTo,
          idleEndDateTimeTo,
          idleCommentTo,
          contractorMaterialObjectId,
          volumeFrom,
          volumeTypeFrom,
          volumeTo,
          volumeTypeTo,
          comment,
          invoiceNumber,
          waybillNumber,
          companyId: companyId === '' ? null : companyId,
          ticketNumber,
        }
      )

      toast.success('Поездка добавлена.')

      history.push(`/dashboard/edit-trip/${trip.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  const now = new Date()

  const today = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`

  const todayMax = `${today}T23:59:59`

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addTrip}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Тип заказа</label>
              <select
                className="form-control"
                value={type}
                onChange={(_) => {
                  setType(parseInt(_.target.value))
                }}
                required
              >
                <option value={0}>Поставка</option>
                <option value={1}>Перевозка</option>
                <option value={2}>Аренда</option>
              </select>
            </div>
            <div className="form-group form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={marked}
                onChange={(_) => {
                  setMarked(!marked)
                }}
                id="mark"
              />
              <label className="form-check-label" htmlFor="mark">
                Отметка
              </label>
            </div>
            <div className="form-group">
              <label>Водитель</label>

              <AsyncSelect
                key={`driver_${type}`}
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadDrivers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const drivers = await Axios.get<{
                      items: {
                        id: string
                        surname: string
                        name: string
                        patronymic: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/drivers?query=${inputValue}&page=1`
                    )

                    callback(
                      drivers.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.surname} ${_.name[0]}.${
                            _.patronymic ? ' ' + _.patronymic[0] + '.' : ''
                          }`,
                        }
                      })
                    )
                  }

                  loadDrivers(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setDriverId(_.value)
                }}
              />
              <input
                type="text"
                value={driverId ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>

            <div className="form-group">
              {type === 2 ? (
                <strong>Начало аренды</strong>
              ) : (
                <strong>Погрузка</strong>
              )}
            </div>
            <div className="form-group">
              <label>Дата и время</label>
              <input
                className="form-control"
                required
                type="datetime-local"
                value={dateTimeFrom.toString()}
                onChange={(_) => {
                  setDateTimeFrom(_.target.value)
                }}
                max={todayMax}
              />
            </div>
            <div className="form-group">
              <label>Наименование контрагента</label>
              <AsyncSelect
                cacheOptions
                key={`contractor_from_${type}`}
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractors = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        inn: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/contractors?query=${inputValue}&page=1`
                    )

                    callback(
                      contractors.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}-${_.inn}`,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorIdFrom(_.value)
                  setContractorObjectIdFrom('')
                }}
              />
              <input
                type="text"
                value={contractorIdFrom ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            <div className="form-group" hidden={!contractorIdFrom}>
              <label>Адрес</label>
              <AsyncSelect
                key={contractorIdFrom}
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractorsObjects = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractorsObjects = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        address: string
                        contractorId: string
                      }[]
                    }>(
                      `${
                        process.env.REACT_APP_API_URL
                      }/api/v1/contractors-objects?query=${inputValue}&contractorId=${
                        contractorIdFrom || ''
                      }&page=1`
                    )

                    callback(
                      contractorsObjects.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}-${_.address}`,
                          contractorId: _.contractorId,
                        }
                      })
                    )
                  }

                  loadContractorsObjects(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorObjectIdFrom(_.value)
                }}
              />
              <input
                type="text"
                value={contractorObjectIdFrom ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            {type !== 2 ? (
              <div className="form-group form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={idleFrom}
                  onChange={(_) => {
                    setIdleFrom(!idleFrom)
                  }}
                  id="idleFrom"
                />
                <label className="form-check-label" htmlFor="idleFrom">
                  Простой на объекте
                </label>
              </div>
            ) : null}
            {idleFrom ? (
              <>
                <div className="form-group">
                  <label>С</label>
                  <input
                    className="form-control"
                    required
                    type="datetime-local"
                    value={
                      idleStartDateTimeFrom
                        ? idleStartDateTimeFrom.toString()
                        : undefined
                    }
                    onChange={(_) => {
                      setIdleStartDateTimeFrom(_.target.value)
                    }}
                    max={todayMax}
                  />
                </div>
                <div className="form-group">
                  <label>По</label>
                  <input
                    className="form-control"
                    required
                    type="datetime-local"
                    value={
                      idleEndDateTimeFrom
                        ? idleEndDateTimeFrom.toString()
                        : undefined
                    }
                    onChange={(_) => {
                      setIdleEndDateTimeFrom(_.target.value)
                    }}
                    max={todayMax}
                  />
                </div>
                <div className="form-group">
                  <label>Комментарий при простое</label>
                  <textarea
                    className="form-control"
                    required
                    rows={5}
                    value={idleCommentFrom}
                    onChange={(_) => setIdleCommentFrom(_.target.value)}
                  ></textarea>
                </div>
              </>
            ) : null}

            <div className="form-group">
              {type === 2 ? (
                <strong>Конец аренды</strong>
              ) : (
                <strong>Разгрузка</strong>
              )}
            </div>
            <div className="form-group">
              <label>Дата и время</label>
              <input
                className="form-control"
                type="datetime-local"
                value={dateTimeTo ? dateTimeTo.toString() : ''}
                onChange={(_) => {
                  setDateTimeTo(_.target.value)
                }}
                max={todayMax}
              />
            </div>
            {type !== 2 ? (
              <>
                <div className="form-group">
                  <label>Наименование контрагента</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    key={`contractor_to_${type}`}
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractors = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractors = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            inn: string
                          }[]
                        }>(
                          `${process.env.REACT_APP_API_URL}/api/v1/contractors?query=${inputValue}&page=1`
                        )

                        callback(
                          contractors.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}-${_.inn}`,
                            }
                          })
                        )
                      }

                      loadContractors(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setContractorIdTo(_.value)
                        setContractorObjectIdTo(undefined)
                        return
                      }

                      setContractorIdTo(undefined)
                      setContractorObjectIdTo(undefined)
                    }}
                  />
                </div>
                <div className="form-group" hidden={!contractorIdTo}>
                  <label>Адрес</label>
                  <AsyncSelect
                    key={contractorIdTo}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractorsObjects = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractorsObjects = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            address: string
                          }[]
                        }>(
                          `${
                            process.env.REACT_APP_API_URL
                          }/api/v1/contractors-objects?query=${inputValue}&contractorId=${
                            contractorIdTo || ''
                          }&page=1`
                        )

                        callback(
                          contractorsObjects.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}-${_.address}`,
                            }
                          })
                        )
                      }

                      loadContractorsObjects(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setContractorObjectIdTo(_.value)

                        return
                      }

                      setContractorObjectIdTo(undefined)
                    }}
                  />
                </div>
                <div className="form-group form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={idleTo}
                    onChange={(_) => {
                      setIdleTo(!idleTo)
                    }}
                    id="idleTo"
                  />
                  <label className="form-check-label" htmlFor="idleTo">
                    Простой на объекте
                  </label>
                </div>
              </>
            ) : null}
            {idleTo ? (
              <>
                <div className="form-group">
                  <label>С</label>
                  <input
                    className="form-control"
                    required
                    type="datetime-local"
                    max={todayMax}
                    value={
                      idleStartDateTimeTo
                        ? idleStartDateTimeTo.toString()
                        : undefined
                    }
                    onChange={(_) => {
                      setIdleStartDateTimeTo(_.target.value)
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>По</label>
                  <input
                    className="form-control"
                    required
                    type="datetime-local"
                    value={
                      idleEndDateTimeTo
                        ? idleEndDateTimeTo.toString()
                        : undefined
                    }
                    onChange={(_) => {
                      setIdleEndDateTimeTo(_.target.value)
                    }}
                    max={todayMax}
                  />
                </div>
                <div className="form-group">
                  <label>Комментарий при простое</label>
                  <textarea
                    required
                    rows={5}
                    className="form-control"
                    value={idleCommentTo}
                    onChange={(_) => setIdleCommentTo(_.target.value)}
                  ></textarea>
                </div>
              </>
            ) : null}
            {(type === 0 && contractorObjectIdTo) ||
            (type === 1 && contractorObjectIdFrom) ? (
              <>
                <div className="form-group">
                  <strong>Груз</strong>
                </div>
                <div className="form-group">
                  <label>Материал</label>
                  <AsyncSelect
                    key={contractorObjectIdTo}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadMaterials = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const materials = await Axios.get<{
                          items: {
                            id: string
                            materialName: string
                          }[]
                        }>(
                          `${process.env.REACT_APP_API_URL}/api/v1/contractors-materials-objects?query=${inputValue}&contractorObjectId=${contractorObjectIdTo}&page=1`
                        )

                        callback(
                          materials.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.materialName,
                            }
                          })
                        )
                      }

                      loadMaterials(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setContractorMaterialObjectId(_.value)
                        return
                      }

                      setContractorMaterialObjectId(undefined)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorMaterialObjectId ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="form-row">
                  <div className="col">
                    <label>Кубатура при загрузке</label>
                    <input
                      type="number"
                      className="form-control"
                      value={volumeFrom}
                      required
                      min={0.001}
                      step={0.001}
                      onChange={(_) =>
                        setVolumeFrom(parseFloat(_.target.value))
                      }
                    />
                  </div>
                  <div className="col">
                    <label>Единица измерения</label>
                    <select
                      required
                      className="form-control"
                      value={volumeTypeFrom}
                      onChange={(_) =>
                        setVolumeTypeFrom(parseInt(_.target.value))
                      }
                    >
                      <option value={0}>Тонны</option>
                      <option value={1}>М³</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label>Кубатура при разгрузке</label>
                      <input
                        type="number"
                        className="form-control"
                        required
                        value={volumeTo}
                        min={0.001}
                        step={0.001}
                        onChange={(_) =>
                          setVolumeTo(parseFloat(_.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>Единица измерения</label>
                      <select
                        required
                        className="form-control"
                        value={volumeTypeTo}
                        onChange={(_) =>
                          setVolumeTypeTo(parseInt(_.target.value))
                        }
                      >
                        <option value={0}>Тонны</option>
                        <option value={1}>М³</option>
                      </select>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="form-group">
              <label>Комментарий</label>
              <textarea
                className="form-control"
                value={comment}
                onChange={(_) => setComment(_.target.value)}
                rows={10}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Номер путевого листа</label>
              <input
                type="text"
                className="form-control"
                value={waybillNumber}
                onChange={(_) => setWaybillNumber(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Организация</label>

              <AsyncSelect
                key={`company_${type}`}
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue: string, callback: any) => {
                  const loadCompanies = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const companies = await Axios.get<{
                      items: {
                        id: string
                        name: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1&archived=false`
                    )

                    callback(
                      companies.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}
                          }`,
                        }
                      })
                    )
                  }

                  loadCompanies(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setCompanyId(_.value)
                    return
                  }

                  setCompanyId('')
                }}
              />
            </div>
            <div className="form-group">
              <label>Номер документа</label>
              <input
                type="text"
                className="form-control"
                value={invoiceNumber}
                onChange={(_) => setInvoiceNumber(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Номер талона</label>
              <input
                type="text"
                className="form-control"
                value={ticketNumber}
                onChange={(_) => setTicketNumber(_.target.value || '')}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
