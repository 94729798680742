import React, { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Users: FunctionComponent = (_) => {
  return (
    <Table
      apiUrl="/api/v1/users"
      addUrl="/dashboard/add-user"
      defaultSorting={{ name: 'surname', direction: 'asc' }}
      items={[
        { title: 'Фамилия', name: 'surname', type: 'string' },
        { title: 'Имя', name: 'name', type: 'string' },
        { title: 'Email', name: 'email', type: 'string' },
        { title: 'Заблокирован', name: 'locked', type: 'boolean' },
        { title: 'Дата блокировки', name: 'lockDateTime', type: 'datetime' },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: '/dashboard/edit-user',
        },
        {
          title: 'Изменить пароль',
          icon: 'fas fa-key',
          url: '/dashboard/edit-user-password',
        },
      ]}
    />
  )
}
