import Axios from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { downloadFile } from '../../../infrastructure/document/downloadFile'
import { Table } from '../table/table'

export const TripsDocuments: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)

  const tableRef = useRef<{ refresh(): void }>(null)

  const [displayExportWindow, setDisplayExportWindow] = useState(false)

  const date = new Date()

  const [from, setFrom] = useState(
    `${date.getFullYear()}-${date.getMonth() + 1}-01`
  )

  const [to, setTo] = useState(
    `${date.getFullYear()}-${date.getMonth() + 1}-${new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate()}`
  )

  useEffect(() => {
    const interval = setInterval(() => {
      tableRef.current?.refresh()
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  const exportTrips = async (_: FormEvent) => {
    _.preventDefault()

    try {
      setDisabled(true)

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/exports-documents/trips`,
        { from, to }
      )

      tableRef.current?.refresh()

      toast.success('Экспорт добавлен.')
    } finally {
      setDisabled(false)
      setDisplayExportWindow(false)
    }
  }

  return (
    <>
      <Table
        ref={tableRef}
        apiUrl="/api/v1/exports-documents/trips"
        defaultSorting={{ name: 'creationDateTime', direction: 'desc' }}
        items={[
          {
            name: 'creationDateTime',
            title: 'Дата создания',
            type: 'datetime-timezone',
          },
          {
            name: 'name',
            title: 'Название',
            type: 'string',
          },
          {
            name: 'exportStatus',
            title: 'Статус',
            type: 'enum',
            enum: ['Создано', 'Выполняется', 'Завершено', 'Не удалось'],
          },
        ]}
        tableActions={[
          {
            title: 'Экспортировать',
            icon: 'far fa-file-excel',
            onClick: async () => {
              setDisplayExportWindow(true)
            },
          },
        ]}
        actions={[
          {
            title: 'Скачать',
            icon: 'fas fa-download',
            hidden: (_) => _.exportStatus !== 2,
            onClick: async (_: { id: string; name: string }) => {
              const response = await Axios.get(
                process.env.REACT_APP_API_URL +
                  '/api/v1/exports-documents/files/' +
                  _.id,
                {
                  responseType: 'blob',
                }
              )

              downloadFile(response)
            },
          },
        ]}
      />
      <Modal
        show={displayExportWindow}
        onHide={() => setDisplayExportWindow(!displayExportWindow)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Настройки экспорта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={exportTrips}>
            <fieldset disabled={disabled}>
              <div className="form-group">
                <label>С</label>
                <input
                  className="form-control"
                  required
                  type="date"
                  value={from.toString()}
                  onChange={(_) => {
                    if (new Date(_.target.value) > new Date(to)) {
                      alert('"По" не может быть раньше чем "С".')
                      _.preventDefault()
                      return
                    }

                    setFrom(_.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label>По</label>
                <input
                  className="form-control"
                  required
                  type="date"
                  value={to.toString().split('T')[0]}
                  onChange={(_) => {
                    if (new Date(_.target.value) < new Date(from)) {
                      alert('"По" не может быть раньше "С".')
                      _.preventDefault()
                      return
                    }

                    setTo(_.target.value + 'T23:59:59.999')
                  }}
                />
              </div>
              <button type="submit" className="btn btn-success">
                Экспортировать
              </button>
            </fieldset>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
