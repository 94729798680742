import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './tableImage.scss'
export const TableImage = ({ src }: any) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <img
        className="img-preview"
        src={src}
        alt="Изображение"
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Изображение</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className="img-full" src={src} alt="Изображение" />
        </Modal.Body>
      </Modal>
    </>
  )
}
