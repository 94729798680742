import React, { FunctionComponent, useRef, useState } from 'react'
import { Table } from '../../table/table'
import Axios from 'axios'

export const TariffRents: FunctionComponent = () => {
  const [displayArchive, setDisplayArchive] = useState(false)

  const tableRef = useRef<{
    refresh(): void
    updateParams(key: string, value: string): void
  }>(null)

  return (
    <>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="displayArchive"
          checked={displayArchive}
          onChange={(_) => {
            const newValue = !displayArchive

            setDisplayArchive(newValue)

            tableRef.current?.updateParams(
              'displayArchive',
              newValue ? 'true' : 'false'
            )

            tableRef.current?.refresh()
          }}
        />
        <label className="form-check-label" htmlFor="displayArchive">
          Показывать архивные
        </label>
      </div>
      <Table
        ref={tableRef}
        apiUrl="/api/v1/tariffs-rents"
        addUrl="/dashboard/add-tariff-rent"
        defaultSorting={{ name: 'contractorName', direction: 'asc' }}
        items={[
          {
            title: 'Минимальное количество часов',
            name: 'minHours',
            type: 'string',
          },
          {
            title: 'Максимальное количество часов',
            name: 'maxHours',
            type: 'string',
          },
          { title: 'Контрагент', name: 'contractorName', type: 'string' },
          { title: 'Тариф', name: 'value', type: 'string' },
        ]}
        actions={[
          {
            title: 'Изменить',
            icon: 'far fa-edit',
            url: '/dashboard/edit-tariff-rent',
          },
          {
            title: 'Архивировать',
            icon: 'fas fa-archive',
            onClick: async (_: { id: string }) => {
              await Axios.delete(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents/${_.id}`
              )
            },
            hidden: (_: { archived: boolean }) => _.archived,
          },
          {
            title: 'Вернуть из архива',
            icon: 'fas fa-undo',
            onClick: async (_: { id: string }) => {
              await Axios.put(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents/${_.id}/restore`
              )
            },
            hidden: (_: { archived: boolean }) => !_.archived,
          },
          {
            title: 'Удалить',
            icon: 'fas fa-trash-alt',
            onClick: async (_: { id: string }) => {
              await Axios.delete(
                `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents/${_.id}/delete`
              )
            },
          }
        ]}
      />
    </>
  )
}
