import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { EditDriver } from './editDriver'
import { EditDriverDocuments } from './editDriverDocuments'
import { Tab, Tabs } from 'react-bootstrap'
import { Fines } from '../fines/fines'
import { Advances } from '../advances/advances'
import { Bonuses } from '../bonuses/bonuses'
import { Idles } from '../idles/idles'

export const EditDriverTabs: FunctionComponent = () => {
  const { driverId } = useParams<{ driverId: string }>()
  const activeTab = useLocation().hash.replace('#', '') || 'data'

  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')

  useEffect(() => {
    const loadDriver = async () => {
      const driver = await Axios.get<{
        surname: string
        name: string
        patronymic: string
      }>(`${process.env.REACT_APP_API_URL}/api/v1/drivers/${driverId}`)

      setSurname(driver.data.surname)
      setName(driver.data.name)
      setPatronymic(driver.data.patronymic)
    }

    loadDriver()
  }, [driverId])

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <strong>
            {surname} {name} {patronymic}
          </strong>
        </div>
      </div>
      <Tabs defaultActiveKey={activeTab}>
        <Tab eventKey="data" title="Данные водителя">
          <EditDriver driverId={driverId} />
        </Tab>
        <Tab eventKey="documents" title="Документы">
          <EditDriverDocuments driverId={driverId} />
        </Tab>
        <Tab eventKey="fines" title="Списания">
          <div className="row mt-1 mb-1">
            <div className="col">
              <strong>Личные штрафы</strong>
            </div>
          </div>
          <Fines driverId={driverId} />
          <div className="row mt-1 mb-1">
            <div className="col">
              <strong>Авансы</strong>
            </div>
          </div>
          <Advances driverId={driverId} />
        </Tab>
        <Tab eventKey="bonuses" title="Премии">
          <Bonuses driverId={driverId} />
        </Tab>
        <Tab eventKey="idles" title="Простои">
          <Idles driverId={driverId} />
        </Tab>
      </Tabs>
    </>
  )
}
