import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const EditCompany: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')

  const { companyId } = useParams<{ companyId: string }>()

  useEffect(() => {
    const loadCompany = async () => {
      setDisabled(true)

      try {
        const company = await Axios.get<{
          name: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}`)

        setName(company.data.name)
      } finally {
        setDisabled(false)
      }
    }

    loadCompany()
  }, [companyId])

  const editCompany = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}`,
        {
          name,
        }
      )

      toast.success('Компания обновлена.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editCompany}>
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Название</label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={name}
                    onChange={(_) => setName(_.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-success">
                  <i className="fas fa-save"></i> Сохранить
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
