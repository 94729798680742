import Axios from 'axios'
import React from 'react'
import { FunctionComponent } from 'react'
import { Table } from '../table/table'

export const Bonuses: FunctionComponent<{ driverId: string }> = ({
  driverId,
}) => {
  return (
    <Table
      apiUrl={`/api/v1/bonuses/${driverId}`}
      addUrl={`/dashboard/add-bonus/${driverId}`}
      defaultSorting={{ name: 'date', direction: 'desc' }}
      items={[
        { title: 'Дата', name: 'date', type: 'date' },
        { title: 'Сумма', name: 'amount', type: 'string' },
        { title: 'Комментарий', name: 'comment', type: 'string' },
      ]}
      actions={[
        {
          title: 'Изменить',
          icon: 'far fa-edit',
          url: `/dashboard/edit-bonus/${driverId}`,
        },
        {
          title: 'Удалить',
          icon: 'fas fa-trash-alt',
          onClick: async (_: { id: string }) => {
            await Axios.delete(
              `${process.env.REACT_APP_API_URL}/api/v1/bonuses/${_.id}`
            )
          },
        },
      ]}
    />
  )
}
