import Axios from 'axios'
import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { threeMonthsAgo } from '../threeMonthsAgo'

export const AddTariffRent: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [minHours, setMinHours] = useState<number>()
  const [maxHours, setMaxHours] = useState<number>()
  const [contractorId, setContractorId] = useState<string>()
  const [value, setValue] = useState(0)
  const [updateFrom, setUpdatFrom] = useState<string>()
  const [tripsUpdateWarning, setTripsUpdateWarning] = useState(false)

  const history = useHistory()

  const addTariffRent = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const tariffRent = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents`,
        {
          minHours,
          maxHours,
          contractorId,
          value,
          updateFrom,
        }
      )

      toast.success('Тариф аренды добавлен.')

      history.push(
        `/dashboard/tariffs/edit-tariff-rent/${tariffRent.data.data}`
      )
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={addTariffRent}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Минимальное количество часов</label>
              <input
                type="number"
                min={1}
                step={1}
                className="form-control"
                value={minHours}
                onChange={(_) => setMinHours(parseInt(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Максимальное количество часов</label>
              <input
                type="number"
                min={minHours || 1}
                step={1}
                className="form-control"
                value={maxHours}
                onChange={(_) => setMaxHours(parseInt(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Контрагент</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const drivers = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        inn: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/contractors?query=${inputValue}&page=1`
                    )

                    callback(
                      drivers.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.inn}-${_.name}`,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorId(_ ? _.value : undefined)
                }}
              />
            </div>
            <div className="form-group">
              <label>Тариф</label>
              <input
                className="form-control"
                type="number"
                step={0.01}
                min={0.01}
                value={value}
                required
                onChange={(_) => {
                  _ ? setValue(parseFloat(_.target.value)) : setValue(0)

                  if (updateFrom) {
                    setTripsUpdateWarning(true)
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>Тариф актуален с (необязательно)</label>
              <input
                className="form-control"
                type="date"
                value={updateFrom}
                min={threeMonthsAgo()}
                onChange={(_) => {
                  setUpdatFrom(_.target.value)
                  setTripsUpdateWarning(true)
                }}
              />
            </div>
            {tripsUpdateWarning ? (
              <span className="text-danger">
                Внимание! Тариф будет пересчитан во всех уже совершенных
                заказах.
              </span>
            ) : null}
            <div className="form-group">
              <button type="submit" className="btn btn-success">
                Сохранить
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
