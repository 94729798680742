import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const EditGibddFine: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState(0)
  const [carNumber, setCarNumber] = useState<string>()
  const [carId, setCarId] = useState<string>()
  const [driverId, setDriverId] = useState('')
  const [driverSurname, setDriverSurname] = useState('')
  const [driverName, setDriverName] = useState('')
  const [driverPatronymic, setDriverPatronymic] = useState('')
  const [trailerId, setTrailerId] = useState<string>()
  const [trailerNumber, setTrailerNumber] = useState<string>()

  const { gibddFineId } = useParams<{ gibddFineId: string }>()

  useEffect(() => {
    const loadGibddFine = async () => {
      setDisabled(true)

      try {
        const gibddFine = await Axios.get<{
          date: string
          amount: number
          carNumber: string
          carId: string
          driverId: string
          driverSurname: string
          driverName: string
          driverPatronymic?: string
          trailerNumber: string
          trailerId: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines/${gibddFineId}`)
        setDate(gibddFine.data.date.replace('T00:00:00', ''))
        setAmount(gibddFine.data.amount)

        if (gibddFine.data.carId) {
          setCarNumber(gibddFine.data.carNumber)
          setCarId(gibddFine.data.carId)
        }

        setDriverId(gibddFine.data.driverId)
        setDriverSurname(gibddFine.data.driverSurname)
        setDriverName(gibddFine.data.driverName)
        setDriverPatronymic(gibddFine.data.driverPatronymic ?? '')

        if (gibddFine.data.trailerId) {
          setTrailerNumber(gibddFine.data.trailerNumber)
          setTrailerId(gibddFine.data.trailerId)
        }
      } finally {
        setDisabled(false)
      }
    }

    loadGibddFine()
  }, [gibddFineId])

  const editGibddFine = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/gibdd-fines/${gibddFineId}`,
        {
          date,
          amount,
          carId,
          trailerId,
          driverId,
        }
      )

      toast.success('Штраф ГИБДД обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editGibddFine}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Дата</label>
              <input
                className="form-control"
                required
                type="date"
                value={date.toString().replace('+00:00', '')}
                onChange={(_) => setDate(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Сумма</label>
              <input
                className="form-control"
                required
                type="number"
                value={amount}
                min={0.01}
                step={0.01}
                onChange={(_) => setAmount(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Транспортное средство</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                value={{ value: carId, label: carNumber }}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/cars?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return { value: _.id, label: _.number }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setCarId(_.value)
                    setCarNumber(_.label)
                    return
                  }

                  setCarId(undefined)
                  setCarNumber(undefined)
                }}
              />
            </div>
            <div className="form-group">
              <label>Прицеп</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable={true}
                value={{ value: trailerId, label: trailerNumber }}
                loadOptions={(inputValue, callback) => {
                  const loadTrailers = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: { id: string; number: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/trailers?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return { value: _.id, label: _.number }
                      })
                    )
                  }

                  loadTrailers(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setTrailerId(_.value)
                    setTrailerNumber(_.label)
                    return
                  }

                  setTrailerId(undefined)
                  setTrailerNumber(undefined)
                }}
              />
            </div>
            <div className="form-group">
              <label>Водитель</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={{
                  value: driverId,
                  label: `${driverSurname} ${driverName} ${driverPatronymic}`,
                }}
                loadOptions={(inputValue, callback) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const cars = await Axios.get<{
                      items: {
                        id: string
                        surname: string
                        name: string
                        patronymic: string
                      }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/drivers?query=${inputValue}&page=1`
                    )

                    callback(
                      cars.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.surname} ${_.name} ${_.patronymic ?? ''}`,
                          surname: _.surname,
                          name: _.name,
                          patronymic: _.patronymic,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setDriverId(_.value)
                  setDriverSurname(_.surname)
                  setDriverName(_.name)
                  setDriverPatronymic(_.patronymic)
                }}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
