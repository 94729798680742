import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { threeMonthsAgo } from '../threeMonthsAgo'
import { TariffRentsHistory } from './tariffsRentsHistory'

export const EditTariffRent: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [minHours, setMinHours] = useState(0)
  const [maxHours, setMaxHours] = useState(0)
  const [contractorName, setContractorName] = useState<string>()
  const [contractorId, setContractorId] = useState<string>()
  const [value, setValue] = useState(0)
  const [updateFrom, setUpdateFrom] = useState<string>()
  const [tripsUpdateWarning, setTripsUpdateWarning] = useState(false)

  const { tariffRentId } = useParams<{ tariffRentId: string }>()

  useEffect(() => {
    const loadTariffRent = async () => {
      setDisabled(true)

      try {
        const tariffRent = await Axios.get<{
          id: string
          minHours: number
          maxHours: number

          contractorInn: string
          contractorName: string
          contractorId: string

          updateFrom: string

          value: number
        }>(
          `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents/${tariffRentId}`
        )

        setMinHours(tariffRent.data.minHours)
        setMaxHours(tariffRent.data.maxHours)

        if (tariffRent.data.contractorId) {
          setContractorName(
            `${tariffRent.data.contractorInn}-${tariffRent.data.contractorName}`
          )
          setContractorId(tariffRent.data.contractorId)
        }

        if (tariffRent.data.updateFrom) {
          setUpdateFrom(tariffRent.data.updateFrom.split('T')[0])
        }

        setValue(tariffRent.data.value)
      } finally {
        setDisabled(false)
      }
    }

    loadTariffRent()
  }, [tariffRentId])

  const editTariffTrip = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/tariffs-rents/${tariffRentId}`,
        {
          minHours,
          maxHours,
          contractorId,
          value,
          updateFrom,
        }
      )

      toast.success('Тариф аренды обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <form onSubmit={editTariffTrip}>
            <fieldset disabled={disabled}>
              <div className="form-group">
                <label>Минимальное количество часов</label>
                <input
                  type="number"
                  min={1}
                  step={1}
                  className="form-control"
                  value={minHours}
                  onChange={(_) => setMinHours(parseInt(_.target.value))}
                />
              </div>
              <div className="form-group">
                <label>Максимальное количество часов</label>
                <input
                  type="number"
                  min={minHours || 1}
                  step={1}
                  className="form-control"
                  value={maxHours}
                  onChange={(_) => setMaxHours(parseInt(_.target.value))}
                />
              </div>
              <div className="form-group">
                <label>Контрагент</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isClearable={true}
                  value={{
                    value: contractorId,
                    label: contractorName,
                  }}
                  loadOptions={(inputValue: string, callback: any) => {
                    const loadContractors = async (
                      inputValue: string,
                      callback: any
                    ) => {
                      const contractors = await Axios.get<{
                        items: {
                          id: string
                          name: string
                          inn: string
                        }[]
                      }>(
                        `${process.env.REACT_APP_API_URL}/api/v1/contractors?query=${inputValue}&page=1`
                      )

                      callback(
                        contractors.data.items.map((_) => {
                          return {
                            value: _.id,
                            label: `${_.inn}-${_.name}`,
                          }
                        })
                      )
                    }

                    loadContractors(inputValue, callback)
                  }}
                  onChange={(_: any) => {
                    if (_) {
                      setContractorId(_.value)
                      setContractorName(_.label)

                      return
                    }

                    setContractorId(undefined)
                    setContractorName('')
                  }}
                />
              </div>
              <div className="form-group">
                <label>Тариф</label>
                <input
                  className="form-control"
                  type="number"
                  step={0.01}
                  min={0.01}
                  value={value}
                  required
                  onChange={(_) => {
                    _ ? setValue(parseFloat(_.target.value)) : setValue(0)

                    if (updateFrom) {
                      setTripsUpdateWarning(true)
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <label>Тариф актуален с (необязательно)</label>
                <input
                  className="form-control"
                  type="date"
                  value={updateFrom}
                  min={threeMonthsAgo()}
                  onChange={(_) => {
                    setUpdateFrom(_.target.value)
                    setTripsUpdateWarning(true)
                  }}
                />
              </div>
              {tripsUpdateWarning ? (
                <span className="text-danger">
                  Внимание! Тариф будет пересчитан во всех уже совершенных
                  заказах.
                </span>
              ) : null}
              <div className="form-group">
                <button type="submit" className="btn btn-success">
                  Сохранить
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h3>История тарифа</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TariffRentsHistory />
        </div>
      </div>
    </>
  )
}
