import React from 'react'
import { Table } from '../table/table'

export const CompaniesDrivers = () => {
  return (
    <Table
      apiUrl="/api/v1/companies-drivers"
      defaultSorting={{ name: 'companyName', direction: 'asc' }}
      items={[
        {
          title: 'Организация',
          name: 'companyName',
          type: 'string',
        },
        {
          title: 'Водитель, фамилия',
          name: 'driverSurname',
          type: 'string',
        },
        {
          title: 'Водитель, имя',
          name: 'driverName',
          type: 'string',
        },
        {
          title: 'Водитель, отчество',
          name: 'driverPatronymic',
          type: 'string',
        },
      ]}
    />
  )
}
