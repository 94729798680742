import Axios from 'axios'
import React, { useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const ArchiveCar: FunctionComponent = () => {
  const { carId } = useParams<{ carId: string }>()
  const history = useHistory()

  useEffect(() => {
    const archiveCar = async () => {
      await Axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/cars/${carId}`
      )

      toast.success('Транспортное средство архивировано.')

      history.push('/dashboard/cars')
    }

    archiveCar()
  }, [carId, history])

  return (
    <div className="row">
      <div className="col">Архивировать транспортное средство.</div>
    </div>
  )
}
