import React from 'react'
import { Breadcrumb } from 'react-breadcrumbs'
import { Redirect, Route } from 'react-router-dom'

export const ProtectedRoute = ({ component, breadcrumbs, ...rest }: any) => {
  const updateTitle = () => {
    if (breadcrumbs.length > 0) {
      document.title = `${breadcrumbs[breadcrumbs.length - 1].title} - ${
        process.env.REACT_APP_NAME
      }`
    }
  }
  const routeComponent = (props: any) =>
    localStorage.getItem('authenticated') === 'true' ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/404' }} />
    )
  return (
    <Route
      {...rest}
      render={(props: any) => (
        <>
          {updateTitle()}
          {breadcrumbs.map(
            (_: { title: string; url: string }, index: number) => {
              return (
                <Breadcrumb
                  key={_.url}
                  data={{
                    title: _.title,
                    pathname: _.url,
                  }}
                />
              )
            }
          )}
          {routeComponent(rest)}
        </>
      )}
    />
  )
}

export default ProtectedRoute
