import Axios from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

export const EditTrailer: FunctionComponent = () => {
  const [disabled, setDisabled] = useState(false)
  const [number, setNumber] = useState('')
  const [brand, setBrand] = useState('')
  const [year, setYear] = useState(0)
  const [color, setColor] = useState('')
  const [volume, setVolume] = useState(0)
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyName] = useState('')

  const { trailerId } = useParams<{ trailerId: string }>()

  useEffect(() => {
    const loadTrailer = async () => {
      setDisabled(true)

      try {
        const trailer = await Axios.get<{
          number: string
          brand: string
          year: number
          color: string
          volume: number
          companyId: string
          companyName: string
        }>(`${process.env.REACT_APP_API_URL}/api/v1/trailers/${trailerId}`)

        setNumber(trailer.data.number)
        setBrand(trailer.data.brand)
        setYear(trailer.data.year)
        setColor(trailer.data.color)
        setVolume(trailer.data.volume)
        setCompanyId(trailer.data.companyId)
        setCompanyName(trailer.data.companyName)
      } finally {
        setDisabled(false)
      }
    }

    loadTrailer()
  }, [trailerId])

  const editTrailer = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/trailers/${trailerId}`,
        {
          number,
          brand,
          year,
          color,
          volume,
          companyId,
        }
      )

      toast.success('Прицеп обновлён.')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={editTrailer}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Номер</label>
              <input
                className="form-control"
                required
                type="text"
                value={number}
                onChange={(_) => setNumber(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Марка</label>
              <input
                className="form-control"
                required
                type="string"
                value={brand}
                onChange={(_) => setBrand(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Год выпуска</label>
              <input
                className="form-control"
                required
                type="number"
                value={year}
                min={1}
                onChange={(_) => setYear(parseInt(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Цвет</label>
              <input
                className="form-control"
                required
                type="string"
                value={color}
                onChange={(_) => setColor(_.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Объём кузова</label>
              <input
                className="form-control"
                required
                type="number"
                value={volume}
                min={0.01}
                step={0.01}
                onChange={(_) => setVolume(parseFloat(_.target.value))}
              />
            </div>
            <div className="form-group">
              <label>Организация собственник</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={{
                  value: companyId,
                  label: companyName,
                }}
                loadOptions={(inputValue, callback) => {
                  const loadCompanies = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const companies = await Axios.get<{
                      items: { id: string; name: string }[]
                    }>(
                      `${process.env.REACT_APP_API_URL}/api/v1/companies?query=${inputValue}&page=1`
                    )

                    callback(
                      companies.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.name,
                        }
                      })
                    )
                  }

                  loadCompanies(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setCompanyId(_.value)
                }}
              />
              <input
                type="text"
                value={companyId ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
