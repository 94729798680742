import Axios from 'axios'
import React, { FormEvent, useState } from 'react'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

export const AddUser: FunctionComponent = (_) => {
  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [patronymic, setPatronymic] = useState('')

  const history = useHistory()

  const addUser = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const user = await Axios.post<{ data: string }>(
        `${process.env.REACT_APP_API_URL}/api/v1/users`,
        {
          email,
          password,
          surname,
          name,
          patronymic,
        }
      )

      toast.success('Пользователь добавлен.')

      history.push(`/dashboard/edit-user/${user.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={(_) => addUser(_)}>
          <fieldset disabled={disabled}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(_) => setEmail(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Пароль</label>
              <input
                type="text"
                className="form-control"
                id="password"
                value={password}
                onChange={(_) => setPassword(_.target.value)}
                minLength={6}
                required
              />
            </div>
            <div className="form-group">
              <label>Фамилия</label>
              <input
                type="text"
                className="form-control"
                id="surname"
                value={surname}
                onChange={(_) => setSurname(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Имя</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(_) => setName(_.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Отчество</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={patronymic}
                onChange={(_) => setPatronymic(_.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-success">
              <i className="fas fa-save"></i> Сохранить
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  )
}
